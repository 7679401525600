import axios from 'axios';

export const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3030',
	// headers: {
	// 	Authorization: 'Bearer ' + getToken(),
	// 	'Content-Type': 'application/json',
	// },
});

export const apiTrello = axios.create({
	baseURL: process.env.REACT_APP_API_TRELLO_URL || 'http://localhost:3030',
	// headers: {
	// 	Authorization: 'Bearer ' + getToken(),
	// 	'Content-Type': 'application/json',
	// },
});

export const apiAurora = axios.create({
	baseURL: process.env.REACT_APP_API_AURORA_URL || 'http://localhost:3030',
	// headers: {
	// 	Authorization: 'Bearer ' + getToken(),
	// 	'Content-Type': 'application/json',
	// },
});

export const getTrelloToken = process.env.REACT_APP_API_TRELLO_TOKEN;
export const getTrelloKey = process.env.REACT_APP_API_TRELLO_KEY;
