import React, { useDeferredValue, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

/**
 *
 * @param _ Apenas para dataTables
 * @param {array} list array com os dados
 * @param {any} setResult state que fará alterações na lista. A lista sempre estará dentro do objeto `nodes`
 * @param {string} atributeToSearch nome do atributo a ser buscado
 */

//apenas de dataTables
const Searchbox = ({ list, setResult, atributeToSearch }) => {
	const [searchbox, setSearchbox] = useState('');
	const deferredSearch = useDeferredValue(searchbox);

	const handleSearch = (e) => {
		setSearchbox(e.target.value);
	};

	useEffect(() => {
		if (list) {
			setResult({
				nodes: list.filter((item) =>
					item[atributeToSearch]
						?.toString()
						.toLowerCase()
						.normalize('NFD')
						.replace(/[^a-zA-Z0-9\s]/g, '')
						.includes(
							deferredSearch
								?.normalize('NFD')
								.replace(/[^a-zA-Z0-9\s]/g, '')
								.toLowerCase()
						)
				),
			});
		}
	}, [deferredSearch]);

	return (
		<Form.Control
			style={{ width: 'max-content' }}
			type='text'
			placeholder='Pesquisar'
			onChange={handleSearch}
		/>
	);
};

export default Searchbox;
