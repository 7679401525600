import { Card } from 'react-bootstrap';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import CustomTooltip from '../customTooltip/CustomTooltip';

const CardWithGrowth = ({
	className,
	style,
	children,
	icon,
	colorIcon,
	title,
	growth,
	tooltip,
	isLoading,
}) => {
	return (
		<Card className={className + ' w-100'} style={style}>
			<Card.Body>
				<div
					className='d-flex justify-content-between align-items-center'
					style={{ gap: '0.5rem' }}
				>
					<div className={'text-white rounded p-3 bg-' + colorIcon}>{icon}</div>
					<div className='text-nowrap'>
						<div className='d-flex align-items-center justify-content-between'>
							{title}

							<CustomTooltip text={tooltip} />
						</div>
						<div
							className='d-flex flex-wrap align-items-center justify-content-evenly'
							style={{ gap: '0.5rem' }}
						>
							<h4 className='counter'>
								{/* <ReactPlaceholder
									type='text'
									rows={1}
									ready={!isLoading}
									showLoadingAnimation={true}
									color='#E0E0E0'
								> */}
								{/* {Number.isInteger(children) ? (
										<CountUp start={0} end={children ?? 0} duration={0.5} />
									) : ( */}
								{children}
								{/* )} */}
								{/* </ReactPlaceholder> */}
							</h4>

							{growth ? (
								<div className='badge bg-primary'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='20px'
										viewBox='0 0 20 20'
										fill='currentColor'
									>
										<path
											fillRule='evenodd'
											d='M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
											clipRule='evenodd'
										/>
									</svg>
									{growth}%
								</div>
							) : null}
						</div>
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};

const Body = ({ children, isLoading }) => {
	return (
		<ReactPlaceholder
			type='text'
			rows={1}
			ready={!isLoading}
			showLoadingAnimation={true}
			color='#E0E0E0'
			style={{ minWidth: '50px' }}
		>
			{children ?? 0}
		</ReactPlaceholder>
	);
};
CardWithGrowth.Body = Body;

export default CardWithGrowth;
