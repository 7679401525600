import React, { useEffect, useState } from 'react';
import { apiTrello, getTrelloKey, getTrelloToken } from '../../../api/api';

//hooks
import { useQuery } from 'react-query';
import { useSessionStorage } from '@uidotdev/usehooks';

import { Spinner, Accordion } from 'react-bootstrap';
import Card from '../../../components/card/Card';

//components
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';

const Trello = () => {
	const [isLoading, setLoading] = useSessionStorage('isLoading', false);
	const [boards, setBoards] = useSessionStorage('boards');
	const [totalCards, setTotalCards] = useState(0);
	const [boardsData, setBoardsData] = useState([]);

	const { data: getBoards, isLoading: isLoadingBoards } = useQuery(
		['boardsTrello'],
		async () => {
			setLoading(true);
			const response = await apiTrello.get(
				`/organizations/63a46f234ad42e04333d0502/boards?key=${getTrelloKey}&token=${getTrelloToken}`
			);

			setBoards(response.data);
			return response.data;
		},
		{ onSuccess: () => setLoading(false) }
	);

	useEffect(() => {
		setBoardsDataChart();
	}, []);

	const setBoardsDataChart = async () => {
		if (boards) {
			let progress = [];
			let cardsLength = 0;
			for (const key in boards) {
				if (Object.hasOwnProperty.call(boards, key)) {
					const board = boards[key];
					const cardsByBoard = await apiTrello.get(
						`/boards/${board.id}/cards/?key=${getTrelloKey}&token=${getTrelloToken}`
					);

					progress.push({
						name: board.name,
						data: [cardsByBoard.data.length],
					});
					cardsLength += cardsByBoard.data.length;
				}
			}
			setTotalCards(cardsLength);
		}
	};

	const getListsName = async (boardId) => {
		try {
			setLoading(true);

			const response = await apiTrello.get(
				`/boards/${boardId}/lists?key=${getTrelloKey}&token=${getTrelloToken}`
			);

			setLoading(false);
			return response.data;
		} catch (error) {
			console.log(error);
		}
	};
	const getCardsInList = async (listId) => {
		try {
			setLoading(true);

			const response = await apiTrello.get(
				`/lists/${listId}/cards?key=${getTrelloKey}&token=${getTrelloToken}`
			);

			setLoading(false);
			return response.data.length;
		} catch (error) {
			console.log(error);
		}
	};

	const cardsInBody = async (boardId, bodyIndex) => {
		setBoardsData([]);
		const accordionBody = document.getElementById(`accordion-body-` + bodyIndex);

		if (!accordionBody.parentNode.classList.contains('show')) {
			const lists = await getListsName(boardId);

			let cardsPerList = [];
			for (let index = 0; index < lists.length; index++) {
				const element = lists[index];
				const cardslength = await getCardsInList(element.id);
				cardsPerList.push(cardslength);
			}

			setBoardsData((prevState) => [
				...prevState,
				{ boardId: boardId, lists: lists.map((list) => list.name), cardsLength: cardsPerList },
			]);
		}
	};

	return (
		<>
			<Card>
				<Card.Body>
					<h2>Visão Geral do Trello</h2>
					<p className='text-muted'>Dados da área de trabalho do trello de Salvador</p>
					<div className='mt-3'>
						{boards ? (
							<>
								<p>
									Total de cards no projeto:{' '}
									<ReactPlaceholder
										className='d-inline-block'
										type='text'
										rows={1}
										ready={totalCards}
										showLoadingAnimation={true}
										color='#E0E0E0'
										style={{ width: '50px' }}
									>
										{totalCards}
									</ReactPlaceholder>
								</p>
								<Accordion defaultActiveKey='0'>
									{boards.map((board, index) => (
										<Accordion.Item eventKey={index}>
											<Accordion.Header onClick={() => cardsInBody(board.id, index)}>
												{board.name}
											</Accordion.Header>
											<Accordion.Body id={'accordion-body-' + index}>
												<ReactPlaceholder
													className='d-inline-block'
													type='text'
													rows={8}
													ready={!!boardsData.length}
													showLoadingAnimation={true}
													color='#E0E0E0'
													style={{ width: '50%' }}
												>
													<ul>
														{boardsData.map((boardData) => {
															let lists = [];

															if (boardData.boardId === board.id) {
																var totalCardsByBoard = boardData.cardsLength.reduce(
																	(acumulador, numero) => acumulador + numero,
																	0
																);
																boardData.lists.map((list) => lists.push(list));
															}
															let anteriores = 0;

															return lists.map((list, i) => {
																console.log(boardData.cardsLength[i]);
																boardData.cardsLength.forEach((element, indx) => {
																	if (element === boardData.cardsLength[i]) return;
																	anteriores += element;
																	console.log(element, boardData.lists[i]);
																});
																return (
																	<li>
																		{list}:
																		{boardData.cardsLength[i] === 0
																			? 100
																			: Math.round(
																					(1 - boardData.cardsLength[i] / totalCardsByBoard) * 100
																			  )}
																		%
																	</li>
																);
															});
														})}
													</ul>
												</ReactPlaceholder>
											</Accordion.Body>
										</Accordion.Item>
									))}
								</Accordion>
							</>
						) : isLoadingBoards ? (
							<div className='text-center w-100 mt-4'>
								<Spinner />
							</div>
						) : (
							<div className='text-muted text-center w-100 mt-4'>
								<h4>Não há dados</h4>
							</div>
						)}
					</div>
				</Card.Body>
			</Card>
		</>
	);
};

export default Trello;
