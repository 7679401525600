import styled from 'styled-components';

export const TableWrapper = styled.div`
  table {
    --data-table-library_grid-template-columns: minmax(0px, 450px) minmax(0px, max-content) minmax(0px, max-content) !important;
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .rb-tabs-header {
    display: flex;
    justify-content: center;

    margin-bottom: 2rem;
  }

  .tab-text {
    padding: 1rem !important;

    z-index: 999;
  }
`;
