import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import reportWebVitals from './reportWebVitals';
import jwtDecode from 'jwt-decode';

import { AuthProvider } from './contexts/auth';
import useAuth from './hooks/useAuth';

//router
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
//store
import { Provider } from 'react-redux';
//reducer
import { store } from './store';

import SignIn from './views/auth/sign-in';
import { SimpleRouter } from './router/simple-router';
import { DefaultRouter } from './router/default-router';
import { DocumentationRouter } from './router/documentation-router';
import Default from './layouts/dashboard/default';
import { GlobalStyles } from './globalStyles';
import './custom-classes.css';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.locale('de');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

const Private = ({ Page, caminho, route }) => {
	const { isLogged } = useAuth();

	return isLogged ? <Default /> : <SignIn />;
	// return Page;
};

const Public = ({ page }) => {
	const { isLogged } = useAuth();

	return isLogged ? <Default /> : page;
};

const router = createBrowserRouter(
	[
		{
			path: '/',
			element: <Private Page={<Default />} caminho='/' />,
		},
		...DefaultRouter.map((rota) => {
			const token = JSON.parse(localStorage.getItem('@datahub-token'))?.token;

			let decoded = null;
			if (token) decoded = jwtDecode(token);
			var route = rota;
			route.children = route.children.filter((child) => {
				return child.permission.includes(decoded?.permission) || child.permission === 'all';
			});

			return {
				...route,
				element: <Private route={route} Page={route.element} caminho={route.path} />,
			};
		}),
		...SimpleRouter.map((rota) => ({
			...rota,
			element: <Public page={rota.element} />,
		})),
		...DocumentationRouter.map((rota) => ({
			...rota,
			element: rota.element,
		})),
	],
	{ basename: process.env.PUBLIC_URL }
);

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<AuthProvider>
					<GlobalStyles>
						<App>
							<RouterProvider router={router} />
						</App>
					</GlobalStyles>
				</AuthProvider>
			</QueryClientProvider>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
