import React, { useEffect, useMemo, useState } from 'react';
import { api } from '../../api/api';

import { TableWrapper } from '../table/style';

import { Col, Table as BTable, Dropdown, Modal, Button, Spinner, Card, Row, Badge } from 'react-bootstrap';

import { useQuery } from 'react-query';
import { useSessionStorage } from '@uidotdev/usehooks';

//icons
import { AiOutlineCheck } from 'react-icons/ai';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { TiStarHalfOutline } from 'react-icons/ti';
//components
import Chart from 'react-apexcharts';
import ReactPlaceholder from 'react-placeholder';
import CustomTooltip from '../customTooltip/CustomTooltip';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
SwiperCore.use([Navigation]);
import 'swiper/swiper-bundle.min.css';
import PiechartCard from '../piechartCard/piechartCard';
import columnLineData from '../../utils/charts/columnLineData';
import { productTypes } from '../../utils/types/productTypes';

const months = {
	0: 'Todos',
	1: 'Janeiro',
	2: 'Fevereiro',
	3: 'Março',
	4: 'Abril',
	5: 'Maio',
	6: 'Junho',
	7: 'Julho',
	8: 'Agosto',
	9: 'Setembro',
	10: 'Outubro',
	11: 'Novembro',
	12: 'Dezembro',
};

const AttendantDetails = ({ show, attendant, setShowAttendantDetails, year, totalYears, channel, product }) => {
	const [isLoading, setLoading] = useSessionStorage('isLoading', false);

	const [monthFilter, setMonthFilter] = useState(0);
	const [yearFilter, setYearFilter] = useState(0);

	const [attendantId, setAttendantId] = useState(0);
	const [osList, setOsList] = useState([]);
	const [servicesByYear, setServicesByYear] = useState();
	const [categoryDaysAllXFinished, setCategoryDaysAllXFinished] = useState([
		'Jan',
		'Fev',
		'Mar',
		'Abr',
		'Mai',
		'Jun',
		'Jul',
		'Ago',
		'Set',
		'Out',
		'Nov',
		'Dez',
	]);

	//queries
	const {
		data: attendantDetails,
		isLoading: isLoadingAttendantDetails,
		isRefetching: isRefetchingAttendantDetails,
		refetch: refetchAttendantDetails,
	} = useQuery(['attendantsDetails', attendantId], () => getUser(attendantId), {
		onError: (err) => console.log(err),
		onSettled: () => setLoading(false),
	});

	const getUser = async (id) => {
		setLoading(true);
		try {
			if (id === 0 || id === undefined) return;
			const response = await api.get(`/employees/quantity/details/${id}?year=${year}&channel=${channel}&product=${product}`);
			setLoading(false);

			return response?.data;
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const applyFilterInServices = (date) => {
		if (!attendantDetails) return;

		const services = attendantDetails?.servicesQuantityByMonth;

		let servicesTemplate = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];

		var servicesFiltered = services.map((data) => {
			if (new Date(data.mes).getUTCFullYear() === new Date(date).getFullYear()) {
				return data;
			}
		});

		for (const key in servicesFiltered) {
			const month = servicesFiltered[key];
			servicesTemplate[new Date(month?.mes).getUTCMonth()] = month?.quantity.toString();
		}

		setServicesByYear(servicesTemplate);
		return servicesTemplate;
	};

	useEffect(() => {
		applyFilterInServices(new Date().setFullYear(year));
	}, [attendantDetails]);
	useEffect(() => {
		setAttendantId(attendant?.id);
		refetchAttendantDetails();
	}, [attendant]);
	useEffect(() => {
		setMonthFilter(0);
		setYearFilter(0);
		setOsList([]);
	}, [attendantId]);
	// useEffect(() => {
	//   var filtered = attendantDetails?.filter((item) => {
	//     const date = new Date(item?.data_atendimento);
	//     if (
	//       (monthFilter === 0 ? 1 === 1 : date.getMonth() + 1 === monthFilter) &&
	//       (yearFilter === 0 ? 1 === 1 : date.getFullYear() === yearFilter)
	//     ) {
	//       return item;
	//     }
	//   });

	//   setOsList(filtered);
	// }, [monthFilter, yearFilter, attendantDetails]);

	const handleGetServicesByYear = useMemo(() => {
		var total = 0;

		if (attendantDetails) {
			attendantDetails?.servicesQuantityByMonth.map((services) => {
				if (new Date(services.mes).getUTCFullYear() === year) {
					total += Number(services.quantity);
				}
			});
		}

		return total;
	}, [attendantDetails]);

	return (
		<Modal show={show} fullscreen onHide={() => setShowAttendantDetails(false)}>
			<Modal.Header closeButton>
				<Modal.Title>
					<ReactPlaceholder
						type='text'
						rows={1}
						ready={!isRefetchingAttendantDetails}
						showLoadingAnimation={true}
						color='#E0E0E0'
					>
						{attendant?.name}
					</ReactPlaceholder>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ backgroundColor: 'var(--bs-body-bg)' }}>
				<div className='d-flex gap-3 mb-4'>
					<span className='fs-5'>Ano <Badge className='bg-primary'>{year}</Badge></span>
					<span className='fs-5'>Produto <Badge className='bg-primary fs-6'>{productTypes[product] ?? 'Todos'}</Badge></span>
					<span className='fs-5'>Canal <Badge className='bg-primary fs-6'>{channel ? channel : 'Todos'}</Badge></span>
				</div>
				<Col>
					{isRefetchingAttendantDetails ? (
						<div
							className='d-flex justify-content-center align-items-center'
							style={{ height: '300px' }}
						>
							<Spinner />
						</div>
					) : (
						<>
							<Swiper
								className='p-0 m-0 mb-2 list-inline '
								slidesPerView={5}
								spaceBetween={32}
								navigation={{
									nextEl: '.swiper-button-next',
									prevEl: '.swiper-button-prev',
								}}
								breakpoints={{
									320: { slidesPerView: 1 },
									550: { slidesPerView: 2 },
									991: { slidesPerView: 3 },
									1400: { slidesPerView: 3 },
									1500: { slidesPerView: 4 },
									1920: { slidesPerView: 5 },
								}}
							>
								<SwiperSlide className='card-slide card'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='bg-info text-white rounded p-3'>
												<svg
													width='22px'
													viewBox='0 0 24 24'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														fillRule='evenodd'
														clipRule='evenodd'
														d='M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z'
														stroke='currentColor'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Média de Atendimentos/Mês</p>
													<div>
														<CustomTooltip
															text={
																<div style={{ textAlign: 'left' }}>
																	<p>Total de atendimentos no ano</p>
																</div>
															}
														/>
													</div>
												</div>
												<h4 className='counter'>
													<ReactPlaceholder
														type='text'
														rows={1}
														ready={!isLoading}
														showLoadingAnimation={true}
														color='#E0E0E0'
													>
														{attendantDetails?.averageServiceQuantityByUserByYear ?? 0}
													</ReactPlaceholder>
												</h4>
											</div>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide className='card-slide card'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='bg-success text-white rounded p-3'>
												<TiStarHalfOutline size={22} />
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Média de avaliações</p>
													<div>
														<CustomTooltip
															text={
																<div style={{ textAlign: 'left' }}>
																	<p>Porcentagem de atendimentos avaliados</p>
																</div>
															}
														/>
													</div>
												</div>
												<h4 className='counter'>
													<ReactPlaceholder
														type='text'
														rows={1}
														ready={!isLoading}
														showLoadingAnimation={true}
														color='#E0E0E0'
													>
														{attendantDetails?.servicesRatePercent ?? 0}%
													</ReactPlaceholder>
												</h4>
											</div>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide className='card-slide card'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className=' bg-soft-info rounded p-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='22px'
													fill='none'
													viewBox='0 0 24 24'
													stroke='currentColor'
												>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														strokeWidth='2'
														d='M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
													></path>
												</svg>
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Nota média</p>
													<div>
														<CustomTooltip
															text={
																<div style={{ textAlign: 'left' }}>
																	<p>A média de nota das avaliações</p>
																</div>
															}
														/>
													</div>
												</div>
												<h4 className='counter'>
													<ReactPlaceholder
														type='text'
														rows={1}
														ready={!isLoading}
														showLoadingAnimation={true}
														color='#E0E0E0'
													>
														{attendantDetails?.averageServiceRateByUserByYear ?? 0}
													</ReactPlaceholder>
												</h4>
											</div>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide className='card-slide card'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='bg-warning text-white rounded p-3'>
												<svg
													width='22'
													viewBox='0 0 24 24'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														fillRule='evenodd'
														clipRule='evenodd'
														d='M21.25 12.0005C21.25 17.1095 17.109 21.2505 12 21.2505C6.891 21.2505 2.75 17.1095 2.75 12.0005C2.75 6.89149 6.891 2.75049 12 2.75049C17.109 2.75049 21.25 6.89149 21.25 12.0005Z'
														stroke='currentColor'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path
														d='M15.4316 14.9429L11.6616 12.6939V7.84692'
														stroke='currentColor'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Duração média</p>
													<div>
														<CustomTooltip
															text={
																<div style={{ textAlign: 'left' }}>
																	<p>
																		A duração média dos atendimentos é calculado com: Tempo total /
																		qtd atendimento
																	</p>
																</div>
															}
														/>
													</div>
												</div>
												<h4 className='counter'>
													<ReactPlaceholder
														type='text'
														rows={1}
														ready={!isLoading}
														showLoadingAnimation={true}
														color='#E0E0E0'
													>
														{attendantDetails?.averageServicesTimeByEmployeeByYear ?? 0}
													</ReactPlaceholder>
												</h4>
											</div>
										</div>
									</div>
								</SwiperSlide>
							</Swiper>
							<Row>
								<Col className=''>
									<Card>
										<Card.Header>
											<div className='header-title d-flex align-items-center'>
												<h5 className='mb-0'>Atendimentos ao longo do ano</h5>
												<CustomTooltip
													text={
														<div style={{ textAlign: 'left' }}>
															<p>Atendimentos por cliente no ano</p>
															<p>* Considera apenas o ano atual</p>
														</div>
													}
												/>
											</div>
										</Card.Header>
										<Card.Body>
											{servicesByYear ? (
												<ReactPlaceholder
													type='rect'
													style={{ height: '300px' }}
													ready={!isLoading}
													showLoadingAnimation={true}
													color='#E0E0E0'
												>
													<Chart
														options={
															columnLineData(
																[
																	{
																		name: year,
																		type: 'line',
																		data: servicesByYear,
																	},
																],
																categoryDaysAllXFinished,
																12
															).options
														}
														series={
															columnLineData(
																[
																	{
																		name: year,
																		type: 'line',
																		data: servicesByYear,
																	},
																],
																categoryDaysAllXFinished,
																12
															).series
														}
														type='area'
														height='250'
													/>
												</ReactPlaceholder>
											) : null}
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</>
					)}
				</Col>
			</Modal.Body>
		</Modal>
	);
};

export { AttendantDetails };
