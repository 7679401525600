import styled from 'styled-components';

export const ErrorDetails = styled.div`
	position: relative;

	padding: 2rem;
	margin-top: 0.5rem;

	background-color: var(--bs-light);
	border: 2px solid var(--bs-gray);
	border-radius: 4px;
`;
