import React, { useEffect, useState } from 'react';
//hooks
import { useQuery } from 'react-query';
//components
import Chart from 'react-apexcharts';
import { Button, Card, Dropdown } from 'react-bootstrap';
import ReactPlaceholder from 'react-placeholder';
import { stackedbar100 } from '../../utils/charts/stackedbar100Data';
import { api } from '../../api/api';

const deficitsTypes = {
	DEMAIS: 'Custos administrativos',
	FOLHA: 'Folha',
	VIAGEM: 'Viagens',
};

export const PreviewXRealMonth = () => {
	const [expectedAndRealizedBottom, setExpectedAndRealizedBottom] = useState();
	const [EnRBottomFilter, setEnRBottomFilter] = useState('');

	const { refetch: EnRBottomRefetch, isLoading } = useQuery(['expectedXrealizedBottom'], () =>
		getExpectedAndRealized(setExpectedAndRealizedBottom, EnRBottomFilter)
	);

	async function getExpectedAndRealized(setData, filter) {
		try {
			const response = await api.get(`/custos/expectedXrealized?category=${filter ?? ''}`);
			setData(response?.data);
			return response?.data;
		} catch (e) {
			console.error(e);
		}
	}
	const treatNumbers = (n) => {
		return Number(n?.toFixed(2) ?? 0);
	};

	useEffect(() => {
		EnRBottomRefetch();
	}, [EnRBottomFilter]);
	return (
		<Card>
			<Card.Header className='d-flex justify-content-between align-items-center'>
				<h5 className='card-title'>Previsto X Realizado mensal</h5>
				<Dropdown>
					<Dropdown.Toggle as={Button} variant='text-gray' type='button' id='dropdownMenuButtonSM'>
						{deficitsTypes[EnRBottomFilter] || 'Todos'}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item
							onClick={() => {
								setEnRBottomFilter('');
							}}
						>
							Todos
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => {
								setEnRBottomFilter('DEMAIS');
							}}
						>
							Custos administrativos
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => {
								setEnRBottomFilter('VIAGEM');
							}}
						>
							Viagens
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => {
								setEnRBottomFilter('FOLHA');
							}}
						>
							Folha
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Card.Header>
			<Card.Body className='d-flex flex-column gap-4'>
				<ReactPlaceholder
					type='rect'
					style={{ height: '300px' }}
					ready={!isLoading}
					showLoadingAnimation={true}
					color='#E0E0E0'
				>
					<Chart
						options={
							stackedbar100(
								[
									{
										name: 'Previsto',
										data: expectedAndRealizedBottom?.previsto?.map(treatNumbers) ?? [],
									},
									{
										name: 'Realizado',
										data: expectedAndRealizedBottom?.realizado?.map(treatNumbers) ?? [],
									},
								],
								'money'
							).options
						}
						series={
							stackedbar100(
								[
									{
										name: 'Previsto',
										data: expectedAndRealizedBottom?.previsto?.map(treatNumbers) ?? [],
									},
									{
										name: 'Realizado',
										data: expectedAndRealizedBottom?.realizado?.map(treatNumbers) ?? [],
									},
								],
								'money'
							).series
						}
						type='bar'
						height='400'
					/>
				</ReactPlaceholder>
			</Card.Body>
		</Card>
	);
};
