import styled from 'styled-components';

export const Quantity = styled.h1`
  color: var(--bs-gray-700);
`;

//232 = 200px do card + 32 de padding (2rem)
export const GroupWrapper = styled.div`
  height: ${(props) => (props.isMinimized ? 0 : props.list * 182) + 'px'};

  cursor: default;
  overflow: hidden;

  transition: 0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
`;
