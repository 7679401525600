import React from 'react';
import Index from '../views/dashboard/index';
// import { Switch, Route } from 'react-router-dom'
// user
import UserProfile from '../views/dashboard/app/user-profile';
import UserAdd from '../views/dashboard/app/user-add';
// map
import Vector from '../views/dashboard/maps/vector';
import Google from '../views/dashboard/maps/google';

//Special Pages
import Kanban from '../views/dashboard/special-pages/kanban';
import Calender from '../views/dashboard/special-pages/calender';
import Trello from '../views/dashboard/Trello';

//admin
import Admin from '../views/dashboard/admin/admin';
import Default from '../layouts/dashboard/default';
import DualCompact from '../layouts/dashboard/dual-compact';
import UserList from '../views/dashboard/Users';

//Dashboard
import Productivity from '../views/dashboard/Productivity';
import Rh from '../views/dashboard/Rh';
import DevsOverview from '../views/dashboard/DevsOverview';
import ServicesOverview from '../views/dashboard/ServicesOverview';
import AttendantDash from '../views/dashboard/Attendant';
import Clients from '../views/dashboard/Clients';
import Products from '../views/dashboard/Products';
import Error404 from '../views/dashboard/ErrorsBoundary/error404';
import Flow from '../views/dashboard/Flow';
import LazyPage from '../components/lazyPage/lazyPage';
import CallCenter from '../views/dashboard/CallCenter';
import Documentation from '../views/documentation/index';
import Financial from '../views/dashboard/Financial';
import CallsDetails from '../views/dashboard/CallsDetails';
import WhatsappOverview from '../views/dashboard/WhatsappOverview';
import Maintenance from '../views/dashboard/ErrorsBoundary/maintenance';

export const DefaultRouter = [
	{
		path: '/',
		element: <Default />,

		children: [
			{
				index: true,
				path: '/',
				element: <LazyPage component={<Index />} />,

				permission: ['D', 'SA', 'SM', 'FM'],
			},

			{
				path: 'dashboard',
				element: <LazyPage component={<Index />} />,
				permission: ['D', 'SA', 'SM', 'FM'],
			},
			{
				path: 'dashboard/services/attendants',
				element: <LazyPage component={<AttendantDash />} />,
				permission: ['A', 'D'],
			},
			{
				path: 'dashboard/services/clients',
				element: <LazyPage component={<Clients />} />,
				permission: ['A', 'D'],
			},
			{
				path: 'dashboard/services/products',
				element: <LazyPage component={<Products />} />,
				permission: ['A', 'D'],
			},
			//Esperando aprovação
			{
				path: 'dashboard/services/whatsapp',
				element: <WhatsappOverview />,
				permission: 'D',
			}, //
			{
				path: 'dashboard/services',
				element: <LazyPage component={<ServicesOverview />} />,
				permission: ['PM', 'D'],
			},
			{
				path: 'dashboard/productivity',
				element: <LazyPage component={<Productivity />} />,
				permission: 'D',
			},
			{
				path: 'dashboard/rh',
				element: <LazyPage component={<Rh />} />,
				permission: ['PM', 'D'],
			},
			//Esperando aprovação
			{
				path: 'dashboard/financial',
				element: <Financial />,
				permission: ['D'],
			}, //

			{
				path: 'dashboard/developers',
				element: <LazyPage component={<DevsOverview />} />,
				permission: 'D',
			},
			{
				path: 'dashboard/flow',
				element: <LazyPage component={<Flow />} />,
				permission: 'D',
			},
			{
				path: 'dashboard/call-center',
				element: <LazyPage component={<CallCenter />} />,
				permission: 'D',
			},
			//Esperando aprovação
			{
				path: 'dashboard/call-center/details',
				element: <CallsDetails />,
				permission: 'D',
			}, //
			{
				path: 'admin/user-list',
				element: <LazyPage component={<UserList />} />,
				permission: 'D',
			},
			{
				path: 'trello/salvador',
				element: <Trello />,
				permission: 'D',
			},
			{
				path: 'trello/barra-mansa',
				element: <Maintenance />,
				permission: 'D',
			},
			{
				path: 'dashboard/special-pages/calender',
				element: <Calender />,
				permission: 'S/N',
			},
			{
				path: 'dashboard/app/user-profile',
				element: <UserProfile />,
				permission: 'G',
			},
			{
				path: 'dashboard/app/user-add',
				element: <UserAdd />,
				permission: 'S/N',
			},
			{
				path: 'dashboard/admin/admin',
				element: <Admin />,
				permission: 'S/N',
			},
			// Map
			{
				path: 'dashboard/map/google',
				element: <Google />,
				permission: 'S/N',
			},
			{
				path: 'dashboard/map/vector',
				element: <Vector />,
				permission: 'S/N',
			},
			{
				path: '*',
				element: <Error404 />,
				permission: 'all',
			},
		],
	},
];
