import React, { useEffect, useState, useCallback } from 'react';
import { api } from '../../../api/api';
import { Link } from 'react-router-dom';

//hooks
import { useQuery, useMutation } from 'react-query';
import { useSessionStorage } from '@uidotdev/usehooks';
//styles
import { TableWrapper } from './style';
//components
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
	Cell,
} from '@table-library/react-table-library/table';
import { Col, Image, Form, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';
// import * as RBRow from 'react-bootstrap/Row';
import Card from '../../../components/card/Card';
import { Modal, Button } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import InputMask from 'react-input-mask';
import { useSort, HeaderCellSort } from '@table-library/react-table-library/sort';
import ReactPlaceholder from 'react-placeholder';
import { TableR } from '../../../components/table/Table';
import { ChangeRole } from '../../../components/changeRole/changeRole';
import { CreateUser } from '../../../components/createUser/createUser';
import { BsPersonPlusFill } from 'react-icons/bs';
import Searchbox from '../../../components/searchbox/searchbox';

const roles = {
	G: 'Gerente',
	D: 'Diretor',
	P: 'Gestor de Pessoas',
};
const UserList = () => {
	//components
	const [isLoading, setLoading] = useSessionStorage('loading');
	const [showChangeRole, setShowChangeRole] = useState(false);
	const [createUser, setCreateUser] = useState(false);
	const [userToChange, setUserToChange] = useState();
	const [searchList, setSearchList] = useState({ nodes: [] });

	const { data: users, refetch: refetchUsers } = useQuery({
		queryKey: 'users',
		queryFn: async () => {
			const response = await api.get('/users');

			setSearchList({ nodes: response?.data });
			return response?.data;
		},
	});

	const handleShowChangeModal = (userId) => {
		setUserToChange(userId);
		setShowChangeRole(true);
	};

	return (
		<>
			<div>
				<div className='d-flex'>
					<Col sm='12'>
						<Card>
							<Card.Header className='d-flex'>
								<div
									className='header-title d-flex justify-content-between align-items-center'
									style={{ width: '100%' }}
								>
									<div className='d-flex align-items-center gap-2'>
										<h4 className='card-title'>Colaboradores</h4>
										<OverlayTrigger
											className='info'
											placement='auto'
											overlay={<Tooltip>Adicionar usuário</Tooltip>}
										>
											<Button
												className='d-flex align-items-center gap-2'
												onClick={() => setCreateUser(true)}
											>
												<BsPersonPlusFill />
											</Button>
										</OverlayTrigger>
									</div>

									<Searchbox
										list={users}
										setResult={setSearchList}
										atributeToSearch={'nomePessoa'}
									/>
								</div>
							</Card.Header>

							<Card.Body className='px-0'>
								<TableR
									columns={[
										'Nome',
										'E-mail',
										'Cargo',
										'CPF/CNPJ',
										'Última alteração de senha',
										'Atividade',
									]}
									dataColumns={[
										'nomePessoa',
										'email',
										'permissoes',
										'cpfCnpj',
										'dataMudancaSenha',
										'isActive',
									]}
									isRefetching={false}
									data={searchList.nodes?.map((obj) => ({
										id: obj.pessoaID, // Renomeia pessoaID para id
										usuarioID: obj.usuarioID, // Mantém o usuarioID inalterado
										nomePessoa: obj.nomePessoa,
										permissoes: roles[obj.permissoes],
										email: obj.email,
										login: obj.login,
										dataMudancaSenha: obj.dataMudancaSenha,
										cpfCnpj: obj.cpfCnpj,
										isActive: obj.ativo,
									}))}
									limitTable={4}
									firstColumnFixed={true}
									action={handleShowChangeModal}
									actionType={'actionWithId'}
									actionButton={
										<Link
											className='btn btn-sm btn-icon btn-warning'
											data-toggle='tooltip'
											data-placement='top'
											title='Edit'
											data-original-title='Edit'
										>
											<i className='btn-inner'>
												<svg
													width='20'
													viewBox='0 0 24 24'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341'
														stroke='currentColor'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													></path>
													<path
														fillRule='evenodd'
														clipRule='evenodd'
														d='M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z'
														stroke='currentColor'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													></path>
													<path
														d='M15.1655 4.60254L19.7315 9.16854'
														stroke='currentColor'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													></path>
												</svg>
											</i>
										</Link>
									}
								/>
							</Card.Body>
						</Card>
					</Col>
				</div>
			</div>

			<CreateUser show={createUser} setShow={setCreateUser} refetchUsers={refetchUsers} />

			<ChangeRole
				item={userToChange}
				show={showChangeRole}
				setShow={setShowChangeRole}
				refetchUsers={refetchUsers}
			/>
		</>
	);
};

export default UserList;
