import React, { useEffect } from 'react';
import { themes } from './themes';

const moneyFormat = new Intl.NumberFormat('pt-BR', {
	style: 'currency',
	currency: 'BRL',
});

const stockXAccumulated = (series, categories, colorPrimary, format) => {
	let cat = categories;
	if (categories.length < 12 && categories.length > 6) {
		const diasAbreviados = categories.map((data) => {
			const dataObj = new Date(data);
			const diasDaSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
			const nomeDiaAbreviado = diasDaSemana[dataObj.getDay() === 6 ? 0 : dataObj.getDay() + 1];
			return nomeDiaAbreviado;
		});
		cat = diasAbreviados;
	}
	if (categories.length > 12) {
		const newDates = categories.map((date) => {
			const [ano, mes, dia] = date.split('-');
			return `${dia}/${mes}`;
		});
		cat = newDates;
	}

	const getVariableColor = () => {
		let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
		if (prefix) {
			prefix = prefix.trim();
		}
		const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
		const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
		const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
		const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
		return {
			primary: color1.trim(),
			info: color2.trim(),
			warning: color4.trim(),
			primary_light: color3.trim(),
		};
	};
	const variableColors = getVariableColor();

	const colors = [variableColors[colorPrimary], variableColors.info];
	useEffect(() => {
		return () => colors;
	});

	const stockXAccumulated = {
		options: {
			chart: {
				stacked: false,
				toolbar: {
					show: false,
				},
			},
			colors: themes[colorPrimary] || themes.secondary,
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '60%',
					endingShape: 'rounded',
					borderRadius: 5,
				},
			},
			legend: {
				show: true,
				position: 'bottom',
				offsetY: 10,
			},
			dataLabels: {
				enabled: false,
			},
			noData: {
				text: 'Nenhum dado encontrado!',
				align: 'center',
				verticalAlign: 'middle',
				style: {
					fontSize: '16px',
				},
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent'],
			},
			xaxis: {
				tickAmount: 5,
				labels: {
					minHeight: 22,
					maxHeight: 22,
					style: {
						colors: '#8A92A6',
					},
				},
				categories: cat,
			},
			yaxis: {
				title: {
					text: '',
				},
				labels: {
					minWidth: 22,
					maxWidth: 22,
					rotate: 0,
					style: {
						colors: '#8A92A6',
					},
				},
			},
			fill: {
				opacity: 1,
			},
			tooltip: {
				enabled: true,
				y: {
					formatter: function (val) {
						return format === 'money' ? moneyFormat.format(val) : val;
					},
				},
			},
		},
		series: series,
	};
	return stockXAccumulated;
};

export default stockXAccumulated;
