import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BiSolidHelpCircle } from 'react-icons/bi';

const useFormattedDate = () => {
	const setFormattedDate = (date) => {
		if (!date)
			return (
				<NullTooltip
					emptyValue={
						<ol className='text-start'>
							<p>Este campo apresenta-se como VAZIO nas seguintes condições:</p>
							<li>Etapa Pendente: Ainda não foi alcançada pelo usuário.</li>
						</ol>
					}
				/>
			);
		const formattedDate = `${new Date(date).getDate().toString().padStart(2, '0')}/${(
			new Date(date).getMonth() + 1
		)
			.toString()
			.padStart(2, '0')}/${new Date(date).getFullYear()}`;

		return formattedDate;
	};
	return { setFormattedDate };
};

const NullTooltip = ({ emptyValue }) => {
	return (
		<OverlayTrigger
			className='info w-max-content'
			placement='bottom'
			overlay={<Tooltip className='custom-tooltip text-left'>{emptyValue || 'Vazio'}</Tooltip>}
		>
			<span className='text-muted'>
				<BiSolidHelpCircle size={20} /> Vazio
			</span>
		</OverlayTrigger>
	);
};

export default useFormattedDate;
