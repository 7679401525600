import React, { useEffect, useMemo, useState } from 'react';
import { api } from '../../../api/api';
//pseudotypes
import { productTypes } from '../../../utils/types/productTypes';
//components
import { DateWrapper } from '../../../components/datepickerWrapper/style';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
	Cell,
} from '@table-library/react-table-library/table';
import { PaginationButton } from '../../../components/table/style';
//charts
import Chart from 'react-apexcharts';
import columnChartData from '../../../utils/charts/columnChartData';
import { Button, Card, Col, Dropdown, Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import ClientDetails from '../../../components/clientDetails/clientDetails';
import { useSort, HeaderCellSort } from '@table-library/react-table-library/sort';
import { usePagination } from '@table-library/react-table-library/pagination';
import { useTheme } from '@table-library/react-table-library/theme';
import { useDebounce } from '@uidotdev/usehooks';
import ChartFilters from '../../../components/chartFilters/chartFilters';

const Clients = () => {
	//table
	const [dataTable, setDataTable] = useState();
	const [limitTable, setLimitTable] = useState(20);
	//charts
	const [clientServicesQuantities, setClientServicesQuantities] = useState([]);
	const [queryMethods, setQueryMethods] = useState();
	const [servicesFilters, setServicesFilters] = useState({});
	const [servicesChannelFilter, setServicesChannelFilter] = useState('Todos');
	const [servicesProductFilter, setServicesProductFilter] = useState('Todos');

	const [showClientDetails, setShowClientDetails] = useState(false);

	const [totalYears, setTotalYears] = useState([
		'2018',
		'2019',
		'2020',
		'2021',
		'2022',
		'2023',
		'2024',
	]);

	const debouncedChart = useDebounce(clientServicesQuantities, 500);

	//table
	const pagination = usePagination(dataTable, {
		state: {
			page: 0,
			size: limitTable,
		},
		// onChange: onPaginationChange,
	});
	const theme = useTheme({
		BaseCell: `
			&:nth-of-type(1) {
				left: 0px;
			}`,
	});
	const sort = useSort(
		dataTable,
		{
			state: {
				sortKey: 'name',
				reverse: false,
			},
		},
		{
			sortIcon: {
				iconDefault: null,
				iconUp: null,
				iconDown: null,
			},
			sortFns: {
				name: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
				chartbar: (array) => array.sort((a, b) => a.qtdOS - b.qtdOS),
			},
		}
	);

	return (
		<>
			<Col>
				<Card>
					<Card.Body>
						<div
							className='header-title d-flex justify-content-between align-items-center'
							style={{ width: '100%' }}
						>
							<h4 className='card-title'>Atendimentos por clientes</h4>
						</div>

						<ChartFilters
							product={true}
							channel={true}
							period={true}
							channelFilter={servicesChannelFilter}
							productFilter={servicesProductFilter}
							setProduct={setServicesProductFilter}
							filtersController={servicesFilters}
							setChannel={setServicesChannelFilter}
							setFiltersController={setServicesFilters}
							dates={totalYears}
							data={'clientServicesQuantities'}
							setData={setClientServicesQuantities}
							setQueryMethods={setQueryMethods}
							query={'/services/quantities/client'}
						/>
					</Card.Body>
				</Card>

				{/* {dataTable ? (
          <div className='table-responsive'>
            <TableWrapper>
              <div style={{ textAlign: 'end' }}>
                <PaginationButton
                  type='button'
                  disabled={pagination.state.page === 0}
                  onClick={() => pagination.fns.onSetPage(0)}
                >
                  <LuArrowLeftToLine />
                </PaginationButton>
                <PaginationButton
                  type='button'
                  disabled={pagination.state.page === 0}
                  onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
                >
                  <LuArrowLeft />
                </PaginationButton>
                <PaginationButton
                  type='button'
                  disabled={
                    pagination.state.page + 1 >= pagination.state.getTotalPages(dataTable.nodes)
                  }
                  onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
                >
                  <LuArrowRight />
                </PaginationButton>
                <PaginationButton
                  type='button'
                  disabled={
                    pagination.state.page + 1 >= pagination.state.getTotalPages(dataTable.nodes)
                  }
                  onClick={() =>
                    pagination.fns.onSetPage(pagination.state.getTotalPages(dataTable.nodes) - 1)
                  }
                >
                  <LuArrowRightToLine />
                </PaginationButton>
              </div>

              <Table
                id='table-devs-os'
                data={dataTable}
                pagination={pagination}
                sort={sort}
                theme={theme}
                className='table'
                role='grid'
                data-toggle='data-table'
                style={{ width: '100vw' }}
              >
                {(tableList) => (
                  <>
                    <Header>
                      <HeaderRow>
                        <HeaderCell pinLeft sortKey='name'>
                          Nome
                        </HeaderCell>
                        <HeaderCell sortKey='chartbar'>================</HeaderCell>
                        <HeaderCell
                          sortKey='total'
                          style={{ textAlign: 'left', marginLeft: '15px' }}
                        >
                          Total
                        </HeaderCell>
                      </HeaderRow>
                    </Header>
                    <Body>
                      {tableList.map((client, index) => (
                        <>
                          <Row key={index} item={client}>
                            <Cell
                              onClick={() => (getUser(client.id), setDev(client))}
                              pinLeft
                              style={{
                                borderLeft: idsShowChart.includes(client.name)
                                  ? '4px solid var(--bs-primary)'
                                  : 'none',
                                borderRadius: idsShowChart.includes(client.name)
                                  ? '5px 0 0 0'
                                  : 'inherit',
                              }}
                            >
                              {client.name}
                            </Cell>

                            {client.qtdOS.map((data, index) => (
                              <Cell onClick={() => handleSetIdsChart(client.name)}>{data}</Cell>
                            ))}

                            <Cell onClick={() => handleSetIdsChart(client.name)}>
                              {client.totalOSYear}
                            </Cell>
                          </Row>
                          {idsShowChart.includes(client.name) ? (
                            <tr className='d-flex' pinLeft style={{ gridColumn: '1/-1' }}>
                              <Cell
                                pinLeft
                                style={{
                                  width: '100vw',
                                  boxShadow: 'rgb(218, 218, 218) 5px 5px 0px -2px',
                                  borderLeft: '4px solid var(--bs-primary)',
                                  borderRadius: '0 0 0 5px',
                                }}
                              >
                                <h6 className='text-muted' style={{ position: 'absolute' }}>
                                  Total: {client.totalOSYear}
                                </h6>
                                <Chart
                                  type='area'
                                  height='100'
                                  width='800'
                                  options={lineChartData(client.qtdOS).options}
                                  series={lineChartData(client.qtdOS).series}
                                />
                              </Cell>
                            </tr>
                          ) : null}
                        </>
                      ))}
                    </Body>
                  </>
                )}
              </Table>
              <div style={{ textAlign: 'end' }}>
                <PaginationButton
                  type='button'
                  disabled={pagination.state.page === 0}
                  onClick={() => pagination.fns.onSetPage(0)}
                >
                  <LuArrowLeftToLine />
                </PaginationButton>
                <PaginationButton
                  type='button'
                  disabled={pagination.state.page === 0}
                  onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
                >
                  <LuArrowLeft />
                </PaginationButton>
                <PaginationButton
                  type='button'
                  disabled={
                    pagination.state.page + 1 >= pagination.state.getTotalPages(dataTable.nodes)
                  }
                  onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
                >
                  <LuArrowRight />
                </PaginationButton>
                <PaginationButton
                  type='button'
                  disabled={
                    pagination.state.page + 1 >= pagination.state.getTotalPages(dataTable.nodes)
                  }
                  onClick={() =>
                    pagination.fns.onSetPage(pagination.state.getTotalPages(dataTable.nodes) - 1)
                  }
                >
                  <LuArrowRightToLine />
                </PaginationButton>
              </div>
            </TableWrapper>
          </div>
        ) : (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ height: '200px' }}
          >
            <Spinner />
          </div>
        )} */}
				<Card>
					<Card.Body>
						<LazyChart
							debouncedChart={debouncedChart}
							data={{
								setShowClientDetails,
								clientServicesQuantities: clientServicesQuantities?.servicesQuantitiesByClient,
							}}
							loadings={queryMethods?.clientServicesQuantitiesIsFetching}
						/>
					</Card.Body>
				</Card>
			</Col>

			{showClientDetails ? (
				<ClientDetails
					show={showClientDetails}
					client={
						clientServicesQuantities.servicesQuantitiesByClient?.filter((client) =>
							client.client.includes(showClientDetails.selected)
						)[0]
					}
					setShowClientDetails={setShowClientDetails}
					totalYears={totalYears}
				/>
			) : null}
		</>
	);
};

const LazyChart = ({ debouncedChart, data, loadings }) =>
	useMemo(
		() =>
			!loadings ? (
				<Chart
					options={
						columnChartData(
							data.clientServicesQuantities,
							'client',
							true,
							data.setShowClientDetails
						).options
					}
					series={
						columnChartData(
							data.clientServicesQuantities,
							'client',
							true,
							data.setShowClientDetails
						).series
					}
					type='bar'
					height='1200'
				/>
			) : (
				<Spinner />
			),
		[debouncedChart, data]
	);

export default Clients;
