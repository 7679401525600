const columnChartData = (data, labelAttribute, isHorizontal, setShowDetails) => {
	var total = 0;

	const columnChartData = {
		options: {
			chart: {
				type: 'bar',
				toolbar: {
					show: false,
				},
				events: {
					dataPointSelection: (event, chartContext, config) => {
						if (setShowDetails) {
							setShowDetails({
								selected: config.w.config.labels[config.dataPointIndex],
								show: true,
							});
						}
					},
				},
			},
			plotOptions: {
				bar: {
					borderRadius: 4,
					horizontal: isHorizontal,
					dataLabels: {
						position: 'top', // top, center, bottom
					},
				},
			},
			dataLabels: {
				enabled: !isHorizontal,
				formatter: function (val, opts) {
					return `${Math.round((val / total) * 100)}%`;
				},

				offsetX: isHorizontal ? 60 : 0,
				offsetY: isHorizontal ? 0 : -30,
				style: {
					fontSize: '14px',
					colors: ['#304758'],
				},
			},
			noData: {
				text: 'Nenhum dado encontrado!',
				align: 'center',
				verticalAlign: !isHorizontal ? 'middle' : 'top',
				style: {
					fontSize: '16px',
				},
			},
			grid: {
				show: false,
			},
			yaxis: {
				show: true,
			},
			labels: data !== undefined ? data.map((item) => item[labelAttribute] ?? 'Sem nome') : [],
			// xaxis: {
			// 	type: 'category',
			// 	categories: data.map((label) => label.client ?? 'Sem nome'),
			// },
		},
		series: [
			{
				name: 'Atendimentos',
				data:
					data !== undefined
						? data.map((item) => {
								total += item.quantity;
								return item.quantity ?? 0;
						  })
						: [],
			},
		],
	};

	return columnChartData;
};

export default columnChartData;
