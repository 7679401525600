import React, { useEffect } from 'react';
import { api } from '../../api/api';
//hooks
import { useQuery } from 'react-query';
//components
import { Card } from 'react-bootstrap';
import PiechartCard from '../piechartCard/piechartCard';

export const ServicesByEmployeesTypes = ({
	startDateDisplay,
	endDateDisplay,
	filters,
	product,
	channel,
}) => {
	const { data: employees, refetch: refetchEmployees } = useQuery(['employees'], async () =>
		getEmployees()
	);
	useEffect(() => {
		refetchEmployees();
	}, [channel, product]);
	async function getEmployees() {
		try {
			const filterController = handleFilterQuery(filters);
			const response = await api.get(
				`/services/quantities/user/types?initialDate=${
					new Date(startDateDisplay).toISOString().split('T')[0] ?? ''
				}&finalDate=${new Date(endDateDisplay).toISOString().split('T')[0] ?? ''}&${
					filterController ?? ''
				}`
			);

			return filterEmployees(response?.data);
		} catch (error) {
			console.log(error);
		}
	}
	const filterEmployees = (employees) => {
		const supportLevel = {
			4: 'Outros',
			3: 'Desenvolvedor',
			2: 'Analista',
			1: 'Suporte',
		};

		return Array.from(employees, (_, index) => {
			const employee = employees[index];
			return {
				name: String(supportLevel[employee.type]),
				quantity: Number(employee.quantity),
			};
		}).filter(employee => employee.quantity > 0);
	};

	const handleFilterQuery = (filtersController) => {
		//convertendo objeto em string params para requisição
		var filters = '';
		for (let index = 0; index < Object.keys(filtersController).length; index++) {
			const obj = Object.entries(filtersController)[index];
			filters = filters.concat(obj.toString().replace(',', '=') + '&');
		}

		return filters;
	};

	useEffect(() => {
		refetchEmployees();
	}, [startDateDisplay, endDateDisplay]);

	return (
		<Card>
			<PiechartCard
				title={`Atendimentos por nível`}
				tooltip={`Atendimentos por nível. Considerando apenas atendimentos fechados (chats finalizados e OS concluídas)
					`}
				data={employees}
				labelAttribute='name'
				quantityAttribute='quantity'
			/>
		</Card>
	);
};
