import React from 'react';

import DualCompact from '../layouts/dashboard/dual-compact';
import DocApi from '../views/documentation/views/DocApi';
import DocumentationPage from '../views/documentation';

export const DocumentationRouter = [
	{
		path: '/documentation',
		element: <DualCompact />,
		children: [
			{
				path: '1',
				element: <DocApi />,
			},
		],
	},
];
