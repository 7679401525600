import React, { useState } from 'react';
//stylw
import { PiWarningCircle } from 'react-icons/pi';
//components
import { Form, Modal, Alert, Button, Collapse } from 'react-bootstrap';

export const WhatsappSettings = ({ show, setShow }) => {
	const [prevProduction, setPrevProduction] = useState(true);
	const [production, setProduction] = useState(true);

	const handleClose = () => {
		setProduction(prevProduction);
		setShow(false);
	};

	return (
		<Modal show={show} className='back-blur' onHide={handleClose}>
			<Modal.Header closeButton>
				<h4>Configurações e Parametrização</h4>
			</Modal.Header>
			<Modal.Body className='d-flex flex-column gap-4'>
				<section className='d-flex align-items-center'>
					<Form.Check
						className='me-2'
						id='toggle-check'
						type='switch'
						variant='outline-secondary'
						checked={production}
						onClick={() => setProduction(!production)}
						aria-controls='collapse-production'
					/>

					<label htmlFor='toggle-check'>Produção</label>
				</section>
				<section>
					{!production && prevProduction && (
						<Alert
							id='collapse-production'
							variant='light'
							className='d-flex gap-2 align-items-start'
						>
							<PiWarningCircle className='text-warning' size={100} />
							<div>
								<b>Configuração sensível</b>
								<p>
									Alterar esse parâmetro desativará o BOT em produção. Tem certeza que deseja ativar
									o modo de desenvolvimento?
								</p>

								<div className='d-flex gap-2'>
									<Button variant='warning'>Não, cancelar.</Button>
									<Button variant='light'>Sim, ativar.</Button>
								</div>
							</div>
						</Alert>
					)}
				</section>
			</Modal.Body>
		</Modal>
	);
};
