import styled from 'styled-components';

export const MoreDetail = styled.button`
	overflow: hidden;

	
	position: relative;
	width: 100%;
	height: 100%;

	padding: 1.5rem;
	
	border: none;

	background-color: inherit;
	color: var(--bs-info);

	::before{
		content: '';
		position: absolute;
		left: 48%;

		height: 10px;
		width: 10px;
		border-radius: 50px;

		background-color: transparent;

		transition: 0.2s ease-in-out;
		
	}

	
	:not(.not-anim)::before{
		-webkit-animation: scale-up-center 0.8s ease-out both;
	        animation: scale-up-center 0.8s ease-out both;

		}
	
	@keyframes scale-up-center {
		0% {
			-webkit-transform: scale(1);
							transform: scale(1);
		}
		25%{
			background-color: rgb(111 134 231 / 14%);
		}
		75%{
			-webkit-transform: scale(20);
							transform: scale(20);
		}
		100% {
			-webkit-transform: scale(25);
							transform: scale(25);

			background-color: rgb(111 134 231 / 0%);
		}
	}
`;
