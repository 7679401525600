import React from 'react';

const GrowthBadge = ({ growth }) => {
  return (
    <div
      className={`d-flex align-items-center badge bg-${Number(growth) < 0 ? 'danger' : 'primary'}`}
      style={{ fontSize: '0.8rem', width: 'max-content' }}
    >
      <div style={{ transform: Number(growth) < 0 ? 'rotateZ(180deg)' : 'none' }}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20px'
          viewBox='0 0 20 20'
          fill='currentColor'
        >
          <path
            fillRule='evenodd'
            d='M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
            clipRule='evenodd'
          />
        </svg>
      </div>
      {growth}%
    </div>
  );
};

export default GrowthBadge;
