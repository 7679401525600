const usePhoneNumber = () => {
	const setPhoneNumber = (number) => {
		let phoneNumber = number;
		if (number.toString().startsWith('55')) phoneNumber = number.toString().slice(2);
		if (phoneNumber.length <= 10) {
			return phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
		}
		if (phoneNumber.length <= 11) {
			return phoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
		}
		return number;
	};
	return { setPhoneNumber };
};

export default usePhoneNumber;
