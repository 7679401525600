import React, { useState, useEffect } from 'react';
//hooks
import useAuth from '../../../hooks/useAuth';
//styles
import error from '../../../assets/images/error/undraw_cancel_errorGeneric.svg';
import { ErrorDetails } from './style';
import { MdOutlineFileCopy } from 'react-icons/md';
//components
import { Button, Image } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { ArrowExpansion } from '../../../components/table/arrowExpansion';
import { useNavigate } from 'react-router-dom';
import { roles } from '../../../utils/types/rolesTypes';
import jwtDecode from 'jwt-decode';
import useCache from '../../../hooks/useClearCache';

//Tratamento dedicado a rotas no Outlet, ou seja, o conteúdo em si.
const RenderingError = ({ genericError, resetErrorBoundary, setHasError }) => {
	const { clearStorages } = useCache();
	const [showError, setShowError] = useState(false);
	const userRole = jwtDecode(JSON.parse(localStorage.getItem('@datahub-token'))?.token)?.permission;

	const goHome = () => {
		//limpa storage para prevenir problemas de cache
		clearStorages();
		window.location = roles[userRole].index || '/';
	};

	const copyError = async () => {
		try {
			await navigator.clipboard.writeText(genericError?.message);
			toast.success('Texto copiado');
		} catch (err) {
			console.log('Não foi possível copiar');
		}
	};

	useEffect(() => {
		console.log(genericError?.stack);
		setHasError(true);
	}, []);

	return (
		<div
			className='d-flex flex-column align-items-center'
			style={{ height: '100vh', backgroundColor: 'white' }}
		>
			<Image src={error} style={{ maxWidth: '200px' }} className='img-fluid mb-4' />
			<h3>
				<b>Algo deu errado! Tente novamente mais tarde</b>
			</h3>
			<span className='mt-2'>
				Parece estar havendo um erro consecutivo que não conseguimos resolver automaticamente
			</span>
			<span className=''>Favor acione a equipe de suporte</span>
			<Button className='mt-4' onClick={() => goHome()}>
				Voltar para o início
			</Button>
			<Button className='mt-4' variant='warning' onClick={() => setShowError(!showError)}>
				Mostrar detalhes do erro <ArrowExpansion active={showError} />
			</Button>
			{showError && (
				<ErrorDetails className='col-md-6'>
					{genericError?.stack}
					<MdOutlineFileCopy
						size={22}
						onClick={copyError}
						style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}
					/>
				</ErrorDetails>
			)}
		</div>
	);
};

export default RenderingError;
