import styled from 'styled-components';

export const FlowColumn = styled.div`
  transition: all 0.2s ease-out;
  :hover {
    background-color: ${(props) => props.color};

    border-radius: 4px;
  }
`;
