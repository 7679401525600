import styled from 'styled-components';

export const SelectPeriod = styled.tr`
	background-color: ${(props) => (props.active ? '#7EB02C' : 'inherit')};
	color: ${(props) => (props.active ? 'white' : 'inherit')};
	font-weight: 600;

	border-radius: 4px;

	padding: 0.5rem 1rem;
	transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

	cursor: pointer;
	:hover{
		opacity: 0.8;
		color: var(--bs-primary)
	}

	td {
		color: ${(props) => (props.active ? 'white' : 'inherit')} !important;

	}
`;

export const DateWrapper = styled.div`
	z-index: 999;
	
	input{
		font-weight: 600;
		border: none;
		border-bottom: #567292 dashed 2px;

		max-width: 100px;
	}

	.react-datepicker{
		border: none;

		font-family: var(--bs-body-font-family);
	}
	.react-datepicker__header{
		background-color: inherit !important;
	}
	.react-datepicker__day--range-start{
		border-radius: 4px 0 0 4px !important;
	}
	.react-datepicker__day--range-end{
		border-radius: 0 4px 4px 0 !important;
	}
	.react-datepicker__day--selected, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
		background-color: var(--bs-primary) !important;
		color: white !important;
		border-radius: 0;
	}
	.react-datepicker__day--in-selecting-range{
		background-color: var(--bs-primary-tint-80);
		color: var(--bs-dark);
	}
	.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
		width: 2rem;
		height: 2rem;
		
		margin: 0.2rem 0 0.2rem 0;
		
	}
`;
