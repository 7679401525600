import styled from 'styled-components';

export const ClientImage = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	
	height: 50px;
	width: 50px;
	padding: 5px;

	border-radius: 50%;

	background-color: var(--bs-primary);
	box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.25);
`;

export const BotImage = styled.img`
	height: 50px;
	width: 50px;
	padding: 5px;

	border-radius: 50%;

	background-color: var(--bs-primary-tint-80);
	box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.25);
`;

export const Messages = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const MessageContainer = styled.div`
	display: flex;
	gap: 1rem;

	align-items: end;
	${({ user }) => (user === 'client' ? 'justify-content: start;' : 'justify-content: end;')}
`;

export const ClientMessage = styled.div`
	display: flex;
	flex-direction: column;

	min-height: 30px;
	max-width: 350px;

	padding: 1rem;
	
	border-radius: 1rem;
	border-bottom-left-radius: 0.2rem;

	background-color: var(--bs-gray-300);
	color: black;

	word-wrap: break-word;

	span{
		display: flex;
		justify-content: end;

		font-size: 0.9rem;
		color: var(--bs-gray-600);
	}
`;
export const MyMessage = styled.div`
	min-height: 30px;
	max-width: 350px;

	padding: 1rem;

	border-radius: 1rem;
	border-bottom-right-radius: 0.2rem;

	color: white;
	background-color: var(--bs-primary);

	word-wrap: break-word;

	span{
		display: flex;
		justify-content: end;

		font-size: 0.9rem;
		color: var(--bs-primary-shade-40);
	}
`;
