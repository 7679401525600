import React, { useState, useEffect, useMemo } from 'react';
//hooks
import { useDebounce } from '@uidotdev/usehooks';
//charts
import Chart from 'react-apexcharts';
import columnLineData from '../../utils/charts/columnLineData';
// AOS animation
import AOS from 'aos';
import 'aos';
import '../../../node_modules/aos/dist/aos.css';
//components
import { DualChartsSection } from '../../views/dashboard/style';
import { Card, Col, Spinner } from 'react-bootstrap';
import CustomTooltip from '../customTooltip/CustomTooltip';
import ReactPlaceholder from 'react-placeholder';
import ChartFilters from '../chartFilters/chartFilters';

const ServicesByLastYears = () => {
	const [lastYears, setLastYears] = useState();
	const debouncedChart = useDebounce(lastYears, 300);
	const [queryMethods, setQueryMethods] = useState();
	//Charts filters
	const [filtersColumnLineChart, setFiltersColumnLineChart] = useState({});
	const [channelFilter, setChannelFilter] = useState('Todos');
	const [productFilter, setProductFilter] = useState('Todos');
	//functional
	const categoryDaysAllXFinished = [
		'Jan',
		'Fev',
		'Mar',
		'Abr',
		'Mai',
		'Jun',
		'Jul',
		'Ago',
		'Set',
		'Out',
		'Nov',
		'Dez',
	];

	const applyFilterInServices = (date) => {
		if (!lastYears) return;

		const services = lastYears?.servicesQuantityByMonthByYear;

		let servicesTemplate = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

		var servicesFiltered = services.map((data) => {
			if (new Date(data.date).getUTCFullYear() === new Date(date).getFullYear()) {
				return data;
			}
		});

		for (const key in servicesFiltered) {
			const month = servicesFiltered[key];
			servicesTemplate[new Date(month?.date).getUTCMonth()] = month?.quantity;
		}

		return servicesTemplate;
	};

	//UseEffects==========
	useEffect(() => {
		setTimeout(() => queryMethods?.refetch, 100);
	}, [productFilter, channelFilter]);
	//anim
	useEffect(() => {
		AOS.init({
			startEvent: 'DOMContentLoaded',
			disable: function () {
				var maxWidth = 996;
				return window.innerWidth < maxWidth;
			},
			throttleDelay: 10,
			once: true,
			duration: 700,
			offset: 10,
		});
	});

	return (
		<DualChartsSection>
			<Col>
				<Card>
					<Card.Header className='flex-wrap d-flex justify-content-between'>
						<div className='header-title d-flex align-items-center'>
							<h5 className='mb-0'>Atendimentos dos últimos 3 anos</h5>
							<CustomTooltip
								text={
									<div style={{ textAlign: 'left' }}>
										<p>Total de atendimentos gerados pelos últimos 3 anos</p>
										<p>* Considera todo o período</p>
									</div>
								}
							/>
						</div>

						<ChartFilters
							product
							channel
							channelFilter={channelFilter}
							productFilter={productFilter}
							setProduct={setProductFilter}
							setChannel={setChannelFilter}
							filtersController={filtersColumnLineChart}
							setFiltersController={setFiltersColumnLineChart}
							data={'lastYears'}
							setData={setLastYears}
							setQueryMethods={setQueryMethods}
							query={'/services/new_vs_finished'}
						/>
					</Card.Header>

					<div style={{ position: 'relative' }}>
						<Card.Body
							style={{
								position: 'relative',
								filter: queryMethods?.isRefetching ? 'blur(5px)' : 'none',
							}}
						>
							<LazyChart
								debouncedChart={debouncedChart}
								loadings={queryMethods?.isLoading}
								data={{ categoryDaysAllXFinished, applyFilterInServices }}
							/>
						</Card.Body>
						{queryMethods?.isRefetching ? (
							<Spinner
								variant='primary'
								style={{ position: 'absolute', left: '50%', top: '50%' }}
							/>
						) : null}
					</div>
				</Card>
			</Col>
		</DualChartsSection>
	);
};

const LazyChart = ({ debouncedChart, data, loadings }) =>
	useMemo(
		() => (
			<ReactPlaceholder
				type='rect'
				style={{ height: '300px' }}
				ready={!loadings}
				showLoadingAnimation={true}
				color='#E0E0E0'
			>
				<Chart
					options={
						columnLineData(
							[
								{
									name: new Date().getFullYear(),
									type: 'column',
									data: data.applyFilterInServices(new Date()),
								},
								{
									name: new Date().getUTCFullYear() - 1,
									type: 'line',
									data: data.applyFilterInServices(
										new Date().setUTCFullYear(new Date().getUTCFullYear() - 1)
									),
								},
								{
									name: new Date().getUTCFullYear() - 2,
									type: 'line',
									data: data.applyFilterInServices(
										new Date().setUTCFullYear(new Date().getUTCFullYear() - 2)
									),
								},
							],
							data.categoryDaysAllXFinished,
							12
						).options
					}
					series={
						columnLineData(
							[
								{
									name: new Date().getFullYear(),
									type: 'column',
									data: data.applyFilterInServices(new Date()),
								},
								{
									name: new Date().getUTCFullYear() - 1,
									type: 'line',
									data: data.applyFilterInServices(
										new Date().setUTCFullYear(new Date().getUTCFullYear() - 1)
									),
								},
								{
									name: new Date().getUTCFullYear() - 2,
									type: 'line',
									data: data.applyFilterInServices(
										new Date().setUTCFullYear(new Date().getUTCFullYear() - 2)
									),
								},
							],
							data.categoryDaysAllXFinished,
							12
						).series
					}
					type='area'
					height='300'
				/>
			</ReactPlaceholder>
		),
		[debouncedChart]
	);
export default ServicesByLastYears;
