import styled from 'styled-components';

export const TableWrapper = styled.div`
	table{
		--data-table-library_grid-template-columns: 
		minmax(0px, max-content) 
		minmax(0px, max-content) 
		minmax(0px, max-content) 
		minmax(0px, max-content) 
		minmax(0px, max-content) 
		minmax(0px, max-content) 
		minmax(0px, max-content) 
		minmax(0px, max-content) 
		minmax(0px, max-content) !important;
	}
`;
export const TD = styled.td`
	padding: 0.3rem 0.8rem;

	color: transparent;
`;
export const PaginationButton = styled.button`
	padding: 0rem 1rem;
	border: 2px solid ${(props) => (props.bold ? 'transparent' : 'var(--bs-light)')};
	font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
	background:none;
`;

export const Arrow = styled.div`
	transform: ${(props) => (props.active ? 'rotateZ(180deg)' : 'rotateZ(0deg)')};

	transition: 0.3s all cubic-bezier(0.68, -0.55, 0.27, 1.55);
`;

export const TableWrapperTest = styled.div`
	table{
		--data-table-library_grid-template-columns:
		${(props) => (props.columns ? `repeat(${props.columns}, max-content)` : '1fr')} !important;
	}
	th:nth-of-type(1) {
		${(props) => (props.firstColumnFixed ? 'left: 0px;' : '')};
		box-shadow: #ececec 5px 0px 5px -2px;
	}
	td:nth-of-type(1) {
		left: 0px;
		box-shadow: #ececec 5px 0px 5px -2px;
	}
	th {
		background-color: var(--bs-white);
	}
	td {
		max-width: 600px;
		text-align: center;
		white-space: wrap;
		background-color: var(--bs-white);		
	}
	td:hover {
		background-color: var(--bs-primary-tint-80) !important;
	}
`;
