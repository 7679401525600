import { useEffect, memo, Fragment, useContext, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';

import { useSessionStorage } from '@uidotdev/usehooks';

//react-shepherd
import { ShepherdTourContext } from 'react-shepherd';

// header
import Header from '../../components/partials/dashboard/HeaderStyle/header';

//subheader
import SubHeader from '../../components/partials/dashboard/HeaderStyle/sub-header';

//sidebar
import Sidebar from '../../components/partials/dashboard/SidebarStyle/sidebar';

//footer
import Footer from '../../components/partials/dashboard/FooterStyle/footer';

import Loader from '../../components/Loader';
//components
import { Spinner } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
// AOS animation
import AOS from 'aos';
import 'aos';
import '../../../node_modules/aos/dist/aos.css';

//style
import { ContentWrapper } from './style';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryPage from '../../views/dashboard/ErrorsBoundary/Generic';
import RenderingError from '../../views/dashboard/ErrorsBoundary/Generic-alt';
import useCache from '../../hooks/useClearCache';

const Tour = () => {
	const tour = useContext(ShepherdTourContext);
	const { pathname } = useLocation();
	useEffect(() => {
		if (pathname === '/dashboard' && sessionStorage.getItem('tour') !== 'true') {
			tour?.start();
		}
	});
	return <Fragment></Fragment>;
};

const Default = memo((props) => {
	const { clearStorages } = useCache();
	const [isLoading, setLoading] = useSessionStorage('isLoading', false);
	const [hasError, setHasError] = useState(false);
	const [countErrors, setCountErrors] = useState(0);
	const appName = 'COPLAN';

	useEffect(() => {
		AOS.init({
			startEvent: 'DOMContentLoaded',
			disable: function () {
				var maxWidth = 996;
				return window.innerWidth < maxWidth;
			},
			throttleDelay: 10,
			once: true,
			duration: 700,
			offset: 10,
		});
	});

	useEffect(() => {
		//contagem de erros de renderizacão
		if (hasError) {
			clearStorages();
			setCountErrors(countErrors + 1);
			toast.error('Ocorreu um erro inesperado! Atualizando a página...');
		}
		setHasError(false);
	}, [hasError]);

	return (
		<ErrorBoundary
			FallbackComponent={(error, resetErrorBoundary) => (
				<ErrorBoundaryPage genericError={error?.error} resetErrorBoundary={resetErrorBoundary} />
			)}
		>
			<Loader />
			<Sidebar app_name={appName} />
			<Tour />
			<main className='main-content'>
				<div className='position-relative'>
					<Header />
					<SubHeader />
				</div>

				<ContentWrapper
					className='py-0 conatiner-fluid content-inner mt-n5'
					data-aos='zoom-out-up'
					data-aos-delay='800'
				>
					{(!hasError || countErrors > 0) && (
						<ErrorBoundary
							FallbackComponent={(error, resetErrorBoundary) => (
								<RenderingError
									genericError={error?.error}
									resetErrorBoundary={resetErrorBoundary}
									setHasError={setHasError}
								/>
							)}
						>
							<Outlet />
						</ErrorBoundary>
					)}
				</ContentWrapper>
				<Toaster position='bottom-right' reverseOrder={false} />
				{isLoading ? (
					<div className='btn-download'>
						<Spinner animation='border' variant='primary' role='status'>
							<span className='visually-hidden'>Loading...</span>
						</Spinner>
					</div>
				) : null}

				<Footer />
			</main>
			{/* <SettingOffCanvas />
      <Link className="btn btn-fixed-end btn-secondary btn-icon btn-dashboard" to="/landing-modules/home" target="_blank" >
        Landing Pages
      </Link> */}
		</ErrorBoundary>
	);
});

Default.displayName = 'Default';
export default Default;
