const lineChartData = (data, labelAttribute, quantityAttribute) => {
	const lineChartData = {
		options: {
			chart: {
				sparkline: {
					enabled: true,
				},
				group: 'sparklines',
				toolbar: {
					show: false,
				},
			},
			dataLabels: {
				enabled: false,
			},
			noData: {
				text: 'Nenhum dado encontrado!',
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: undefined,
					fontSize: '16px',
					fontFamily: undefined,
				},
			},
			stroke: {
				curve: 'straight',
			},
			grid: {
				show: false,
			},
			colors: ['#00BAEC'],
			markers: {
				size: 5,
				colors: ['#136d85'],
				strokeColor: '#00BAEC',
				strokeWidth: 3,
			},
			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 1,
					opacityFrom: 0.7,
					opacityTo: 0.9,
					stops: [0, 90, 100],
				},
			},
			yaxis: {
				show: false,
			},
			labels: [
				'Janeiro',
				'Fevereiro',
				'Março',
				'Abril',
				'Maio',
				'Junho',
				'Julho',
				'Agosto',
				'Setembro',
				'Outubro',
				'Novembro',
				'Dezembro',
			],
		},

		series: [
			{
				name: `OS's`,
				data: data,
			},
		],
	};

	return lineChartData;
};

export default lineChartData;
