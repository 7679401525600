const piechartData = (data, labelAttribute, quantityAttribute, filtered, legendPosition) => {
	let listBySector = [];
	const legendLimit = 4;
	const sortedEmployeesBySector = data.sort((a, b) => b[quantityAttribute] - a[quantityAttribute]);

	// Pegar os três primeiros setores
	const topThreeSectors = sortedEmployeesBySector.slice(
		0,
		filtered ? legendLimit : sortedEmployeesBySector.length
	);

	// Calcular a quantidade total dos outros setores
	const totalOtherSectors = sortedEmployeesBySector
		.slice(filtered ? legendLimit : sortedEmployeesBySector.length)
		.reduce((total, channel) => total + Number(channel[quantityAttribute]), 0);

	// Criar uma entrada "Outros" com a quantidade total
	const otherSectors = { [labelAttribute]: 'Outros', [quantityAttribute]: totalOtherSectors };

	// Criar a lista final com os três primeiros e a entrada "Outros"
	sortedEmployeesBySector.length > legendLimit
		? (listBySector = [...topThreeSectors, otherSectors])
		: (listBySector = [...topThreeSectors]);

	if (
		listBySector.length > 0 &&
		listBySector[listBySector.length - 1][quantityAttribute] === 0 &&
		listBySector[listBySector.length - 1][labelAttribute] === 'Outros'
	) {
		listBySector.pop();
	}

	const piechartData = {
		options: {
			labels: listBySector.map((channel) => channel[labelAttribute]) ?? ['sem dados'],
			dataLabels: {
				enabled: true,
			},
			stroke: {
				width: 1,
			},
			legend: {
				show: true,
				position: legendPosition || 'right',
				horizontalAlign: 'left',
				width: 150, //limita tamanho de legenda
			},
			noData: {
				text: undefined,
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: undefined,
					fontSize: '16px',
					fontFamily: undefined,
				},
			},
			responsive: [
				{
					breakpoint: 998,
					options: {
						legend: {
							show: false,
						},
					},
				},
			],
		},
		series: listBySector.map((channel) => Number(channel[quantityAttribute])) ?? [0],
	};

	return piechartData;
};

export default piechartData;
