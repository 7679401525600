import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { api } from '../../api/api';
import jwtDecode from 'jwt-decode';
//hooks
import { useForm, Controller, useWatch } from 'react-hook-form';
import useAuth from '../../hooks/useAuth';
//pseudotypes
import { roles } from '../../utils/types/rolesTypes';
//components
import * as Yup from 'yup';
import { Row, Col, Image, Form, Button, ListGroup, Spinner, Alert } from 'react-bootstrap';
import Card from '../../components/card/Card';
import { CpfMask } from '../../components/cpfCnpjMask/cpfCnpjMask';
//imgs
import auth2 from '../../assets/images/auth/Graphic-side.png';
import logoCoplan from '../../assets/images/brands/coplan1-high.png';

const SignIn = () => {
	let navigate = useNavigate();
	const { register, control, watch, handleSubmit } = useForm();
	const { login } = useAuth();
	const [isLoading, setLoading] = useState();
	const [error, setError] = useState();

	const email = watch('email');
	const password = watch('password');

	const handleLogin = async () => {
		setLoading(true);
		setError(null);

		const validationSchema = Yup.object().shape({
			email: Yup.string().required('CPF/CNPJ é obrigatório'),
			password: Yup.string().required('Senha é obrigatória'),
		});
		try {
			await validationSchema.validate({
				email: email,
				password: password,
			});
		} catch (error) {
			setError(error.message);
			setLoading(false);
			return;
		}
		const response = await login(email.replace(/\D/g, ''), password);

		if (response?.status === 200) {
			const userRole = jwtDecode(
				JSON.parse(localStorage.getItem('@datahub-token'))?.token
			).permission;

			window.location.href = roles[userRole].index;
		}
		if (response?.response?.status === 401) {
			setError('CPF/CNPJ ou senha inválidos');
		}
		setLoading(false);
	};

	return (
		<>
			<section className='login-content'>
				<Row className='m-0 align-items-center bg-white vh-100'>
					<Col md='6'>
						<Row className='justify-content-center'>
							<Col md='10'>
								<Card className='card-transparent shadow-none d-flex justify-content-center mb-0 auth-card'>
									<Card.Body>
										<Link className='navbar-brand d-flex justify-content-center align-items-center mb-3'>
											<img src={logoCoplan} height={80} />
										</Link>
										<h2 className='mb-2 text-center'>Entrar</h2>
										<p className='text-center'>Use as credenciais de admin ou contate o suporte.</p>
										<Form onSubmit={handleSubmit(handleLogin)}>
											<Row>
												<Col lg='12'>
													<Form.Group className='form-group'>
														<Form.Label htmlFor='email' className=''>
															CPF/CNPJ
														</Form.Label>

														<Controller
															name='email'
															control={control}
															render={({ field }) => (
																<CpfMask
																	value={field.value}
																	onChange={field.onChange}
																	disabled={field.disabled}
																>
																	<Form.Control type='text' />
																</CpfMask>
															)}
														/>
													</Form.Group>
												</Col>
												<Col lg='12' className=''>
													<Form.Group className='form-group'>
														<Form.Label htmlFor='password' className=''>
															Senha
														</Form.Label>
														<Form.Control
															type='password'
															className=''
															id='password'
															aria-describedby='password'
															placeholder=' '
															{...register('password')}
														/>
													</Form.Group>
												</Col>
												{error ? <Alert variant='danger'>{error}</Alert> : null}
												{/* <Col lg='12' className='d-flex justify-content-between'>
													<Form.Check className='form-check mb-3'>
														<Form.Check.Input type='checkbox' id='customCheck1' />
														<Form.Check.Label htmlFor='customCheck1'>
															Manter conectado
														</Form.Check.Label>
													</Form.Check>
													<Link to='/auth/recoverpw'>Esqueceu a senha?</Link>
												</Col> */}
											</Row>
											<div className='d-flex justify-content-center'>
												<Button type='submit' disabled={!!isLoading} variant='btn btn-primary'>
													{isLoading ? (
														<>
															<span>Entrar </span>
															<Spinner size='sm' />{' '}
														</>
													) : (
														'Entrar'
													)}
												</Button>
											</div>
										</Form>
									</Card.Body>
								</Card>
							</Col>
						</Row>
						<div className='sign-bg'>
							<svg
								width='280'
								height='230'
								viewBox='0 0 431 398'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g opacity='0.05'>
									<rect
										x='-157.085'
										y='193.773'
										width='543'
										height='77.5714'
										rx='38.7857'
										transform='rotate(-45 -157.085 193.773)'
										fill='#3B8AFF'
									/>
									<rect
										x='7.46875'
										y='358.327'
										width='543'
										height='77.5714'
										rx='38.7857'
										transform='rotate(-45 7.46875 358.327)'
										fill='#3B8AFF'
									/>
									<rect
										x='61.9355'
										y='138.545'
										width='310.286'
										height='77.5714'
										rx='38.7857'
										transform='rotate(45 61.9355 138.545)'
										fill='#3B8AFF'
									/>
									<rect
										x='62.3154'
										y='-190.173'
										width='543'
										height='77.5714'
										rx='38.7857'
										transform='rotate(45 62.3154 -190.173)'
										fill='#3B8AFF'
									/>
								</g>
							</svg>
						</div>
					</Col>
					<Col md='6' className='d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden'>
						<Image src={auth2} className='Image-fluid gradient-main animated-scaleX' alt='images' />
					</Col>
				</Row>
			</section>
		</>
	);
};

export default SignIn;
