import React, { useState } from 'react';
import { api } from '../../api/api';
import { roles } from '../../utils/types/rolesTypes';
//hooks
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';

export const ChangeRole = ({ item, show, setShow, refetchUsers }) => {
	const [roleToChange, setRoleToChange] = useState();
	const [userToChange, setUserToChange] = useState();

	const { mutate: changeRole, isLoading: loadingChangeRole } = useMutation({
		mutationFn: async (pessoaID) => {
			const response = await api.patch(`/users/${pessoaID}`, { permission: roleToChange });
			return response?.data;
		},
		onSuccess: () => (refetchUsers(), toast.success(`Alterado cargo`), setShow(false)),
		onError: (error) => {
			toast.error(`Não foi possível mudar o cargo`);
		},
	});

	return (
		<Modal show={show} backdrop='static' className='back-blur' onHide={() => setShow(false)}>
			<Modal.Header closeButton>
				<Modal.Title>Mudar Cargo</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Label htmlFor='select-role'>Selecione o cargo</Form.Label>
				<Form.Select
					id='select-role'
					aria-label='Selecionar cargo'
					onChange={(e) => setRoleToChange(e.target.value)}
				>
					<option>Selecione o cargo</option>
					<option value='D'>Diretor</option>
					<option value='G'>Gerente</option>
					<option value='P'>Gestor de Pessoas</option>
				</Form.Select>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={() => setShow(false)}>
					Cancelar
				</Button>
				{roleToChange ? (
					<Button variant='primary' disabled={loadingChangeRole} onClick={() => changeRole(item)}>
						{loadingChangeRole ? (
							<>
								<span>Mudar Cargo </span>
								<Spinner size='sm' />
							</>
						) : (
							'Mudar Cargo'
						)}
					</Button>
				) : (
					<Button disabled>Mudar Cargo</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
};
