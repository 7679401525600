import React, { useEffect } from 'react';

import { PaginationButton } from './style';
import { LuArrowLeft, LuArrowLeftToLine, LuArrowRight, LuArrowRightToLine } from 'react-icons/lu';

/**
 * a ideia é que sirva tanto para paginação de dados no servidor como para dados locais
 * @param {object} data o array com dados deve estar dentro de uma propriedade `nodes`
 * @returns {JSX.Element} componente de paginação
 */
export const Pagination = ({ data, pagination }) => {
	useEffect(() => {
		pagination.fns.onSetPage(0);
	}, []);

	return (
		<div className='d-flex gap-1'>
			<PaginationButton
				type='button'
				disabled={pagination.state.page === 0}
				onClick={() => pagination.fns.onSetPage(0)}
			>
				<LuArrowLeftToLine />
			</PaginationButton>
			<PaginationButton
				type='button'
				disabled={pagination.state.page === 0}
				onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
			>
				<LuArrowLeft />
			</PaginationButton>

			{Array.from(
				{ length: 2 },
				(_, index) =>
					pagination.state.page - 1 - index >= 0 && (
						<PaginationButton
							type='button'
							onClick={() => pagination.fns.onSetPage(pagination.state.page - 1 - index)}
						>
							{pagination.state.page - index}
						</PaginationButton>
					)
			).reverse()}

			<PaginationButton type='button' bold>
				{pagination.state.page + 1}
			</PaginationButton>

			{Array.from({ length: 3 }, (_, index) =>
				pagination.state.page + 1 + index < pagination.state.getTotalPages(data.nodes) ? (
					<PaginationButton
						type='button'
						onClick={() => pagination.fns.onSetPage(pagination.state.page + 1 + index)}
					>
						{pagination.state.page + 2 + index}
					</PaginationButton>
				) : null
			)}
			<PaginationButton
				type='button'
				disabled={pagination.state.page + 1 >= pagination.state.getTotalPages(data.nodes)}
				onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
			>
				<LuArrowRight />
			</PaginationButton>
			<PaginationButton
				type='button'
				disabled={pagination.state.page + 1 >= pagination.state.getTotalPages(data.nodes)}
				onClick={() => pagination.fns.onSetPage(pagination.state.getTotalPages(data.nodes) - 1)}
			>
				<LuArrowRightToLine />
			</PaginationButton>
		</div>
	);
};
