import styled from 'styled-components';

export const RestartIcon = styled.div`
	transform: rotateY(180deg);
	
	.restart-icon{
		${({ isLoading }) => (isLoading ? 'animation: restart-icon .6s linear infinite both;' : '')}
		${({ isLoading }) => (isLoading ? '-webkit-animation: restart-icon .6s linear infinite both;' : '')}
	}

@-webkit-keyframes restart-icon {
  0% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
@keyframes restart-icon {
  0% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

`;
