import React from 'react';
import { BiSolidHelpCircle } from 'react-icons/bi';
//hooks
import usePhoneNumber from './usePhoneNumber';
import useStars from './useStars';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as dayjs from 'dayjs';

let moneyFormat = new Intl.NumberFormat('pt-BR', {
	style: 'currency',
	currency: 'BRL',
});

const useFormattedValues = () => {
	const { setStars } = useStars();
	const { setPhoneNumber } = usePhoneNumber();

	const setValue = (value, type, emptyValue) => {
		if (!value && type === '') return <NullTooltip emptyValue={emptyValue} />;

		let isFormatted = false;
		const regexDateISO = /^\d{4}-\d{2}-\d{2}$/;
		const regexDateTimeISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/;
		const regexTimingISO = /^\d{2}:\d{2}:\d{2}$/;
		const regexPhoneNumber = /^\d{12}/;
		const regexDateTimeLocal = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/; //DD/MM/YYYY HH:MM:SS

		// console.log(value, regexDateTimeISO.test(value));

		if (
			regexDateISO.test(value) ||
			regexTimingISO.test(value) ||
			regexPhoneNumber.test(value) ||
			regexDateTimeISO.test(value) ||
			regexDateTimeLocal.test(value)
		)
			isFormatted = true;

		if (type === '' || !type) {
			switch (true) {
				case typeof value === 'number':
					return value || 0;

				case typeof value === 'string' && !isFormatted:
					return value || 'Não informado';

				case regexTimingISO.test(value):
					return value || '00:00:00';

				case regexPhoneNumber.test(value):
					return setPhoneNumber(value) || 'Sem telefone';

				case regexDateTimeISO.test(value):
					const valueDateTime = new Date(value);
					return (
						`${valueDateTime.getUTCHours().toString().padStart(2, '0')}:${valueDateTime
							.getMinutes()
							.toString()
							.padStart(2, '0')} ${valueDateTime.getUTCDate().toString().padStart(2, '0')}/${(
							valueDateTime.getUTCMonth() + 1
						)
							.toString()
							.padStart(2, '0')}/${valueDateTime.getUTCFullYear()}` || 'Sem data'
					);

				case regexDateTimeLocal.test(value):
					const valueDateTimeLocal = value.split(' ');
					const dataPartes = valueDateTimeLocal[0].split('/');
					const dataFormatada = dataPartes[1] + '/' + dataPartes[0] + '/' + dataPartes[2];
					const dateAFormatada = new Date(dataFormatada + ' ' + valueDateTimeLocal[1]);

					return dayjs(dateAFormatada).format('YYYY-MM-DDTHH:mm:ssZ');

				case regexDateISO.test(value):
					const valueDate = new Date(value);
					const date = `${valueDate.getDate().toString().padStart(2, '0')}/${(
						valueDate.getMonth() + 1
					)
						.toString()
						.padStart(2, '0')}/${valueDate.getFullYear()}`;
					return value ? date : 'Sem data';

				default:
					return value;
			}
		} else {
			// Detecta o tipo de valor e aplica formatação
			switch (type) {
				case 'rate':
					return value ? (
						setStars(value).map((star) => star)
					) : (
						<NullTooltip emptyValue={emptyValue} />
					);

				case 'money':
					return moneyFormat.format(value || 0);

				case 'date':
					const valueDate = new Date(value);
					const date = `${valueDate.getDate().toString().padStart(2, '0')}/${(
						valueDate.getMonth() + 1
					)
						.toString()
						.padStart(2, '0')}/${valueDate.getFullYear()}`;

					return value ? date : 'Sem data';

				case 'datetime':
					const valueDateTimeLocal = value.split(' ');
					const dataPartes = valueDateTimeLocal[0].split('/');
					const dataFormatada = dataPartes[1] + '/' + dataPartes[0] + '/' + dataPartes[2];
					const dateAFormatada = new Date(dataFormatada + ' ' + valueDateTimeLocal[1]);

					return dayjs(dateAFormatada).format('DD-MM-YYYY, HH:mm');
				case 'timeSeconds':
					return value || '00:00:00';

				default:
					return value;
			}
		}
	};

	return { setValue };
};

const NullTooltip = ({ emptyValue }) => {
	return (
		<OverlayTrigger
			className='info w-max-content'
			placement='bottom'
			overlay={<Tooltip className='custom-tooltip text-left'>{emptyValue || 'Vazio'}</Tooltip>}
		>
			<span className='text-muted'>
				<BiSolidHelpCircle size={20} /> Vazio
			</span>
		</OverlayTrigger>
	);
};

export default useFormattedValues;
