const getVariableColor = () => {
	let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
	if (prefix) {
		prefix = prefix.trim();
	}
	const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}orange`);
	const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}yellow`);
	const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
	const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
	const color5 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-60`);
	return {
		primary: color4.trim(),
		info: color3.trim(),
		orange: color1.trim(),
		yellow: color2.trim(),
		primary_light: color5.trim(),
	};
};
const variableColors = getVariableColor();

const themeSecondary = [
	variableColors.orange,
	variableColors.yellow,
	variableColors.primary,
	variableColors.info,
	,
];
const themePrimary = [
	variableColors.primary,
	variableColors.primary_light,
	variableColors.orange,
	variableColors.yellow,
];

export const themes = {
	primary: themePrimary,
	secondary: themeSecondary,
};
