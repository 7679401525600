import React from 'react';
import ReactPlaceholder from 'react-placeholder';

export const PillPlaceholder = ({ children, isLoading }) => {
	return (
		<ReactPlaceholder
			type='text'
			rows={1}
			ready={!isLoading}
			showLoadingAnimation={true}
			style={{ width: '80px' }}
			color='#E0E0E0'
		>
			{children}
		</ReactPlaceholder>
	);
};
