import React, { useEffect } from 'react';

/**
 * @param {*} props
 * @param {string} className Classe associada a barra
 * @param {string} softcolors Cores	do fundo da barra
 * @param {string} color Cores do preenchimento da barra
 * @param {number} minvalue Valor mínimo possível
 * @param {number} maxvalue Valor maximo possível
 * @param {number} value Porcentagem
 * @param {string} ratio Valor de preenchimento da barra (valor absoluto)
 * @param {string} maxRatio Valor máximo de preenchimento da barra (valor absoluto)
 *
 * @description Utilize `style={height}` para alterar a largura da barra. String em valores que precisam de formatação
 * @returns Barra de progresso
 */

const Progress = (props) => {
	useEffect(() => {
		//progressbar
		const progressBarInit = (elem) => {
			const currentValue = elem.getAttribute('aria-valuenow');
			elem.style.width = '0%';
			elem.style.transition = 'width 1s';
			elem.style.width = currentValue + '%';
		};
		const customProgressBar = document.querySelectorAll('[data-toggle="progress-bar"]');
		Array.from(customProgressBar, (elem) => {
			return progressBarInit(elem);
		});
	});
	return (
		<>
			<div
				className={`progress bg-soft-${props.softcolors} ${props.className}`}
				style={props.style}
			>
				<div
					className={`progress-bar bg-${props.color}`}
					data-toggle='progress-bar'
					role='progressbar'
					aria-valuenow={props.value}
					aria-valuemin={props.minvalue}
					aria-valuemax={props.maxvalue}
				>
					<b>{props.ratio || ''}</b>
				</div>
				<div className='text-center' style={{ width: `${Math.abs(props.value - 100)}%` }}>
					<b>{props.maxRatio || ''}</b>
				</div>
			</div>
		</>
	);
};
export default Progress;
