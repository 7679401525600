export const osStatusColors = {
	finalizado: 'primary',
	paralisado: 'danger',
	desenvolvendo: 'warning',
	backlog: 'info'
};

export const osStatusTypes = {
	finalizado: 'Finalizado',
	paralisado: 'Paralisado',
	desenvolvendo: 'Em andamento',
	backlog: 'Backlog'
};