import React, { useDeferredValue, useEffect, useState } from 'react';
//hooks
import useFormattedValues from '../../hooks/useFormattedValues';
import { HeaderCellSort, useSort } from '@table-library/react-table-library/sort';
import { usePagination } from '@table-library/react-table-library/pagination';
import { useTheme } from '@table-library/react-table-library/theme';
//style
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
//components
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
	Cell,
} from '@table-library/react-table-library/table';
import { PillPlaceholder } from '../Skeletons/PillPlaceholder';
import { TableWrapperTest } from './style';
import { ArrowExpansion } from './arrowExpansion';
import { Skeleton } from './skeleton';
import { Spinner } from 'react-bootstrap';
import { ServerPagination } from './serverPagination';
import { Pagination } from './pagination';
import { MessageHistory } from '../messageHistory/messageHistory';
import * as dayjs from 'dayjs';
/**
 *
 * @param _ Tabela padrão
 * @param {array} columns - The columns of the table.
 * @param {array} dataColumns - O nome das colunas na api. columns e dataColumns devem seguir a mesma ordem
 * @param {array} data - The data for the table.
 * @param {boolean} hasExpansion - Quando um valor é vazio.
 * @param {boolean} hasExpansion - Indicates if the table has expansion.
 * @param {boolean} hasServerPagination - Indicates if the table has server-side pagination.
 * @param {boolean} firstColumnFixed - Indicates if the first column is fixed.
 * @param {boolean} isLoading - Indicates if the table is currently loading.
 * @param {boolean} isFetching - Indicates if the table is currently fetching.
 * @param {boolean} isRefetching - Indicates if the table is currently refetching.
 * @param {string} descriptionColumn - Conteúdo quando expande uma coluna
 * @param {string} actionButton - The action button of the table.
 * @param {function} action - The action function of the table.
 * @param {string} actionType - O tipo de função do botão
 * @param {number} limitTable - The limit of the table.
 */

const valueTypes = {
	rate: 'rate',
	At: 'datetime',
};

export function TableR({
	columns,
	dataColumns,
	data,
	emptyValue,
	hasExpansion,
	hasServerPagination,
	firstColumnFixed,
	isLoading,
	isFetching,
	isRefetching,
	descriptionColumn,
	actionButton,
	action,
	actionType,
	modal,
	modalType,
	limitTable,
}) {
	const { setValue } = useFormattedValues();

	const [showModal, setShowModal] = useState(false);
	const [modalId, setModalId] = useState(0);
	const modalConfig = {
		default: { show: showModal },
		byId: { item: modalId, show: showModal, setShow: setShowModal },
	};
	const [idsShowDesc, setIdsShowDesc] = useState([]);

	const [dataTable, setDataTable] = useState({ nodes: data || [] });
	const deferredDataTable = useDeferredValue(dataTable);
	const columnsQuantity = columns.length + (actionButton ? 1 : 0);

	const getSortableColumns = () => {
		if (!data) return {};
		const sortColumns = {};

		dataColumns.map((column) => {
			sortColumns[column] = (array) =>
				array.sort((a, b) => {
					if (typeof a[column] === 'number') return a[column] - b[column];

					if (dayjs(setValue(a[column])).isValid())
						return new Date(setValue(a[column])) - new Date(setValue(b[column]));

					if (typeof a[column] === 'string' && !dayjs(setValue(a[column])).isValid()) {
						return a[column].localeCompare(b[column]);
					}
				});
		});
		return sortColumns;
	};

	const includeType = (column) => {
		if (column.toString().toLowerCase().includes('rate')) {
			return 'rate';
		}
		if (column.toString().includes('createdAt')) {
			return 'datetime';
		}
	};

	const handleShowModal = (item) => {
		if (modal) {
			item ? setModalId(item) : console.log('ID vazio');
			setShowModal(true);
		}
	};
	const handleSetIdsChart = (row) => {
		if (hasExpansion) {
			if (idsShowDesc.includes(row)) {
				setIdsShowDesc(idsShowDesc.filter((id) => id !== row));
			} else {
				setIdsShowDesc(idsShowDesc.concat(row));
			}
		}
	};

	const pagination = usePagination(deferredDataTable, {
		state: {
			page: 0,
			size: limitTable ?? 5,
		},
		// onChange: onPaginationChange,
	});

	const sort = useSort(
		deferredDataTable,
		{
			state: {
				sortKey: dataColumns[0],
				reverse: true,
			},
		},
		{
			sortIcon: {
				iconDefault: null,
				iconUp: <FaCaretUp />,
				iconDown: <FaCaretDown />,
			},
			sortFns: getSortableColumns(),
		}
	);

	useEffect(() => {
		setDataTable({
			nodes: data || [],
		});
	}, [data]);

	return (
		<>
			<section className='w-100'>
				<div className='position-relative'>
					{Array.isArray(deferredDataTable?.nodes) && (
						<>
							<TableWrapperTest firstColumnFixed={firstColumnFixed} columns={columnsQuantity}>
								<Table
									id='table-dev-os'
									responsive
									data={deferredDataTable}
									pagination={pagination}
									sort={sort}
									// theme={theme}
									className='table'
									role='grid'
									data-toggle='data-table'
								>
									{(tableList) => (
										<>
											<Header>
												<HeaderRow>
													{actionButton && <HeaderCell></HeaderCell>}
													{columns &&
														columns.map((column, index) => {
															if (dataColumns[index] !== descriptionColumn) {
																return (
																	<HeaderCellSort
																		pinLeft={firstColumnFixed && index === 0}
																		sortKey={dataColumns[index]}
																	>
																		{column}
																	</HeaderCellSort>
																);
															}
														})}
													{hasExpansion && <HeaderCell></HeaderCell>}
												</HeaderRow>
											</Header>
											<Body>
												{tableList.map((os, index) => (
													<>
														<Row
															className='pointer'
															item={os}
															onClick={() => handleSetIdsChart(index)}
															key={index}
														>
															{actionButton && (
																<Cell>
																	{(os.isActive || os.ativo) && (
																		<ActionButton type={actionType} os={os} action={action}>
																			{actionButton}
																		</ActionButton>
																	)}
																</Cell>
															)}
															{dataColumns &&
																dataColumns.map((column, index) => {
																	// console.log(os);
																	if (dataColumns[index] !== descriptionColumn) {
																		return (
																			<Cell
																				className='text-start'
																				pinLeft={firstColumnFixed && index === 0}
																				onClick={() => {
																					handleShowModal(os);
																				}}
																			>
																				{setValue(os[column], includeType(column), emptyValue)}
																			</Cell>
																		);
																	}
																})}

															{hasExpansion && (
																<Cell>
																	<ArrowExpansion
																		data-testid='arrow'
																		active={idsShowDesc.includes(os.id)}
																	/>
																</Cell>
															)}
														</Row>
														{hasExpansion && idsShowDesc.includes(index) && (
															<tr className='d-flex' pinLeft style={{ gridColumn: '1/-1' }}>
																<Cell
																	pinLeft
																	style={{
																		borderLeft: `4px solid var(--bs-primary)`,
																		borderRadius: '0 0 0 5px',
																	}}
																>
																	{setValue(os[descriptionColumn])}
																</Cell>
															</tr>
														)}
													</>
												))}
											</Body>
										</>
									)}
								</Table>
							</TableWrapperTest>

							<Skeleton
								fieldQuantity={columns?.length}
								isActive={!!(isLoading && deferredDataTable?.nodes?.length === 0)}
							/>

							{isRefetching && deferredDataTable?.nodes?.length > 0 && (
								<div
									className='h-100 w-100 position-absolute top-0'
									style={{ backgroundColor: '#1c1f3126' }}
								>
									<Spinner variant='primary' className='position-absolute top-50 start-50' />
								</div>
							)}
							{deferredDataTable?.nodes.length === 0 && !isLoading && (
								<div className='mb-4 mt-4 text-center' data-testid='no-data-table-unit'>
									Não há dados
								</div>
							)}
						</>
					)}
				</div>
				<div className='d-flex flex-wrap gap-2'>
					{Array.isArray(deferredDataTable?.nodes) &&
						!isLoading &&
						(hasServerPagination ? (
							// <ServerPagination
							// data={deferredDataTable || { nodes: [] }}
							// pagination={pagination}
							// totalData={osList[0]?.total || 0}
							// actualPage={actualPage}
							// setPagination={setPagination}/>
							<Spinner />
						) : (
							<Pagination data={deferredDataTable} pagination={pagination} />
						))}
					<b className='d-flex align-items-center gap-1'>
						Total:{' '}
						<PillPlaceholder isLoading={false}>
							{data && data[0]?.total ? data[0]?.total : data?.length}
						</PillPlaceholder>
					</b>
				</div>
			</section>

			{modal && React.cloneElement(modal, modalConfig[modalType])}
		</>
	);
}
const ActionButton = ({ children, type, os, action }) => {
	switch (type) {
		case 'actionWithId':
			return (
				<div
					style={{
						opacity: os?.isActive || os?.ativo ? 1 : 0.5,
					}}
					onClick={() => (os?.isActive || os?.ativo) && action(os?.id)}
				>
					{children}
				</div>
			);
		case 'default':
			<div
				style={{
					opacity: os?.isActive || os?.ativo ? 1 : 0.5,
				}}
				onClick={() => (os?.isActive || os?.ativo) && action()}
			>
				{children}
			</div>;
		default:
			return children;
	}
};
