import React, { useEffect, useState } from 'react';
import { Col, Row, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Card from '../../../components/card/Card';
import CustomToggle from '../../../components/dropdowns';
import { ReactSortable } from 'react-sortablejs';
import { useQuery } from 'react-query';
import { api } from '../../../api/api';
import { FlowCard } from '../../../components/flowCard/flowCard';

import { LuEyeOff } from 'react-icons/lu';
import { LuEye } from 'react-icons/lu';
import { FlowColumn } from './style';

const Flow = () => {
	const [minimizer, setMinimizer] = useState([]);
	//queries
	const { data: flow } = useQuery(['flow'], {
		queryFn: () => getFlow,
		onError: (err) => console.log(err),
	});

	const [others, setOthers] = useState([]);
	const [client, setClient] = useState([]);
	const [support, setSupport] = useState([]);
	const [analist, setAnalist] = useState([]);
	const [development, setDevelopment] = useState([]);

	const getFlow = async () => {
		try {
			const response = await api.get(`/services/quantities/status`);

			setOthers(response?.data.outros);
			setClient(response?.data.cliente);
			setSupport(response?.data.suporte);
			setAnalist(response?.data.analista);
			setDevelopment(response?.data.desenvolvimento);
			return response?.data;
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<Row>
				<FlowColumn color='#6f42c133' className='col'>
					<div className='card-transparent mb-0 desk-info'>
						<div className='card-body p-0'>
							<Row className=''>
								<Col lg='12'>
									<div className='card text-white' style={{ backgroundColor: '#6f42c1' }}>
										<Card.Body>
											<div className='d-flex align-items-center justify-content-between text-white'>
												<span className='mb-0'>Cliente</span>
												<ShowOff group='group1' minimizer={minimizer} setMinimizer={setMinimizer} />
											</div>
										</Card.Body>
									</div>
								</Col>

								<FlowCard
									group='group1'
									list={client}
									isMinimized={minimizer.includes('group1')}
									setList={setClient}
									key='client'
								/>
							</Row>
						</div>
					</div>
				</FlowColumn>

				<FlowColumn color='#08b1ba33' className='col'>
					<div className='card-transparent mb-0 desk-info'>
						<div className='card-body p-0'>
							<Row>
								<Col lg='12'>
									<Card className='bg-info'>
										<Card.Body>
											<div className='d-flex align-items-center justify-content-between text-white'>
												<span className='mb-0'>Suporte</span>
												<ShowOff group='group2' minimizer={minimizer} setMinimizer={setMinimizer} />
											</div>
										</Card.Body>
									</Card>
								</Col>

								<FlowCard
									group='group2'
									list={support}
									isMinimized={minimizer.includes('group2')}
									setList={setSupport}
									key='support'
								/>
							</Row>
						</div>
					</div>
				</FlowColumn>

				<FlowColumn color='#f16a1b33' className='col'>
					<div className='card-transparent mb-0 desk-info'>
						<div className='card-body p-0'>
							<Row className=''>
								<Col lg='12'>
									<Card className='bg-warning'>
										<Card.Body>
											<div className='d-flex align-items-center justify-content-between text-white'>
												<span className='mb-0'>Analista</span>
												<ShowOff group='group3' minimizer={minimizer} setMinimizer={setMinimizer} />
											</div>
										</Card.Body>
									</Card>
								</Col>

								<FlowCard
									group='group3'
									list={analist}
									isMinimized={minimizer.includes('group3')}
									setList={setAnalist}
									key='analist'
								/>
							</Row>
						</div>
					</div>
				</FlowColumn>

				<FlowColumn color='#7eb02c33' className='col'>
					<div className='card-transparent mb-0 desk-info'>
						<div className='card-body p-0'>
							<Row>
								<Col lg='12'>
									<Card className='bg-primary'>
										<Card.Body>
											<div className='d-flex align-items-center justify-content-between text-white'>
												<span className=' mb-0'>Desenvolvimento</span>

												<ShowOff group='group4' minimizer={minimizer} setMinimizer={setMinimizer} />
											</div>
										</Card.Body>
									</Card>
								</Col>

								<FlowCard
									group='group4'
									isMinimized={minimizer.includes('group4')}
									list={development}
									setList={setDevelopment}
									key='development'
								/>
							</Row>
						</div>
					</div>
				</FlowColumn>

				<FlowColumn color='#ffffff60' className='col'>
					<div className='card-transparent mb-0 desk-info'>
						<div className='card-body p-0'>
							<Row className=''>
								<Col lg='12'>
									<Card>
										<Card.Body>
											<div className='d-flex align-items-center justify-content-between'>
												<h6 className='mb-0'>Outros</h6>
												<ShowOff group='group5' minimizer={minimizer} setMinimizer={setMinimizer} />
											</div>
										</Card.Body>
									</Card>
								</Col>

								<FlowCard
									group='group0'
									list={others}
									isMinimized={minimizer.includes('group5')}
									setList={setClient}
									key='others'
								/>
							</Row>
						</div>
					</div>
				</FlowColumn>
			</Row>
		</>
	);
};

const ShowOff = ({ minimizer, setMinimizer, group }) => {
	return (
		<div
			style={{ cursor: 'pointer' }}
			onClick={() =>
				minimizer.includes(group)
					? setMinimizer(minimizer.filter((item) => item !== group))
					: setMinimizer(minimizer.concat(group))
			}
		>
			{minimizer.includes('group4') ? <LuEyeOff /> : <LuEye />}
		</div>
	);
};
export default Flow;
