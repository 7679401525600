import React from 'react';
import { Arrow } from './style';
import { FaChevronDown } from 'react-icons/fa';

/**
 * Rotaciona a flecha da caso a coluna seja aberta
 * @param _
 * @param {boolean} active - Indica a fase da animação dela.
 */
export const ArrowExpansion = ({ active }) => {
	return (
		<Arrow data-testid='arrow' active={active}>
			<FaChevronDown />
		</Arrow>
	);
};
