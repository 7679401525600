import React, { useEffect, useState } from 'react';
import { api } from '../../api/api';
//hooks
import { useQuery } from 'react-query';
import { useSessionStorage } from '@uidotdev/usehooks';
//icons
import { AiOutlineCheck } from 'react-icons/ai';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { TiStarHalfOutline } from 'react-icons/ti';
import { IoWarningOutline } from 'react-icons/io5';
//components
import Chart from 'react-apexcharts';
import { Col, Table as BTable, Dropdown, Modal, Button, Spinner, Card, Row } from 'react-bootstrap';
import ReactPlaceholder from 'react-placeholder';
import CustomTooltip from '../customTooltip/CustomTooltip';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
SwiperCore.use([Navigation]);
import 'swiper/swiper-bundle.min.css';
import PiechartCard from '../piechartCard/piechartCard';
import columnLineData from '../../utils/charts/columnLineData';

const ClientDetails = ({ show, client, setShowClientDetails, totalYears }) => {
	const [isLoading, setLoading] = useSessionStorage('isLoading', false);

	const [monthFilter, setMonthFilter] = useState(0);
	const [yearFilter, setYearFilter] = useState(0);

	const [clientId, setClientId] = useState(0);
	const [osList, setOsList] = useState([]);
	const [servicesByYear, setServicesByYear] = useState();
	const [categoryDaysAllXFinished, setCategoryDaysAllXFinished] = useState([
		'Jan',
		'Fev',
		'Mar',
		'Abr',
		'Mai',
		'Jun',
		'Jul',
		'Ago',
		'Set',
		'Out',
		'Nov',
		'Dez',
	]);

	//queries
	const {
		data: clientDetails,
		isLoading: isLoadingClientDetails,
		isRefetching: isRefetchingClientDetails,
	} = useQuery(['devsDetail', clientId], () => getUser(clientId), {
		onError: (err) => console.log(err),
		onSettled: () => setLoading(false),
	});

	const getUser = async (id) => {
		setLoading(true);
		try {
			if (id === 0 || id === undefined) return;

			const response = await api.get(`/services/quantities/client/${id}`);
			setLoading(false);

			return response?.data;
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const applyFilterInServices = (date) => {
		if (!clientDetails) return;

		const services = clientDetails?.servicesQuantityByYear;

		let servicesTemplate = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];

		var servicesFiltered = services.map((data) => {
			if (new Date(data.date).getUTCFullYear() === new Date(date).getFullYear()) {
				return data;
			}
		});

		for (const key in servicesFiltered) {
			const month = servicesFiltered[key];
			servicesTemplate[new Date(month?.date).getUTCMonth()] = month?.quantity.toString();
		}

		setServicesByYear(servicesTemplate);
		return servicesTemplate;
	};

	const handleMonthFilter = (month) => {
		setMonthFilter(month);
	};

	useEffect(() => {
		applyFilterInServices(new Date());
	}, [clientDetails]);
	useEffect(() => {
		setClientId(client?.id);
	}, [client]);
	useEffect(() => {
		setMonthFilter(0);
		setYearFilter(0);
		setOsList([]);
	}, [clientId]);
	// useEffect(() => {
	//   var filtered = clientDetails?.filter((item) => {
	//     const date = new Date(item?.data_atendimento);
	//     if (
	//       (monthFilter === 0 ? 1 === 1 : date.getMonth() + 1 === monthFilter) &&
	//       (yearFilter === 0 ? 1 === 1 : date.getFullYear() === yearFilter)
	//     ) {
	//       return item;
	//     }
	//   });

	//   setOsList(filtered);
	// }, [monthFilter, yearFilter, clientDetails]);

	const handleGetServicesByYear = (data) => {
		var total = 0;

		if (clientDetails) {
			data.map((services) => {
				if (new Date(services.date).getUTCFullYear() === new Date().getFullYear()) {
					total += Number(services.quantity);
				}
			});
		}

		return total;
	};

	return (
		<Modal show={show} fullscreen onHide={() => setShowClientDetails(false)}>
			<Modal.Header closeButton>
				<Modal.Title>
					<ReactPlaceholder
						type='text'
						rows={1}
						ready={!isRefetchingClientDetails}
						showLoadingAnimation={true}
						color='#E0E0E0'
					>
						{client?.client}
					</ReactPlaceholder>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ backgroundColor: 'var(--bs-body-bg)' }}>
				<Col>
					{isRefetchingClientDetails ? (
						<div
							className='d-flex justify-content-center align-items-center'
							style={{ height: '300px' }}
						>
							<Spinner />
						</div>
					) : (
						<>
							<Swiper
								className='p-0 m-0 mb-2 list-inline '
								slidesPerView={5}
								spaceBetween={32}
								navigation={{
									nextEl: '.swiper-button-next',
									prevEl: '.swiper-button-prev',
								}}
								breakpoints={{
									320: { slidesPerView: 1 },
									550: { slidesPerView: 2 },
									991: { slidesPerView: 3 },
									1400: { slidesPerView: 3 },
									1500: { slidesPerView: 4 },
									1920: { slidesPerView: 5 },
								}}
							>
								<SwiperSlide className='card-slide card'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='bg-info text-white rounded p-3'>
												<svg
													width='22px'
													viewBox='0 0 24 24'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														fillRule='evenodd'
														clipRule='evenodd'
														d='M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z'
														stroke='currentColor'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Atendimentos no ano</p>
													<div>
														<CustomTooltip
															text={
																<div style={{ textAlign: 'left' }}>
																	<p>Total de atendimentos no ano</p>
																</div>
															}
														/>
													</div>
												</div>
												<h4 className='counter'>
													<ReactPlaceholder
														type='text'
														rows={1}
														ready={!isLoading}
														showLoadingAnimation={true}
														color='#E0E0E0'
													>
														{handleGetServicesByYear(clientDetails?.servicesQuantityByYear) ?? 0}
													</ReactPlaceholder>
												</h4>
											</div>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide className='card-slide card'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='bg-warning text-white rounded p-3'>
												<IoWarningOutline size={22} />
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>OS's pendentes</p>
													<div>
														<CustomTooltip
															text={
																<div style={{ textAlign: 'left' }}>
																	<p>OS's ainda pendentes de finalizar</p>
																</div>
															}
														/>
													</div>
												</div>
												<h4 className='counter'>
													<ReactPlaceholder
														type='text'
														rows={1}
														ready={!isLoading}
														showLoadingAnimation={true}
														color='#E0E0E0'
													>
														{handleGetServicesByYear(clientDetails?.unfinishedServicesByYear) ?? 0}
													</ReactPlaceholder>
												</h4>
											</div>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide className='card-slide card'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='bg-secondary text-white rounded p-3'>
												<FaRegCalendarAlt size={22} />
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>OS's 30 dias</p>
													<div>
														<CustomTooltip
															text={
																<div style={{ textAlign: 'left' }}>
																	<p>Número de OS's nos últimos 30 dias</p>
																</div>
															}
														/>
													</div>
												</div>
												<h4 className='counter'>
													<ReactPlaceholder
														type='text'
														rows={1}
														ready={!isLoading}
														showLoadingAnimation={true}
														color='#E0E0E0'
													>
														{handleGetServicesByYear(clientDetails?.last30DaysServicesQuantity) ??
															0}
													</ReactPlaceholder>
												</h4>
											</div>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide className='card-slide card'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='bg-warning text-white rounded p-3'>
												<TiStarHalfOutline size={22} />
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Avaliações</p>
													<div>
														<CustomTooltip
															text={
																<div style={{ textAlign: 'left' }}>
																	<p>Porcentagem de atendimentos avaliados</p>
																</div>
															}
														/>
													</div>
												</div>
												<h4 className='counter'>
													<ReactPlaceholder
														type='text'
														rows={1}
														ready={!isLoading}
														showLoadingAnimation={true}
														color='#E0E0E0'
													>
														{handleGetServicesByYear(clientDetails?.evaluationRatioFixed) ?? 0}%
													</ReactPlaceholder>
												</h4>
											</div>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide className='card-slide card'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='bg-warning text-white rounded p-3'>
												<svg
													width='22'
													viewBox='0 0 24 24'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														fillRule='evenodd'
														clipRule='evenodd'
														d='M21.25 12.0005C21.25 17.1095 17.109 21.2505 12 21.2505C6.891 21.2505 2.75 17.1095 2.75 12.0005C2.75 6.89149 6.891 2.75049 12 2.75049C17.109 2.75049 21.25 6.89149 21.25 12.0005Z'
														stroke='currentColor'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path
														d='M15.4316 14.9429L11.6616 12.6939V7.84692'
														stroke='currentColor'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Duração média</p>
													<div>
														<CustomTooltip
															text={
																<div style={{ textAlign: 'left' }}>
																	<p>
																		A duração média dos atendimentos é calculado com: Tempo total /
																		qtd atendimento
																	</p>
																</div>
															}
														/>
													</div>
												</div>
												<h4 className='counter'>
													<ReactPlaceholder
														type='text'
														rows={1}
														ready={!isLoading}
														showLoadingAnimation={true}
														color='#E0E0E0'
													>
														{handleGetServicesByYear(clientDetails?.averageServiceTimeFixed) ?? 0}
													</ReactPlaceholder>
												</h4>
											</div>
										</div>
									</div>
								</SwiperSlide>
							</Swiper>

							<Row>
								<Col className='col-8'>
									<Card>
										<Card.Header>
											<div className='header-title d-flex align-items-center'>
												<h5 className='mb-0'>Atendimentos por cliente/ano</h5>
												<CustomTooltip
													text={
														<div style={{ textAlign: 'left' }}>
															<p>Atendimentos por cliente no ano</p>
															<p>* Considera apenas o ano atual</p>
														</div>
													}
												/>
											</div>
										</Card.Header>
										<Card.Body>
											<ReactPlaceholder
												type='rect'
												style={{ height: '300px' }}
												ready={!isLoading}
												showLoadingAnimation={true}
												color='#E0E0E0'
											>
												<Chart
													options={
														columnLineData(
															[
																{
																	name: '2023',
																	type: 'line',
																	data: servicesByYear,
																},
															],
															categoryDaysAllXFinished,
															12
														).options
													}
													series={
														columnLineData(
															[
																{
																	name: '2023',
																	type: 'line',
																	data: servicesByYear,
																},
															],
															categoryDaysAllXFinished,
															12
														).series
													}
													type='area'
													height='250'
												/>
											</ReactPlaceholder>
										</Card.Body>
									</Card>
								</Col>

								<Col className='col-4'>
									<Card>
										<PiechartCard
											title={`OS's por produto`}
											tooltip={`OS's por produto`}
											data={clientDetails?.servicesByProduct}
											labelAttribute='produto'
											quantityAttribute='quantity'
										/>
									</Card>
								</Col>
							</Row>
						</>
					)}
				</Col>
			</Modal.Body>
		</Modal>
	);
};

export default ClientDetails;
