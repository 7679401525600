import styled from 'styled-components';

export const GlobalStyles = styled.div`
	
	.back-blur{
		background-color:#0000004d;
		backdrop-filter: blur(5px);
	}
	.dropdown-menu {
		max-height: 300px;
		
		overflow: hidden;
		overflow-y: auto;
	}
`;
