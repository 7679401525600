import React, { useEffect, useState } from 'react';
//hooks
import useFormattedValues from '../../../hooks/useFormattedValues';
//components
import Chart from 'react-apexcharts';
SwiperCore.use([Navigation]);
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { Card, Col } from 'react-bootstrap';
import ReactPlaceholder from 'react-placeholder';
import CardWithGrowth from '../../../components/cardWithGrowth/CardWithGrowth';
import { DualChartsSection } from '../style';
import columnLineData from '../../../utils/charts/columnLineData';
import stockXAccumulated from '../../../utils/charts/stockXAccumulatedData';
import { columnNegativeData } from '../../../utils/charts/columnNegativeData';
import { useQuery } from 'react-query';
import { api } from '../../../api/api';
import { PreviewXRealMonth } from '../../../components/ExpectedXRealMonth/previewXRealMonth';

function Financial() {
	const { setValue } = useFormattedValues();
	const [yearBalance, setYearBalance] = useState();
	const [expectedAndRealizedTop, setExpectedAndRealizedTop] = useState();
	const [EnRTopLoading, setEnRTopLoading] = useState(false); // EnR stands for Expected And Realized
	const [categoriesData, setCategoriesData] = useState();
	const [categoriesLoading, setCategoriesLoading] = useState(false);
	const [monthlyBalance, setMonthlyBalance] = useState();
	const [monthlyBalanceLoading, setMonthlyBalanceLoading] = useState(false);

	const getGeneralBalance = async () => {
		try {
			const response = await api.get('/custos/balance/year');
			setYearBalance(response?.data);
			return response?.data;
		} catch (e) {
			console.error(e);
		}
	};

	const getExpectedAndRealized = async (setLoading, setData, filter) => {
		setLoading(true);
		try {
			const response = await api.get(`/custos/expectedXrealized?category=${filter ?? ''}`);
			setData(response?.data);
			setLoading(false);
			return response?.data;
		} catch (e) {
			setLoading(false);
			console.error(e);
		}
	};

	const getExpectedAndRealizedByCategory = async () => {
		setCategoriesLoading(true);
		try {
			const response = await api.get('custos/expectedXrealized/category');
			setCategoriesData(response?.data);
			setCategoriesLoading(false);
			return response?.data;
		} catch (e) {
			setCategoriesLoading(false);
			console.error(e);
		}
	};

	const getMonthlyBalance = async () => {
		setMonthlyBalanceLoading(true);
		try {
			const response = await api.get('custos/balance/monthly');
			setMonthlyBalance(response?.data);
			setMonthlyBalanceLoading(false);
			return response?.data;
		} catch (e) {
			setCategoriesLoading(false);
			console.error(e);
		}
	};

	const {
		data,
		refetch: yearBalanceRefetch,
		isLoading: yearBalanceLoading,
	} = useQuery(['yearBalance'], getGeneralBalance, {
		onError: (err) => console.log(err),
	});
	const { refetch: EnRTopRefetch } = useQuery(
		['expectedXrealizedTop'],
		() => getExpectedAndRealized(setEnRTopLoading, setExpectedAndRealizedTop),
		{ onError: console.log, onSettled: () => setEnRTopLoading(false) }
	);
	const { refetch: categoriesRefetch } = useQuery(
		['categories'],
		getExpectedAndRealizedByCategory,
		{ onError: console.log, onSettled: () => setCategoriesLoading(false) }
	);
	const { refresh: monthlyBalanceRefetch } = useQuery(['monthlyBalance'], getMonthlyBalance, {
		onError: console.log,
		onSettled: () => setMonthlyBalanceLoading(false),
	});

	console.log(yearBalanceLoading);

	const treatNumbers = (n) => {
		return Number(n?.toFixed(2) ?? 0);
	};

	const sum = (arr) => {
		return arr?.reduce((acc, curr) => acc + curr, 0);
	};

	return (
		<>
			<section data-aos='fade-up' data-aos-delay='500'>
				<Swiper
					className='p-0 m-0 mb-2 list-inline '
					slidesPerView={5}
					spaceBetween={32}
					navigation={{
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					}}
					breakpoints={{
						320: { slidesPerView: 1 },
						550: { slidesPerView: 2 },
						991: { slidesPerView: 3 },
						1400: { slidesPerView: 3 },
						1500: { slidesPerView: 4 },
						1920: { slidesPerView: 5 },
					}}
				>
					<SwiperSlide>
						<CardWithGrowth
							title='Total previsto'
							tooltip={'Total de recursos previstos a serem gastos'}
							colorIcon={'info'}
							icon={<MdOutlineAttachMoney size={22} />}
						>
							<CardWithGrowth.Body isLoading={yearBalanceLoading}>
								{setValue(yearBalance?.totalPrevisto, 'money')}
							</CardWithGrowth.Body>
						</CardWithGrowth>
					</SwiperSlide>
					<SwiperSlide>
						<CardWithGrowth
							title='Total realizado'
							tooltip={'Total de recursos despendidos'}
							colorIcon={'warning'}
							icon={<MdOutlineAttachMoney size={22} />}
						>
							<CardWithGrowth.Body isLoading={yearBalanceLoading}>
								{setValue(yearBalance?.totalRealizado, 'money')}
							</CardWithGrowth.Body>
						</CardWithGrowth>
					</SwiperSlide>
					<SwiperSlide>
						<CardWithGrowth
							title='Saldo atual'
							tooltip={'Saldo atual'}
							colorIcon={'success'}
							icon={<MdOutlineAttachMoney size={22} />}
						>
							<CardWithGrowth.Body isLoading={yearBalanceLoading}>
								{setValue(yearBalance?.saldoAtual, 'money')}
							</CardWithGrowth.Body>
						</CardWithGrowth>
					</SwiperSlide>
				</Swiper>
			</section>

			<section data-aos='fade-up' data-aos-delay='700'>
				<DualChartsSection>
					<Col>
						<Card>
							<Card.Header>
								<h5 className='card-title'>Previsto X Realizado mensal</h5>
							</Card.Header>

							<Card.Body>
								<ReactPlaceholder
									type='rect'
									style={{ height: '300px' }}
									ready={!EnRTopLoading}
									showLoadingAnimation={true}
									color='#E0E0E0'
								>
									<Chart
										options={
											columnLineData(
												[
													{
														name: 'Previsto',
														type: 'line',
														data: expectedAndRealizedTop?.previsto?.map(treatNumbers) ?? [],
													},
													{
														name: 'Realizado',
														type: 'line',
														data: expectedAndRealizedTop?.realizado?.map(treatNumbers) ?? [],
													},
												],
												[
													'Jan',
													'Fev',
													'Mar',
													'Abr',
													'Mai',
													'Jun',
													'Jul',
													'Ago',
													'Set',
													'Out',
													'Nov',
													'Dez',
												],
												12,
												'primary',
												4,
												'money'
											).options
										}
										series={
											columnLineData(
												[
													{
														name: 'Previsto',
														type: 'line',
														data: expectedAndRealizedTop?.previsto?.map(treatNumbers) ?? [],
													},
													{
														name: 'Realizado',
														type: 'line',
														data: expectedAndRealizedTop?.realizado?.map(treatNumbers) ?? [],
													},
												],
												[
													'Jan',
													'Fev',
													'Mar',
													'Abr',
													'Mai',
													'Jun',
													'Jul',
													'Ago',
													'Set',
													'Out',
													'Nov',
													'Dez',
												],
												12,
												'primary',
												4,
												'money'
											).series
										}
										type='area'
										height='250'
									/>
								</ReactPlaceholder>
							</Card.Body>
						</Card>
					</Col>

					<Col>
						<Card>
							<Card.Header>
								<h5 className='card-title'>Previsto X Realizado (por categoria)</h5>
							</Card.Header>

							<Card.Body>
								<ReactPlaceholder
									type='rect'
									style={{ height: '300px' }}
									ready={!categoriesLoading}
									showLoadingAnimation={true}
									color='#E0E0E0'
								>
									<Chart
										options={
											stockXAccumulated(
												[
													{
														name: 'Previsto',
														data: [
															treatNumbers(sum(categoriesData?.folha?.previsto)),
															treatNumbers(sum(categoriesData?.viagens?.previsto)),
															treatNumbers(sum(categoriesData?.outrosGastos?.previsto)),
														],
													},
													{
														name: 'Realizado',
														data: [
															treatNumbers(sum(categoriesData?.folha?.realizado)),
															treatNumbers(sum(categoriesData?.viagens?.realizado)),
															treatNumbers(sum(categoriesData?.outrosGastos?.realizado)),
														],
													},
												],
												['Folha', 'Viagens', 'Outros gastos'],
												'primary',
												'money'
											).options
										}
										series={
											stockXAccumulated(
												[
													{
														name: 'Previsto',
														data: [
															treatNumbers(sum(categoriesData?.folha?.previsto)),
															treatNumbers(sum(categoriesData?.viagens?.previsto)),
															treatNumbers(sum(categoriesData?.outrosGastos?.previsto)),
														],
													},
													{
														name: 'Realizado',
														data: [
															treatNumbers(sum(categoriesData?.folha?.realizado)),
															treatNumbers(sum(categoriesData?.viagens?.realizado)),
															treatNumbers(sum(categoriesData?.outrosGastos?.realizado)),
														],
													},
												],
												['Folha', 'Viagens', 'Outros gastos'],
												'primary',
												'money'
											).series
										}
										type='bar'
										height='250'
									/>
								</ReactPlaceholder>
							</Card.Body>
						</Card>
					</Col>
				</DualChartsSection>
			</section>

			<section data-aos='fade-up' data-aos-delay='500'>
				<DualChartsSection>
					<Col>
						<PreviewXRealMonth />
					</Col>
					<Col>
						<Card>
							<Card.Header>
								<h5 className='card-title'>Déficit / Superávit mensal</h5>
							</Card.Header>
							<Card.Body>
								<div className='d-flex justify-content-center gap-4 user-select-none'>
									<span className='d-flex align-items-center gap-2 cursor-default'>
										<div
											className='rounded-circle'
											style={{ width: '10px', height: '10px', backgroundColor: '#FC5A5A' }}
										></div>
										Déficit
									</span>
									<span className='d-flex align-items-center gap-2'>
										<div
											className='rounded-circle bg-primary'
											style={{ width: '10px', height: '10px' }}
										></div>
										Superávit
									</span>
								</div>
								<ReactPlaceholder
									type='rect'
									style={{ height: '300px' }}
									ready={!monthlyBalanceLoading}
									showLoadingAnimation={true}
									color='#E0E0E0'
								>
									<Chart
										options={columnNegativeData(monthlyBalance?.valoresMes ?? [0], 'money').options}
										series={columnNegativeData(monthlyBalance?.valoresMes ?? [0], 'money').series}
										type='bar'
										height='250'
									/>
								</ReactPlaceholder>
							</Card.Body>
						</Card>
					</Col>
				</DualChartsSection>
			</section>
		</>
	);
}

export default Financial;
