import React, { useEffect, useState } from 'react';
import { api } from '../../../api/api';

import { useQuery } from 'react-query';

import { useSessionStorage } from '@uidotdev/usehooks';

import { Row, Col, Dropdown, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// AOS
import AOS from 'aos';
import 'aos';
import '../../../../node_modules/aos/dist/aos.css';
//apexcharts
import Chart from 'react-apexcharts';

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
// import 'swiper/components/navigation/navigation.scss';

//Count-up
import CountUp from 'react-countup';

// Redux Selector / Action
import { useSelector } from 'react-redux';

// Import selectors & action from setting store
import * as SettingSelector from '../../../store/setting/selectors';

// install Swiper modules
SwiperCore.use([Navigation]);

//components
import CustomTooltip from '../../../components/customTooltip/CustomTooltip';

const Productivity = () => {
	const [isIoading, setLoading] = useSessionStorage('isLoading', false);
	const [dataDashboard, setDataDashboard] = useSessionStorage('dashboardData', null);
	useSelector(SettingSelector.theme_color);
	var monthsAllByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
	var monthsFinishedByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
	var monthsStockByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

	//Pega os dados e armazena no sessionstorage
	const { data: getDataDash } = useQuery(
		['dataDash'],
		async () => {
			setLoading(true);
			const response = await api('/indices/home');

			setDataDashboard(response?.data);
		},
		{
			onError: (err) => console.log(err),
			onSettled: () => setLoading(false),
		}
	);

	const getVariableColor = () => {
		let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
		if (prefix) {
			prefix = prefix.trim();
		}
		const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
		const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
		const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
		const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
		return {
			primary: color1.trim(),
			info: color2.trim(),
			warning: color4.trim(),
			primary_light: color3.trim(),
		};
	};
	const variableColors = getVariableColor();

	const colors = [variableColors.primary, variableColors.info];
	useEffect(() => {
		return () => colors;
	});

	useEffect(() => {
		AOS.init({
			startEvent: 'DOMContentLoaded',
			disable: function () {
				var maxWidth = 996;
				return window.innerWidth < maxWidth;
			},
			throttleDelay: 10,
			once: true,
			duration: 700,
			offset: 10,
		});
	});

	const sortedEmployeesByRole = dataDashboard?.employeesByRole.sort(
		(a, b) => b.quantity - a.quantity
	);

	// Pegar os três primeiros setores
	const topThreeRoles = sortedEmployeesByRole.slice(0, 3);

	// Calcular a quantidade total dos outros setores
	const totalOtherRoles = sortedEmployeesByRole
		.slice(3)
		.reduce((total, role) => total + role.quantity, 0);

	// Criar uma entrada "Outros" com a quantidade total
	const otherRole = { role: 'Outros', quantity: totalOtherRoles };

	// Criar a lista final com os três primeiros e a entrada "Outros"
	const listByRole = [...topThreeRoles, otherRole];
	const employeesByRole = {
		options: {
			chart: {
				type: 'pie',
			},
			labels: listByRole.map((role) => role?.role ?? 'Sem nome') ?? ['sem dados'],
			legend: {
				show: true,
				position: 'right',
			},
			stroke: {
				width: 1,
			},
			dataLabels: {
				enabled: false,
			},
		},
		series: listByRole.map((role) => role?.quantity ?? 0) ?? [0],
	};

	const sortedEmployeesBySector = dataDashboard?.employeesBySector.sort(
		(a, b) => b.quantity - a.quantity
	);

	// Pegar os três primeiros setores
	const topThreeSectors = sortedEmployeesBySector.slice(0, 3);

	// Calcular a quantidade total dos outros setores
	const totalOtherSectors = sortedEmployeesBySector
		.slice(3)
		.reduce((total, sector) => total + sector.quantity, 0);

	// Criar uma entrada "Outros" com a quantidade total
	const otherSectors = { sector: 'Outros', quantity: totalOtherSectors };

	// Criar a lista final com os três primeiros e a entrada "Outros"
	const listBySector = [...topThreeSectors, otherSectors];
	const employeesBySector = {
		options: {
			labels: listBySector.map((sector) => sector?.sector ?? 'Sem setor') ?? ['sem dados'],
			legend: {
				show: true,
				position: 'right',
			},
			stroke: {
				width: 1,
			},
			dataLabels: {
				enabled: false,
			},
		},
		series: listBySector.map((sector) => sector?.quantity) ?? [0],
	};
	return (
		<>
			<Row>
				<Col md='12' lg='12'>
					<Row className='row-cols-1'>
						<div className='overflow-hidden d-slider1 ' data-aos='fade-up' data-aos-delay='800'>
							<Swiper
								className='p-0 m-0 mb-2 list-inline '
								slidesPerView={5}
								spaceBetween={32}
								navigation={{
									nextEl: '.swiper-button-next',
									prevEl: '.swiper-button-prev',
								}}
								breakpoints={{
									320: { slidesPerView: 1 },
									550: { slidesPerView: 2 },
									991: { slidesPerView: 3 },
									1400: { slidesPerView: 3 },
									1500: { slidesPerView: 4 },
									1920: { slidesPerView: 4 },
									2040: { slidesPerView: 7 },
									2440: { slidesPerView: 8 },
								}}
							>
								<SwiperSlide className='card card-slide'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='bg-info text-white rounded p-3'>
												<svg
													width='22'
													viewBox='0 0 24 24'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														fillRule='evenodd'
														clipRule='evenodd'
														d='M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z'
														stroke='currentColor'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Desenvolvedores</p>
													<div>
														<CustomTooltip text='Total de atendimentos GERADOS até hoje.' />
													</div>
												</div>

												<h4 className='counter'>
													<CountUp start={0} end={dataDashboard?.total} duration={1} />
												</h4>
											</div>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide className=' card card-slide'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='bg-success text-white rounded p-3'>
												<svg
													width='22'
													viewBox='0 0 24 24'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														fillRule='evenodd'
														clipRule='evenodd'
														d='M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z'
														stroke='currentColor'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Média de OS por Dev</p>
													<div>
														<CustomTooltip text='Total de atendimentos FINALIZADOS até hoje.' />
													</div>
												</div>
												<h4 className='counter'>
													<CountUp start={0} end={dataDashboard?.finished} duration={1} />
												</h4>
											</div>
										</div>
									</div>
								</SwiperSlide>

								<SwiperSlide className=' card card-slide'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='bg-danger text-white rounded p-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='20px'
													viewBox='0 0 24 24'
													fill='none'
												>
													<path
														fill-rule='evenodd'
														clip-rule='evenodd'
														d='M21.419 15.732C21.419 19.31 19.31 21.419 15.732 21.419H7.95C4.363 21.419 2.25 19.31 2.25 15.732V7.932C2.25 4.359 3.564 2.25 7.143 2.25H9.143C9.861 2.251 10.537 2.588 10.967 3.163L11.88 4.377C12.312 4.951 12.988 5.289 13.706 5.29H16.536C20.123 5.29 21.447 7.116 21.447 10.767L21.419 15.732Z'
														stroke='currentColor'
														stroke-width='1.5'
														stroke-linecap='round'
														stroke-linejoin='round'
													/>
													<path
														d='M7.48145 14.4629H16.2164'
														stroke='currentColor'
														stroke-width='1.5'
														stroke-linecap='round'
														stroke-linejoin='round'
													/>
												</svg>
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Média de Hora por Dev</p>
													<div>
														<CustomTooltip text='Diferença entre total de atendimentos Gerados e Finalizados.' />
													</div>
												</div>

												<h4 className='counter'>
													<CountUp start={0} end={dataDashboard?.totalStock} duration={1} />
												</h4>
											</div>
										</div>
									</div>
								</SwiperSlide>

								<SwiperSlide className='card card-slide'>
									<div className='card-body'>
										<div className='d-flex align-items-center'>
											<div className=' bg-soft-info rounded p-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='40px'
													fill='none'
													viewBox='0 0 24 24'
													stroke='currentColor'
												>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														strokeWidth='2'
														d='M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
													></path>
												</svg>
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-1'>Remuneração Média</p>
													<div>
														<CustomTooltip text='Nota Média Geral de Atendimento, considerando apenas atendimentos com notas entre 0 e 5.' />
													</div>
												</div>
												<h4>R$ 1650</h4>
											</div>
										</div>
									</div>
								</SwiperSlide>

								<div className='swiper-button swiper-button-next'></div>
								<div className='swiper-button swiper-button-prev'></div>
							</Swiper>
						</div>
					</Row>
				</Col>
				<Col>
					<Row data-aos='fade-up' data-aos-delay='1000'>
						<h3>Análise de Desenvolvedores</h3>
						<Row style={{ gap: '1rem' }}>
							<Card style={{ maxWidth: '380px' }}>
								<Card.Body>
									<div className='d-flex justify-content-between align-items-center'>
										<div className='bg-info text-white rounded p-3'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='20px'
												height='20px'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
													d='M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z'
												/>
											</svg>
										</div>
										<div className='text-end'>
											Total de Horas Gastas
											<div
												className='d-flex flex-wrap align-items-center justify-content-evenly'
												style={{ gap: '0.5rem' }}
											>
												<div className='badge bg-primary'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='20px'
														viewBox='0 0 20 20'
														fill='currentColor'
													>
														<path
															fillRule='evenodd'
															d='M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
															clipRule='evenodd'
														/>
													</svg>
													<span>3.48%</span>
												</div>

												<h2 className='counter'>
													<CountUp
														start={15}
														end={dataDashboard?.activeEmployees ?? 0}
														duration={1}
													/>
												</h2>
											</div>
										</div>
									</div>
								</Card.Body>
							</Card>

							<Card style={{ maxWidth: '380px' }}>
								<Card.Body>
									<div className='d-flex justify-content-between align-items-center'>
										<div className='bg-info text-white rounded p-3'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='20px'
												height='20px'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
													d='M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z'
												/>
											</svg>
										</div>
										<div className='text-end'>
											Total Concluído
											<div
												className='d-flex flex-wrap align-items-center justify-content-evenly'
												style={{ gap: '0.5rem' }}
											>
												<div className='badge bg-primary'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='20px'
														viewBox='0 0 20 20'
														fill='currentColor'
													>
														<path
															fillRule='evenodd'
															d='M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
															clipRule='evenodd'
														/>
													</svg>
													<span>3.48%</span>
												</div>
												<h2 className='d-flex justify-content-end counter'>15</h2>
											</div>
										</div>
									</div>
								</Card.Body>
							</Card>

							<Card style={{ maxWidth: '380px' }}>
								<Card.Body>
									<div className='d-flex justify-content-between align-items-center'>
										<div className='bg-info text-white rounded p-3'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='20px'
												height='20px'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
													d='M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z'
												/>
											</svg>
										</div>
										<div className='text-end'>
											Finalizados Totais
											<div
												className='d-flex flex-wrap align-items-center justify-content-evenly'
												style={{ gap: '0.5rem' }}
											>
												<div className='badge bg-primary'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='20px'
														viewBox='0 0 20 20'
														fill='currentColor'
													>
														<path
															fillRule='evenodd'
															d='M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
															clipRule='evenodd'
														/>
													</svg>
													<span>3.48%</span>
												</div>
												<h2 className='d-flex justify-content-end counter'>105</h2>
											</div>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Row>

						<div className='overflow-hidden d-slider1'>
							<Swiper
								className='p-0 m-0 mb-2 list-inline '
								slidesPerView={2}
								spaceBetween={32}
								navigation={true}
							>
								<SwiperSlide
									className='card card-slider'
									style={{ height: '300px', maxWidth: '900px' }}
								>
									<Card.Header>
										<h5>Por produto</h5>
									</Card.Header>
									<Card.Body>
										<Chart
											options={employeesBySector.options}
											series={employeesBySector.series}
											type='pie'
											height='200'
										/>
									</Card.Body>
								</SwiperSlide>

								<SwiperSlide
									className='card card-slider'
									style={{ height: '300px', maxWidth: '900px' }}
								>
									<Card.Header>
										<h5>Por Cliente</h5>
									</Card.Header>
									<Card.Body>
										<Chart
											options={employeesByRole.options}
											series={employeesByRole.series}
											type='pie'
											height='200'
										/>
									</Card.Body>
								</SwiperSlide>
							</Swiper>
						</div>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default Productivity;
