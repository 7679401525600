import React, { memo, Fragment } from 'react';
import { Row, Col, Container } from 'react-bootstrap';

const SubHeader = memo((props) => {
	return (
		<Fragment>
			<div className='iq-navbar-header' style={{ height: '215px' }}>
				<Container fluid className=' iq-container'>
					<Row>
						<Col md='12'>
							<div className='d-flex justify-content-between flex-wrap'>
								<div>
									<h1>Olá, {JSON.parse(localStorage.getItem('@datahub-token'))?.name}</h1>
									<p>Acompanhe a visão geral em dados da Coplan.</p>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
				{/* {{!-- rounded-bottom if not using animation --}} */}
				<div className='iq-header-img' style={{ backgroundColor: '#1F3893' }} />
			</div>
		</Fragment>
	);
});

export default SubHeader;
