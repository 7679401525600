import React, { useState } from 'react';
//style
import { FaArrowLeftLong } from 'react-icons/fa6';
//components
import { Button, Form, Modal } from 'react-bootstrap';
import { IntegrateBI } from './integrate-BI';
import { IntegrateClienteMais } from './integrate-ClienteMais';
import { GoBack } from './style';

export const CreateUser = ({ show, setShow, refetchUsers }) => {
	const [step, setStep] = useState(1);

	return (
		<Modal show={show} backdrop='static' className='back-blur' onHide={() => setShow(false)}>
			<Modal.Header closeButton>
				<h4>Criar/Integrar Usuário</h4>
			</Modal.Header>
			<Modal.Body>
				<GoBack
					step={step}
					className='text-dark d-flex gap-2 align-items-center cursor-pointer'
					onClick={() => step !== 1 && setStep(1)}
				>
					{step !== 1 && <FaArrowLeftLong size={22} />}
					<span>Selecione a opção:</span>
				</GoBack>

				{step === 1 && (
					<div className='d-flex flex-column gap-2 mt-4'>
						<Button variant='secondary' onClick={() => setStep(2)}>
							Criar conta
						</Button>
						<Button variant='primary' onClick={() => setStep(3)}>
							Integrar com Cliente+
						</Button>
					</div>
				)}
				{step === 2 && <IntegrateBI refetchUsers={refetchUsers} setShow={setShow} />}

				{step === 3 && <IntegrateClienteMais refetchUsers={refetchUsers} setShow={setShow} />}
			</Modal.Body>
		</Modal>
	);
};
