import React, { useEffect } from 'react';

import { Card, Col, ProgressBar } from 'react-bootstrap';
import { ReactSortable } from 'react-sortablejs';
import Progress from '../progress/progress';
import { GroupWrapper, Quantity } from './style';

export const FlowCard = ({ list, setList, group, isMinimized }) => {
	const colors = {
		group1: 'success',
		group2: 'info',
		group3: 'warning',
		group4: 'primary',
	};

	return (
		<div className={`${group}-wrap`}>
			<GroupWrapper
				isMinimized={isMinimized}
				list={list.length}
				className='group'
				id={`${group}-wrap`}
			>
				<Col lg='12' className='group__item'>
					<ReactSortable
						list={list}
						group={{
							name: `${group}`,
							put: ['group0', 'group1', 'group2', 'group3', 'group4'],
						}}
						pull={true}
						animation={200}
						delayOnTouchStart={true}
						delay={2}
						setList={setList}
					>
						{list.map((item) => (
							<Card key={item?.id} style={{ height: '150px' }}>
								<Card.Body>
									<div className='d-grid grid-flow-col align-items-center justify-content-between mb-2'>
										<p title={item.status} className='overflow-hidden text-nowrap mb-0'>
											{item.status ?? 'Status não encontrado'}
										</p>
									</div>

									<div className='d-flex justify-content-center align-items-end mb-2'>
										<Quantity>{item.quantity ?? 0}</Quantity>
									</div>

									<div className='d-flex align-items-center' style={{ gap: '1rem' }}>
										<span>{item.ratio}%</span>
										<Progress
											softcolors={colors[group]}
											color={colors[group]}
											className='shadow-none w-100 '
											value={item.ratio}
											minvalue={0}
											maxvalue={100}
											style={{ height: '6px' }}
										/>
									</div>
								</Card.Body>
							</Card>
						))}
					</ReactSortable>
				</Col>
			</GroupWrapper>
		</div>
	);
};
