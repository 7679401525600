import React, { useEffect, memo, Fragment } from 'react';
import { Row, Col, Dropdown, Button, Card } from 'react-bootstrap';
import { Link, Outlet, useNavigate } from 'react-router-dom';

// AOS
import AOS from 'aos';
import '../../../node_modules/aos/dist/aos';
import '../../../node_modules/aos/dist/aos.css';

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
// import 'swiper/components/navigation/navigation.scss';

//ReactIcons
import { AiOutlinePlus } from 'react-icons/ai';

// Redux Selector / Action
import { useSelector } from 'react-redux';

// Import selectors & action from setting store
import * as SettingSelector from '../../store/setting/selectors';

// install Swiper modules
SwiperCore.use([Navigation]);

const DocumentationPage = memo((props) => {
	const navigate = useNavigate();

	useSelector(SettingSelector.theme_color);

	const getVariableColor = () => {
		let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
		if (prefix) {
			prefix = prefix.trim();
		}
		const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
		const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
		const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
		const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
		return {
			primary: color1.trim(),
			info: color2.trim(),
			warning: color4.trim(),
			primary_light: color3.trim(),
		};
	};
	const variableColors = getVariableColor();

	const colors = [variableColors.primary, variableColors.info];
	useEffect(() => {
		return () => colors;
	});

	useEffect(() => {
		AOS.init({
			startEvent: 'DOMContentLoaded',
			disable: function () {
				var maxWidth = 996;
				return window.innerWidth < maxWidth;
			},
			throttleDelay: 10,
			once: true,
			duration: 700,
			offset: 10,
		});
	});

	return (
		<Fragment>
			<Row>
				<Col md='12' lg='12'>
					<Row className='row-cols-1'>
						<div className='overflow-hidden d-slider1' data-aos='fade-up' data-aos-delay='800'>
							<div
								className='d-flex align-items-center'
								style={{ gap: '1rem', marginBottom: '1rem' }}
							>
								<h1>Acesso Rápido</h1>

								<div className='card' style={{ width: 'max-content', margin: '0' }}>
									<div className='card-body' style={{ width: 'max-content' }}>
										<AiOutlinePlus />
									</div>
								</div>
							</div>
							<Swiper
								className='p-0 m-0 mb-2 list-inline '
								slidesPerView={5}
								spaceBetween={32}
								navigation={{
									nextEl: '.swiper-button-next',
									prevEl: '.swiper-button-prev',
								}}
								breakpoints={{
									320: { slidesPerView: 1 },
									550: { slidesPerView: 2 },
									991: { slidesPerView: 3 },
									1400: { slidesPerView: 3 },
									1500: { slidesPerView: 4 },
									1920: { slidesPerView: 4 },
									2040: { slidesPerView: 7 },
									2440: { slidesPerView: 8 },
								}}
							>
								<SwiperSlide
									className='card card-slide'
									style={{ cursor: 'pointer' }}
									onClick={() => navigate('1')}
								>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Como criar um novo indicador</p>
													<div></div>
												</div>
											</div>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide className='card card-slide'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Como alterar um indicador</p>
													<div></div>
												</div>
											</div>
										</div>
									</div>
								</SwiperSlide>

								<SwiperSlide className='card card-slide'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Integrar um novo banco de dados externo</p>
													<div></div>
												</div>
											</div>
										</div>
									</div>
								</SwiperSlide>

								<div className='swiper-button swiper-button-next'></div>
								<div className='swiper-button swiper-button-prev'></div>
							</Swiper>
							<hr className='hr-horizontal' />
							<Outlet />
						</div>
					</Row>
				</Col>
			</Row>
		</Fragment>
	);
});

export default DocumentationPage;
