import styled from 'styled-components';

export const TableWrapper = styled.div`
	table{
		--data-table-library_grid-template-columns: 
		minmax(0px, max-content) 
		minmax(0px, max-content) 
		minmax(0px, max-content) 
		minmax(0px, max-content) 
		minmax(0px, max-content) 
		minmax(0px, max-content) 
		minmax(0px, max-content) !important
	}
`;
