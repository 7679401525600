import React, { useEffect, useState } from 'react';

import { MoreDetail } from './style';
//components
import Lottie from 'react-lottie';
import * as animationData from '../../assets/animations/empty-data.json';
import Chart from 'react-apexcharts';
import { Card, Spinner } from 'react-bootstrap';
import CustomTooltip from '../customTooltip/CustomTooltip';
import piechartData from '../../utils/charts/piechartData';

const PiechartCard = ({
	title,
	tooltip,
	data,
	labelAttribute,
	quantityAttribute,
	legendPosition,
}) => {
	const [quantityFilter, setQuantityFilter] = useState(true);

	const chartFocus = () => {
		const chart = document.getElementById(tooltip.replace(/\s+/g, '-'));
		document.getElementById(tooltip.replace(/\s+/g, '_')).classList.remove('not-anim');
		chart.focus();
		chart.scrollIntoView({ block: 'center' });

		setTimeout(() => {
			document.getElementById(tooltip.replace(/\s+/g, '_')).classList.add('not-anim');
		}, 500);
	};

	const options = {
		loop: false,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<div>
			<Card.Header className='d-flex' style={{ gap: '0.5rem' }}>
				<h5>{title}</h5>
				<CustomTooltip text={tooltip} />
			</Card.Header>

			<Card.Body>
				{data ? (
					<div style={{ position: 'relative' }}>
						<Chart
							id={tooltip.replace(/\s+/g, '-')}
							options={
								piechartData(
									data,
									labelAttribute,
									quantityAttribute,
									quantityFilter,
									legendPosition
								).options
							}
							series={
								piechartData(
									data,
									labelAttribute,
									quantityAttribute,
									quantityFilter,
									legendPosition
								).series
							}
							type='donut'
							height='200'
						/>
						{data?.length < 1 ? (
							<div style={{ position: 'absolute', top: '0', width: '100%', textAlign: 'center' }}>
								<Lottie
									style={{ opacity: '50%' }}
									options={options}
									height={200}
									width={200}
									className='mb-4'
									alt=''
								/>
								<b>Nenhum resultado!</b>
							</div>
						) : null}
					</div>
				) : (
					<Spinner />
				)}
			</Card.Body>

			{data?.length > 4 ? (
				<Card.Footer
					style={{ padding: '0', textAlign: 'center' }}
					onClick={() => (setQuantityFilter(!quantityFilter), chartFocus())}
				>
					<MoreDetail className='not-anim' id={tooltip.replace(/\s+/g, '_')}>
						{quantityFilter ? 'Mais Detalhes' : 'Menos Detalhes'}
					</MoreDetail>
				</Card.Footer>
			) : null}
		</div>
	);
};

export default PiechartCard;
