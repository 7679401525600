import { createContext, useState, useEffect } from 'react';
import { api } from '../api/api';
//hooks
import { useMutation, useQuery } from 'react-query';
import { useLocalStorage } from '@uidotdev/usehooks';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	const [userToken, setUserToken] = useLocalStorage('@datahub-token');

	const getToken = () => {
		return userToken?.token;
	};

	const login = async (email, password) => {
		try {
			const response = await api.post('users/login', { login: email, password: password });

			if (response.status === 200) {
				// window.location.reload();
				setUserToken(response.data);
				return response;
			}
		} catch (error) {
			return error;
		}
	};

	const logout = () => {
		window.location.replace('/');
		setUserToken(null);
	};
	// const { data: login } = useMutation({
	// 	mutationFn: async (email, password) => {

	// 	},
	// 	onError: () => 'Ocorreu um erro',
	// });

	return (
		<AuthContext.Provider value={{ login, logout, isLogged: !!userToken, getToken }}>
			{children}
		</AuthContext.Provider>
	);
};
