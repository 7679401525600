//router
// import IndexRouters from "./router/index"
import React, { useEffect } from 'react';

import './assets/scss/hope-ui.scss';
import './assets/scss/custom.scss';
import './assets/scss/dark.scss';
import './assets/scss/rtl.scss';
import './assets/scss/customizer.scss';

import { useDispatch } from 'react-redux';

// import state selectors
import { setSetting } from './store/setting/actions';

function App({ children }) {
	const dispatch = useDispatch();
	dispatch(setSetting());

	// const clearStorage = () => {
	// 	var token = localStorage.getItem('@datahub-token');

	// 	localStorage.clear();
	// 	sessionStorage.clear();
	// 	localStorage.setItem('@datahub-token', token);
	// };

	// useEffect(() => {
	// 	clearStorage();
	// }, []);

	return <div className='App'>{children}</div>;
}

export default App;
