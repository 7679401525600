import React, { useEffect, useMemo, useState } from 'react';

import { LoadingPage } from '../loadingPage/loadingPage';

const LazyPage = ({ component }) => {
  const [page, setPage] = useState(<></>);

  const Page = useMemo(() => {
    setPage(<LoadingPage />);
    const timeoutId = setTimeout(() => {
      setPage(component);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [component]);

  return page;
};

export default LazyPage;
