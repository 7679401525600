import React, { useState } from 'react';
//hooks
import { useMutation, useQuery } from 'react-query';
import useStars from '../../../hooks/useStars';
//style
import { GoClock } from 'react-icons/go';
import { FaRegSmile } from 'react-icons/fa';
import { BiMessageSquareCheck } from 'react-icons/bi';
import { FaWhatsapp } from 'react-icons/fa';
import { CiSettings } from 'react-icons/ci';
//components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
SwiperCore.use([Navigation]);
import 'swiper/swiper-bundle.min.css';
import CustomTooltip from '../../../components/customTooltip/CustomTooltip';
import { LinePlaceholder } from '../../../components/Skeletons/LinePlaceholder';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TableR } from '../../../components/table/Table';
import toast from 'react-hot-toast';
import { apiAurora } from '../../../api/api';
import { VscDebugRestart } from 'react-icons/vsc';
import { RestartIcon } from './style';
import Searchbox from '../../../components/searchbox/searchbox';
import useFormattedValues from '../../../hooks/useFormattedValues';
import { MessageHistory } from '../../../components/messageHistory/messageHistory';
import { WhatsappSettings } from '../../../components/whatsappSettings/whatsappSettings';

const restartErrors = {
	1001: 'Falha ao reiniciar conversa! Tente novamente mais tarde.',
	1002: 'Usuário não logado! Entre novamente na conta.',
	1003: 'Falha ao reiniciar conversa! Tente novamente mais tarde.',
	1004: 'Chat já fechado!',
	1005: 'Chat não encontrado! Tente novamente mais tarde.',
	1007: `Não é possível reiniciar o chat a partir da etapa "Chat Com Atendente".`,
};
const WhatsappOverview = () => {
	const { setStars } = useStars();
	const { setValue } = useFormattedValues();

	const [chats, setWhatsappChats] = useState([]);
	const [showWhatsappSettings, setShowWhatsappSettings] = useState(false);

	const { data: whatsappChats, isLoading: isLoadingWhatsappChats } = useQuery(
		['whatsappChats'],
		() => getWhatsappChats()
	);

	const { data: whatsappCards, isLoading: isLoadingWhatsappCards } = useQuery(
		['whatsappCards'],
		() => getWhatsappCards()
	);
	const { mutate: restartChat, isLoading: isLoadingRestart } = useMutation(
		async ({ id }) => {
			const response = await apiAurora.post(`/chats/${id}/restart`);
			return response?.data;
		},
		{
			onSuccess: () => {
				toast.success('Conversa reiniciada com sucesso!');
			},
			onError: (error) => {
				toast.error(restartErrors[error?.response?.data?.internalCode]);
			},
		}
	);

	const handleRestartChat = (id) => {
		restartChat({ id });
	};

	const getWhatsappCards = async () => {
		try {
			const response = await apiAurora.get('/chats/cards/info');
			return response?.data;
		} catch (error) {
			toast.error(
				`Não foi possível obter informações (Cards: Erro !${error?.response?.status || 500})`
			);
		}
	};
	const getWhatsappChats = async () => {
		try {
			const response = await apiAurora.get('/chats/info');
			setWhatsappChats({ nodes: response?.data });
			return response?.data;
		} catch (error) {
			toast.error('Falha ao buscar conversas!');
		}
	};

	return (
		<>
			<section>
				<Swiper
					className='p-0 m-0 mb-2 list-inline '
					slidesPerView={5}
					spaceBetween={32}
					navigation={{
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					}}
					breakpoints={{
						320: { slidesPerView: 1 },
						550: { slidesPerView: 2 },
						991: { slidesPerView: 3 },
						1400: { slidesPerView: 3 },
						1500: { slidesPerView: 4 },
						1920: { slidesPerView: 5 },
					}}
				>
					<SwiperSlide className='card-slide card'>
						<Card.Body>
							<div className='progress-widget'>
								<div className='bg-success text-white rounded p-3'>
									<i>
										<FaWhatsapp size={22} />
									</i>
								</div>
								<div className='progress-detail' style={{ width: '100%' }}>
									<div className='d-flex justify-content-between'>
										<p className='mb-2'>Atendimentos</p>
										<div>
											<CustomTooltip
												text={
													<div style={{ textAlign: 'left' }}>
														<p>Total de atendimentos recebidos via canal Whatsapp</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 className='counter'>
										<LinePlaceholder isLoading={false}>
											{setValue(whatsappCards?.totalChatsQuantity)}
										</LinePlaceholder>
									</h4>
								</div>
							</div>
						</Card.Body>
					</SwiperSlide>
					<SwiperSlide className='card-slide card'>
						<Card.Body>
							<div className='progress-widget'>
								<div className='bg-danger text-white rounded p-3'>
									<i>
										<BiMessageSquareCheck size={22} />
									</i>
								</div>
								<div className='progress-detail' style={{ width: '100%' }}>
									<div className='d-flex justify-content-between'>
										<p className='mb-2'>Taxa de conversão</p>
										<div>
											<CustomTooltip
												text={
													<div style={{ textAlign: 'left' }}>
														<p>
															Percentual de atendimentos que conseguiram entrar em contato com o
															suporte humano.
														</p>
														<p>
															Considera-se "atendimento humano" todo atendimento que é gerado um
															protocolo de chat no Cliente+.
														</p>
														<p>
															* Fórmula: (Qtd de Atendimentos Humanos / total de atendimentos) * 100
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 className='counter'>
										<LinePlaceholder isLoading={false}>
											{setValue(whatsappCards?.convertedChatsPercent)}%
										</LinePlaceholder>
									</h4>
								</div>
							</div>
						</Card.Body>
					</SwiperSlide>
					<SwiperSlide className='card-slide card'>
						<Card.Body>
							<div className='progress-widget'>
								<div className='bg-soft-info text-info rounded p-3'>
									<i>
										<FaRegSmile size={22} />
									</i>
								</div>
								<div className='progress-detail' style={{ width: '100%' }}>
									<div className='d-flex justify-content-between'>
										<p className='mb-2'>Nota média</p>
										<div>
											<CustomTooltip
												text={
													<div style={{ textAlign: 'left' }}>
														<p>
															Nota média de 0 a 5, excluindo atendimentos não avaliados.
															<p>*Fórmula: Soma Total de Notas / Qtd de Atendimentos</p>
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 className='counter'>
										<OverlayTrigger
											className='info'
											placement='bottom'
											overlay={
												whatsappCards?.chatsAverageRate ? (
													<Tooltip>{whatsappCards?.chatsAverageRate}</Tooltip>
												) : (
													<div />
												)
											}
										>
											<div>
												{setValue(
													whatsappCards?.chatsAverageRate,
													'rate',
													'Não houveram avaliações/atendimentos'
												)}
											</div>
										</OverlayTrigger>
									</h4>
								</div>
							</div>
						</Card.Body>
					</SwiperSlide>
					<SwiperSlide className='card-slide card'>
						<Card.Body>
							<div className='progress-widget'>
								<div className='bg-warning text-white rounded p-3'>
									<i>
										<GoClock size={22} />
									</i>
								</div>
								<div className='progress-detail' style={{ width: '100%' }}>
									<div className='d-flex justify-content-between'>
										<p className='mb-2'>Duração média</p>
										<div>
											<CustomTooltip
												text={
													<div style={{ textAlign: 'left' }}>
														Considera a duração de todos os atendimentos, a partir da primeira
														interação do usuário.
													</div>
												}
											/>
										</div>
									</div>
									<h4 className='counter'>
										<LinePlaceholder isLoading={false}>
											{setValue(whatsappCards?.chatsAverageTime, 'timingSeconds')}
										</LinePlaceholder>
									</h4>
								</div>
							</div>
						</Card.Body>
					</SwiperSlide>
				</Swiper>
			</section>

			<section>
				<Card>
					<Card.Header className='d-flex gap-4'>
						<h4 className='mb-0'>Gerenciador do Whatsapp</h4>

						<Searchbox
							list={whatsappChats}
							setResult={setWhatsappChats}
							atributeToSearch={'phoneNumber'}
						/>
					</Card.Header>
					<Card.Body>
						<TableR
							columns={['Data', 'Cliente', 'Número', 'Etapa', 'Duração', 'Avaliação', 'Protocolo']}
							dataColumns={[
								'createdAt',
								'userName',
								'phoneNumber',
								'step',
								'time',
								'chatRate',
								'protocol',
							]}
							hasExpansion={false}
							isLoading={isLoadingWhatsappChats}
							isRefetching={false}
							data={chats.nodes}
							limitTable={4}
							firstColumnFixed={true}
							action={handleRestartChat}
							isLoadingAction={isLoadingRestart}
							actionType={'actionWithId'}
							actionButton={
								<OverlayTrigger
									className='info'
									placement='bottom'
									overlay={<Tooltip>{'Reiniciar conversa'}</Tooltip>}
								>
									<Button disabled={isLoadingRestart} className='d-flex gap-2 align-items-center'>
										<RestartIcon isLoading={isLoadingRestart}>
											<VscDebugRestart className='restart-icon' size={22} />
										</RestartIcon>
									</Button>
								</OverlayTrigger>
							}
							emptyValue={
								<ol className='text-start'>
									<p>Este campo apresenta-se como VAZIO nas seguintes condições:</p>
									<li>Etapa Pendente: Ainda não foi alcançada pelo usuário.</li>
									<li>
										Entrada Inválida: Foi inserida uma opção que não atende aos critérios
										estabelecidos.
									</li>
									<li>Ausência de Resposta: Não houve fornecimento de informação pelo usuário.</li>
								</ol>
							}
							modal={<MessageHistory />}
							modalType={'byId'}
						/>
					</Card.Body>
				</Card>
			</section>

			<section>
				<Button
					className='d-flex gap-2 align-items-center'
					onClick={() => setShowWhatsappSettings(true)}
				>
					<CiSettings size={50} />
					Configurações e Parametrização
				</Button>
			</section>

			{/* Esperando homologar */}
			<WhatsappSettings show={showWhatsappSettings} setShow={setShowWhatsappSettings} />
		</>
	);
};

export default WhatsappOverview;
