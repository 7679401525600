import React, { useEffect, useState } from 'react';

import { PaginationButton } from './style';
import { LuArrowLeft, LuArrowLeftToLine, LuArrowRight, LuArrowRightToLine } from 'react-icons/lu';

/**
 * a ideia é que sirva tanto para paginação de dados no servidor como para dados locais
 * @param {object} data o array com dados deve estar dentro de uma propriedade `nodes`
 * @returns {JSX.Element} componente de paginação
 */
export const ServerPagination = ({ data, totalData, pagination, actualPage, setPagination }) => {
	const totalPages = Math.ceil((totalData || 0) / 5);

	useEffect(() => {
		pagination.fns.onSetPage(0);
	}, []);

	return (
		<div className='d-flex gap-1'>
			<PaginationButton type='button' disabled={actualPage === 1} onClick={() => setPagination(1)}>
				<LuArrowLeftToLine />
			</PaginationButton>
			<PaginationButton
				type='button'
				disabled={actualPage === 1}
				onClick={() => setPagination(actualPage - 1)}
			>
				<LuArrowLeft />
			</PaginationButton>

			{Array.from(
				{ length: 2 },
				(_, index) =>
					actualPage - 1 - index >= 1 && (
						<PaginationButton type='button' onClick={() => setPagination(actualPage - 1 - index)}>
							{actualPage - 1 - index}
						</PaginationButton>
					)
			).reverse()}

			<PaginationButton type='button' bold>
				{actualPage}
			</PaginationButton>

			{Array.from(
				{ length: 3 },
				(_, index) =>
					actualPage + index < totalPages && (
						<PaginationButton type='button' onClick={() => setPagination(actualPage + 1 + index)}>
							{actualPage + 1 + index}
						</PaginationButton>
					)
			)}
			<PaginationButton
				type='button'
				disabled={actualPage >= totalPages}
				onClick={() => setPagination(actualPage + 1)}
			>
				<LuArrowRight />
			</PaginationButton>
			<PaginationButton
				type='button'
				disabled={actualPage >= totalPages}
				onClick={() => setPagination(totalPages)}
			>
				<LuArrowRightToLine />
			</PaginationButton>
		</div>
	);
};
