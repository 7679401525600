import React from 'react';
import { api } from '../../api/api';
//hooks
import { useMutation } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
//components
import * as yup from 'yup';
import { Button, Form, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { CpfMask } from '../cpfCnpjMask/cpfCnpjMask';

const internalCodes = {
	1006: 'Usuário já existente.',
	1007: 'Usuário não existente no Cliente+.',
};

export const IntegrateBI = ({ refetchUsers, setShow }) => {
	const { register, watch, control, handleSubmit } = useForm({ shouldUseNativeValidation: true });
	const name = watch('nome');
	const password = watch('senha');
	const cpf = watch('cpf');
	const email = watch('email');
	const role = watch('cargo');
	const { mutate: createAccount, isLoading: creatingAccount } = useMutation({
		mutationFn: async () => {
			const fieldsFormatted = {
				email: email,
				senha: password,
				nome: name,
				cpf: cpf.toString().replace(/\D/g, ''),
				cargo: role,
			};

			const response = await api.post(`/users?type=${'new'}`, fieldsFormatted);
			return response?.data;
		},
		onSuccess: () => (refetchUsers(), toast.success(`Conta criada!`), setShow(false)),
		onError: (error) => {
			toast.error(
				internalCodes[error?.response?.data.internalCode] ||
					'Não foi possível criar a conta. Tente novamente mais tarde.'
			);
		},
	});

	const handleCreateAccount = async () => {
		const validation = await validate();
		if (validation === 200) createAccount();
	};

	const validate = async () => {
		const validationSchema = yup.object().shape({
			email: yup.string().required('CPF/CNPJ é obrigatório'),
			password: yup
				.string()
				.required('Senha é obrigatória')
				.min(3, 'Senha deve ter pelo menos 3 caracteres'),
			role: yup.string().required('Cargo é obrigatório'),
			name: yup
				.string()
				.required('Nome é obrigatório')
				.min(3, 'Nome deve ter pelo menos 3 caracteres'),
			cpf: yup.string().required('CPF é obrigatório'),
		});

		try {
			await validationSchema.validate({
				email,
				password,
				role,
				name,
				cpf,
			});
			return 200;
		} catch (error) {
			toast.error(error.message);
			return 400;
		}
	};

	return (
		<Form className='mt-4' onSubmit={handleSubmit(handleCreateAccount)}>
			<Form.Group className='mb-2'>
				<Form.Label htmlFor='name'>
					Nome <span className='text-danger'>*</span>
				</Form.Label>
				<Form.Control type='text' id='name' aria-label='nome do usuário' {...register('nome')} />
			</Form.Group>
			<Form.Group className='mb-2'>
				<Form.Label htmlFor='email'>
					E-mail <span className='text-danger'>*</span>
				</Form.Label>
				<Form.Control
					type='email'
					id='email'
					aria-label='email do usuário'
					{...register('email')}
				/>
			</Form.Group>
			<Form.Group className='mb-2'>
				<Form.Label htmlFor='cpf'>
					CPF/CNPJ <span className='text-danger'>*</span>
				</Form.Label>
				<Controller
					name='cpf'
					control={control}
					render={({ field }) => (
						<CpfMask value={field.value} onChange={field.onChange} disabled={field.disabled}>
							<Form.Control type='text' />
						</CpfMask>
					)}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label htmlFor='password'>
					Senha <span className='text-danger'>*</span>
				</Form.Label>
				<Form.Control
					type='password'
					id='password'
					aria-label='senha do usuário'
					{...register('senha')}
				/>
			</Form.Group>
			<Form.Group className='mb-4'>
				<Form.Label htmlFor='role'>
					Cargo <span className='text-danger'>*</span>
				</Form.Label>
				<Form.Select id='role' aria-label='cargo do usuário' {...register('cargo')}>
					<option value=''>Selecione o cargo</option>
					<option value='D'>Diretor</option>
					<option value='SA'>Analista de suporte</option>
					<option value='SM'>Gerente de suporte</option>
					<option value='PM'>Gestor de Pessoas</option>
					<option value='FM'>Gestor financeiro</option>
				</Form.Select>
			</Form.Group>

			<Button
				className='d-flex align-items-center gap-2'
				type='submit'
				disabled={!name || !email || !cpf || !password || !role || creatingAccount}
			>
				Criar conta
				{creatingAccount && <Spinner size='sm' />}
			</Button>
		</Form>
	);
};
