import React from 'react';

import { TD } from './style';

import ReactPlaceholder from 'react-placeholder';

/**
 * @param _ The skeleton loading for tables.
 * @param {number} fieldQuantity - The number of fields in loading.
 * @param {boolean} isActive - Visibility.
 * @return {JSX.Element} The skeleton for table loading.
 */

export const Skeleton = ({ fieldQuantity, isActive }) => {
	return (
		isActive && (
			<div className='d-flex justify-content-center' data-testid='skeleton-table'>
				<table>
					<thead>
						{Array.from({ length: fieldQuantity }, (_, i) => (
							<TD key={i}>Loading...</TD>
						))}
					</thead>

					<tbody>
						{Array.from({ length: 5 }, (_, i) => (
							<tr key={i}>
								{Array.from({ length: fieldQuantity }, (_, i) => (
									<TD key={i}>
										<ReactPlaceholder
											type='text'
											rows={1}
											ready={false}
											showLoadingAnimation
											color='#E0E0E0'
										>
											Loading...
										</ReactPlaceholder>
									</TD>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		)
	);
};
