import React from 'react';
import { api } from '../../api/api';
//hooks
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
//components
import { Button, Form, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { CpfMask } from '../cpfCnpjMask/cpfCnpjMask';

const internalCodes = {
	1006: 'Usuário já existente.',
	1007: 'Usuário não existente no Cliente+.',
};

export const IntegrateClienteMais = ({ refetchUsers, setShow }) => {
	const { register, watch, control, handleSubmit } = useForm({ shouldUseNativeValidation: true });
	const cpf = watch('cpf');

	const { mutate: createAccount, isLoading: creatingAccount } = useMutation({
		mutationFn: async () => {
			const fieldsFormatted = {
				cpf: cpf.toString().replace(/\D/g, ''),
			};
			const response = await api.post(`/users?type=${'sync'}`, fieldsFormatted);
			return response?.data;
		},
		onSuccess: () => (refetchUsers(), toast.success(`Conta integrada!`), setShow(false)),
		onError: (error) => {
			toast.error(
				internalCodes[error?.response?.data.internalCode] ||
					'Não foi possível criar a conta. Tente novamente mais tarde.'
			);
		},
	});

	return (
		<>
			<Form className='mt-4' onSubmit={handleSubmit(createAccount)}>
				<Form.Group className='mb-4'>
					<Form.Label htmlFor='cpf'>
						CPF <span className='text-danger'>*</span>
					</Form.Label>
					<Controller
						name='cpf'
						control={control}
						render={({ field }) => (
							<CpfMask value={field.value} onChange={field.onChange} disabled={field.disabled}>
								<Form.Control type='text' />
							</CpfMask>
						)}
					/>
				</Form.Group>

				<Button
					className='d-flex align-items-center gap-2'
					type='submit'
					disabled={!cpf || creatingAccount}
				>
					Criar conta
					{creatingAccount && <Spinner size='sm' />}
				</Button>
			</Form>
		</>
	);
};
