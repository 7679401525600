import React, { useEffect, memo, Fragment, useState } from 'react';
import { api } from '../../../api/api';
//hooks
import { useQuery } from 'react-query';
import { useSessionStorage } from '@uidotdev/usehooks';
import { useSelector } from 'react-redux';

// AOS
import AOS from 'aos';
import 'aos';
import '../../../../node_modules/aos/dist/aos.css';
//charts
import Chart from 'react-apexcharts';
import stockXAccumulated from '../../../utils/charts/stockXAccumulatedData';

// Import selectors & action from setting store
import * as SettingSelector from '../../../store/setting/selectors';

// install Swiper modules
SwiperCore.use([Navigation]);

//components
import { Row, Col, Card } from 'react-bootstrap';
import CustomTooltip from '../../../components/customTooltip/CustomTooltip';
import ReactPlaceholder from 'react-placeholder';
import CountUp from 'react-countup';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

//styles
import { DualChartsSection } from './style';
import 'react-placeholder/lib/reactPlaceholder.css';
import 'swiper/swiper-bundle.min.css';

//icons
import { IconContext } from 'react-icons/lib';
import { BsCurrencyDollar, BsCodeSlash } from 'react-icons/bs';
import PiechartCard from '../../../components/piechartCard/piechartCard';

const Rh = memo((props) => {
	useSelector(SettingSelector.theme_color);

	const [isLoading, setLoading] = useSessionStorage('isLoading', false);
	const [dataDashboard, setDataDashboard] = useSessionStorage('dashboardRh', null);
	//Charts filters
	const filterAllXFinished = 'Anual';
	//gráficos por período
	const [categoryDaysStockXAccumulated, setCategoryDaysStockXAccumulated] = useState([
		'Jan',
		'Fev',
		'Mar',
		'Abr',
		'Mai',
		'Jun',
		'Jul',
		'Ago',
		'Set',
		'Out',
		'Nov',
		'Dez',
	]);
	const [monthsAllByMonth, setMonthsAllByMonth] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
	const [monthsAbsensesByMonth, setMonthsAbsensesByMonth] = useState([
		0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
	]);
	const [monthsStockByMonth, setMonthsStockByMonth] = useState([
		0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
	]);

	//Pega os dados e armazena no sessionstorage
	const { data: getDataRh } = useQuery(
		['dataRh'],
		async () => {
			setLoading(true);
			const response = await api.get('/indices/hr');

			setDataDashboard(response?.data);

			return response?.data;
		},
		{
			onError: (err) => console.log(err),
			onSettled: () => setLoading(false),
			onSuccess: () => {
				applyFilterInServices();
				applyFilterInStock();
				applyFilterInAbsenses();
			},
		}
	);

	const getVariableColor = () => {
		let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
		if (prefix) {
			prefix = prefix.trim();
		}
		const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
		const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
		const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
		const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
		return {
			primary: color1.trim(),
			info: color2.trim(),
			warning: color4.trim(),
			primary_light: color3.trim(),
		};
	};
	const variableColors = getVariableColor();

	const colors = [variableColors.primary, variableColors.info];
	useEffect(() => {
		return () => colors;
	});
	useEffect(() => {
		AOS.init({
			startEvent: 'DOMContentLoaded',
			disable: function () {
				var maxWidth = 996;
				return window.innerWidth < maxWidth;
			},
			throttleDelay: 10,
			once: true,
			duration: 700,
			offset: 10,
		});
	});

	useEffect(() => {
		if (!dataDashboard) return;

		applyFilterInServices();
	}, [filterAllXFinished]);
	const applyFilterInServices = () => {
		const allServices = {
			Semanal: dataDashboard?.last12MonthsAbsencesInfoQuantityByMonth,
			Mensal: dataDashboard?.last12MonthsAbsencesInfoQuantityByMonth,
			Anual: dataDashboard?.last12MonthsAbsencesInfoQuantityByMonth,
		};

		var servicesFiltered = allServices[filterAllXFinished]?.map((data) => data);

		let servicesTemplate = monthsAllByMonth;

		if (filterAllXFinished === 'Anual') {
			setMonthsAllByMonth([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
			let servicesTemplate = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

			for (const key in servicesFiltered) {
				const month = servicesFiltered[key];
				servicesTemplate[month.month - 1] = month.quantity;
			}

			setMonthsAllByMonth(servicesTemplate);
		} else {
			// Converter as strings de data para objetos Date
			const dateObjects = allServices[filterAllXFinished].map((item) => ({
				...item,
				date: new Date(item.date),
			}));

			// Obter a data mais recente e a mais antiga na lista
			const minDate = new Date(Math.min(...dateObjects.map((item) => item.date)));
			const maxDate = new Date(Math.max(...dateObjects.map((item) => item.date)));

			// Criar uma lista de todas as datas no intervalo
			const allDates = [];
			for (
				let currentDate = minDate;
				currentDate <= maxDate;
				currentDate.setDate(currentDate.getDate() + 1)
			) {
				allDates.push(new Date(currentDate));
			}

			// Criar um objeto com as datas existentes e suas quantidades correspondentes
			const existingDates = dateObjects.reduce((acc, item) => {
				acc[item.date.toISOString().split('T')[0]] = item.quantity;
				return acc;
			}, {});

			// Preencher as datas faltantes com quantidade zero
			const filledData = allDates.map((date) => ({
				date: date.toISOString().split('T')[0],
				quantity: existingDates[date.toISOString().split('T')[0]] || 0,
			}));

			setMonthsAllByMonth(filledData.map((day) => day.quantity));
		}
	};

	const applyFilterInAbsenses = () => {
		var servicesFiltered = dataDashboard?.last12MonthsAbsencesInfoQuantityByMonth?.map(
			(data) => data
		);

		let servicesTemplate = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

		for (const key in servicesFiltered) {
			const month = servicesFiltered[key];
			servicesTemplate[month?.month - 1] = month?.quantity;
		}

		setCategoryDaysStockXAccumulated([
			'Jan',
			'Fev',
			'Mar',
			'Abr',
			'Mai',
			'Jun',
			'Jul',
			'Ago',
			'Set',
			'Out',
			'Nov',
			'Dez',
		]);
		setMonthsAbsensesByMonth(servicesTemplate);
	};

	const applyFilterInStock = () => {
		var servicesFiltered = dataDashboard?.last12MonthsHireQuantityByMonth?.map((data) => data);

		let servicesTemplate = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

		for (const key in servicesFiltered) {
			const month = servicesFiltered[key];
			servicesTemplate[month?.month - 1] = month?.quantity;
		}

		setCategoryDaysStockXAccumulated([
			'Jan',
			'Fev',
			'Mar',
			'Abr',
			'Mai',
			'Jun',
			'Jul',
			'Ago',
			'Set',
			'Out',
			'Nov',
			'Dez',
		]);
		setMonthsStockByMonth(servicesTemplate);
	};

	const pieOptions = {
		options: {
			chart: {
				type: 'donut',
			},
			labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
			legend: {
				show: false,
				position: 'left',
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				width: 1,
			},
			legend: {
				show: true,
				position: 'right',
			},
			responsive: [
				{
					breakpoint: 998,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							show: false,
						},
					},
				},
			],
		},
		series: [44, 55, 13, 43, 22],
	};

	return (
		<Fragment>
			<Row>
				<Col md='12' lg='12'>
					<Row className='row-cols-1'>
						<div className='overflow-hidden d-slider1 ' data-aos='fade-up' data-aos-delay='800'>
							<Swiper
								className='p-0 m-0 mb-2 list-inline '
								slidesPerView={5}
								spaceBetween={32}
								navigation={{
									nextEl: '.swiper-button-next',
									prevEl: '.swiper-button-prev',
								}}
								breakpoints={{
									320: { slidesPerView: 1 },
									550: { slidesPerView: 2 },
									991: { slidesPerView: 3 },
									1400: { slidesPerView: 3 },
									// 1500: { slidesPerView: 4 },
									// 1920: { slidesPerView: 4 },
									// 2040: { slidesPerView: 7 },
									// 2440: { slidesPerView: 8 },
								}}
							>
								<SwiperSlide className='card card-slide'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='bg-success text-white rounded p-3'>
												<svg
													width='22'
													viewBox='0 0 24 24'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														fillRule='evenodd'
														clipRule='evenodd'
														d='M9.59151 15.2068C13.2805 15.2068 16.4335 15.7658 16.4335 17.9988C16.4335 20.2318 13.3015 20.8068 9.59151 20.8068C5.90151 20.8068 2.74951 20.2528 2.74951 18.0188C2.74951 15.7848 5.88051 15.2068 9.59151 15.2068Z'
														stroke='currentColor'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path
														fillRule='evenodd'
														clipRule='evenodd'
														d='M9.59157 12.0198C7.16957 12.0198 5.20557 10.0568 5.20557 7.63476C5.20557 5.21276 7.16957 3.24976 9.59157 3.24976C12.0126 3.24976 13.9766 5.21276 13.9766 7.63476C13.9856 10.0478 12.0356 12.0108 9.62257 12.0198H9.59157Z'
														stroke='currentColor'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path
														d='M16.4829 10.8815C18.0839 10.6565 19.3169 9.28253 19.3199 7.61953C19.3199 5.98053 18.1249 4.62053 16.5579 4.36353'
														stroke='currentColor'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path
														d='M18.5952 14.7322C20.1462 14.9632 21.2292 15.5072 21.2292 16.6272C21.2292 17.3982 20.7192 17.8982 19.8952 18.2112'
														stroke='currentColor'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Colaboradores Ativos</p>
													<div>
														<CustomTooltip text='Total de atendimentos FINALIZADOS até hoje.' />
													</div>
												</div>
												<h4 className='counter'>
													<ReactPlaceholder
														type='text'
														rows={1}
														ready={!isLoading}
														showLoadingAnimation={true}
														color='#E0E0E0'
													>
														<CountUp start={0} end={dataDashboard?.activeEmployees} duration={1} />
													</ReactPlaceholder>
												</h4>
											</div>
										</div>
									</div>
								</SwiperSlide>

								<SwiperSlide className='card card-slide'>
									<div className='card-body'>
										<div className='progress-widget'>
											<div className='bg-info text-white rounded p-3'>
												<IconContext.Provider value={{ size: '22px' }}>
													<BsCurrencyDollar />
												</IconContext.Provider>
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Remuneração Média</p>
													<div>
														<CustomTooltip text='Total de atendimentos GERADOS nos últimos 30 dias' />
													</div>
												</div>

												<h4 className='counter d-flex'>
													<ReactPlaceholder
														type='text'
														rows={1}
														ready={!isLoading}
														showLoadingAnimation={true}
														color='#E0E0E0'
													>
														R$ {dataDashboard?.averageIncome}
													</ReactPlaceholder>
												</h4>
											</div>
										</div>
									</div>
								</SwiperSlide>

								<div className='swiper-button swiper-button-next'></div>
								<div className='swiper-button swiper-button-prev'></div>
							</Swiper>
						</div>
					</Row>
				</Col>

				<Row>
					<div className='card' data-aos='fade-up' data-aos-delay='800'>
						<div className='flex-wrap card-header d-flex justify-content-between'>
							<div
								className='header-title d-flex align-items-center'
								style={{ minHeight: '2.625rem' }}
							>
								<h5 className='card-title'>Contratações por mês</h5>
								<CustomTooltip
									text='Estoque: diferença entre total de atendimentos Gerados e Finalizados. Comparado com
															Acumulados: atendimentos atrasados'
								/>
							</div>
						</div>
						<div className='card-body'>
							<ReactPlaceholder
								type='rect'
								style={{ height: '250px' }}
								ready={!isLoading}
								showLoadingAnimation={true}
								color='#E0E0E0'
							>
								<Chart
									options={
										stockXAccumulated(
											[
												{
													name: 'Contratações',
													data: monthsStockByMonth,
												},
											],
											categoryDaysStockXAccumulated,
											'primary'
										).options
									}
									series={
										stockXAccumulated(
											[
												{
													name: 'Contratações',
													data: monthsStockByMonth,
												},
											],
											categoryDaysStockXAccumulated,
											'primary'
										).series
									}
									type='bar'
									height='245'
								/>
							</ReactPlaceholder>
						</div>
					</div>
				</Row>

				<Row>
					<section className='mb-4' data-aos='fade-up' data-aos-delay='800'>
						<h3 className='mb-4'>Análise de afastamentos</h3>

						<DualChartsSection>
							<Col>
								<div className='card'>
									<div className='flex-wrap card-header d-flex justify-content-between'>
										<div
											className='header-title d-flex align-items-center'
											style={{ minHeight: '2.625rem' }}
										>
											<h5 className='card-title'>Afastamentos por mês</h5>
											<CustomTooltip
												text='Estoque: diferença entre total de atendimentos Gerados e Finalizados. Comparado com
															Acumulados: atendimentos atrasados'
											/>
										</div>
									</div>
									<div className='card-body'>
										<ReactPlaceholder
											type='rect'
											style={{ height: '250px' }}
											ready={!isLoading}
											showLoadingAnimation={true}
											color='#E0E0E0'
										>
											<Chart
												options={
													stockXAccumulated(
														[
															{
																name: '',
																data: monthsAbsensesByMonth,
															},
														],
														categoryDaysStockXAccumulated,
														'warning'
													).options
												}
												series={
													stockXAccumulated(
														[
															{
																name: '',
																data: monthsAbsensesByMonth,
															},
														],
														categoryDaysStockXAccumulated,
														'warning'
													).series
												}
												type='bar'
												height='245'
											/>
										</ReactPlaceholder>
									</div>
								</div>
							</Col>
							<Col
								className='card card-slider'
								data-aos='fade-up'
								data-aos-delay='800'
								style={{ height: '350px', minWidth: '300px' }}
							>
								<ReactPlaceholder
									type='rect'
									style={{ height: '250px' }}
									ready={!isLoading}
									showLoadingAnimation={true}
									color='#E0E0E0'
								>
									<PiechartCard
										title='Causas de afastamento'
										tooltip='Causas de afastamentos de colaboradores'
										data={dataDashboard?.employeesAbsencesByReason}
										labelAttribute='reason'
										quantityAttribute='quantity'
									/>
								</ReactPlaceholder>
							</Col>
						</DualChartsSection>
					</section>

					<section data-aos='fade-up' data-aos-delay='800'>
						<h3 className='mb-4'>Alocação de colaboradores</h3>

						<DualChartsSection>
							<Col>
								<Card className='card card-slider' style={{ height: '350px', minWidth: '300px' }}>
									<PiechartCard
										title='Colaboradores por função'
										tooltip='Número de colaboradores por função'
										data={dataDashboard?.employeesByRole}
										labelAttribute='role'
										quantityAttribute='quantity'
									/>
								</Card>
							</Col>
							<Col>
								<Card
									className='card card-slider'
									data-aos='fade-up'
									data-aos-delay='800'
									style={{ height: '350px', minWidth: '300px' }}
								>
									<PiechartCard
										title='Colaboradores por produto'
										tooltip='Colaboradores por produto'
										data={dataDashboard?.employeesBySector}
										labelAttribute='sector'
										quantityAttribute='quantity'
									/>
								</Card>
							</Col>
						</DualChartsSection>
					</section>
					<div>
						{/* <h3>Análise de Desenvolvedores</h3>

						<Row style={{ gap: '1rem' }}>
							<Card style={{ maxWidth: '300px' }}>
								<Card.Body>
									<div className='d-flex justify-content-between align-items-center'>
										<div className='bg-info text-white rounded p-3'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='20px'
												height='20px'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
													d='M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z'
												/>
											</svg>
										</div>
										<div className='text-end'>
											Total de Horas Gastas
											<h2 className='counter'>
												<ReactPlaceholder
													type='text'
													rows={1}
													ready={!isLoading}
													showLoadingAnimation={true}
													color='#E0E0E0'
												>
													<CountUp start={15} end={dataDashboard?.aaaa ?? 0} duration={1} />
												</ReactPlaceholder>
											</h2>
										</div>
									</div>
								</Card.Body>
							</Card>

							<Card style={{ maxWidth: '300px' }}>
								<Card.Body>
									<div className='d-flex justify-content-between align-items-center'>
										<div className='bg-success text-white rounded p-3'>
											<svg
												width='22px'
												viewBox='0 0 24 24'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													fillRule='evenodd'
													clipRule='evenodd'
													d='M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z'
													stroke='currentColor'
													strokeWidth='1.5'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
											</svg>
										</div>
										<div className='text-end'>
											Total Concluído
											<h2 className='d-flex justify-content-end counter'>
												<ReactPlaceholder
													type='text'
													rows={1}
													ready={!isLoading}
													showLoadingAnimation={true}
													color='#E0E0E0'
												>
													{dataDashboard?.aaa ?? 0}
												</ReactPlaceholder>
											</h2>
										</div>
									</div>
								</Card.Body>
							</Card>

							<Card style={{ maxWidth: '300px' }}>
								<Card.Body>
									<div className='d-flex justify-content-between align-items-center'>
										<div className='bg-danger text-white rounded p-3'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='20px'
												viewBox='0 0 24 24'
												fill='none'
											>
												<path
													fill-rule='evenodd'
													clip-rule='evenodd'
													d='M21.419 15.732C21.419 19.31 19.31 21.419 15.732 21.419H7.95C4.363 21.419 2.25 19.31 2.25 15.732V7.932C2.25 4.359 3.564 2.25 7.143 2.25H9.143C9.861 2.251 10.537 2.588 10.967 3.163L11.88 4.377C12.312 4.951 12.988 5.289 13.706 5.29H16.536C20.123 5.29 21.447 7.116 21.447 10.767L21.419 15.732Z'
													stroke='currentColor'
													stroke-width='1.5'
													stroke-linecap='round'
													stroke-linejoin='round'
												/>
												<path
													d='M7.48145 14.4629H16.2164'
													stroke='currentColor'
													stroke-width='1.5'
													stroke-linecap='round'
													stroke-linejoin='round'
												/>
											</svg>
										</div>
										<div className='text-end'>
											Finalizados Totais
											<h2 className='d-flex justify-content-end counter'>
												<ReactPlaceholder
													type='text'
													rows={1}
													ready={!isLoading}
													showLoadingAnimation={true}
													color='#E0E0E0'
												>
													{dataDashboard?.aa ?? 0}
												</ReactPlaceholder>
											</h2>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Row> */}
						<Row className='flex-nowrap overflow-hidden'>
							<div className='overflow-hidden d-slider1'>
								<Swiper
									className='p-0 m-0 mb-2 list-inline '
									slidesPerView={2}
									spaceBetween={32}
									navigation={true}
									// centeredSlides={true}

									breakpoints={{
										320: { slidesPerView: 1 },
										550: { slidesPerView: 2 },
										991: { slidesPerView: 3 },
										1400: { slidesPerView: 3 },
										1500: { slidesPerView: 4 },
										1920: { slidesPerView: 4 },
										2040: { slidesPerView: 7 },
										2440: { slidesPerView: 8 },
									}}
								>
									{/* <SwiperSlide
										className='card card-slider'
										style={{ height: '300px', maxWidth: '900px' }}
									></SwiperSlide> */}

									{/* <SwiperSlide
										className='card card-slider'
										style={{ height: '300px', maxWidth: '900px' }}
									>
										<Card.Header>
											<h5>Por cliente</h5>
										</Card.Header>
										<Card.Body>
											<Chart
												options={pieOptions.options}
												series={pieOptions.series}
												type='pie'
												height='200'
											/>
										</Card.Body>
									</SwiperSlide>

									<SwiperSlide
										className='card card-slider'
										style={{ height: '300px', maxWidth: '900px' }}
									>
										<Card.Header>
											<h5>Colaboradores X Desenvolvedores</h5>
										</Card.Header>
										<Card.Body>
											<Chart
												options={pieOptions.options}
												series={pieOptions.series}
												type='pie'
												height='200'
											/>
										</Card.Body>
									</SwiperSlide> */}
								</Swiper>
							</div>
						</Row>
					</div>
				</Row>
			</Row>
		</Fragment>
	);
});

export default Rh;
