import React, { useEffect, useMemo, useState } from 'react';
import { api } from '../../../api/api';
//hooks
import { useQuery } from 'react-query';
import { useDebounce } from '@uidotdev/usehooks';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
//components
import { Button, Card, Col, Dropdown, Spinner } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import ReactPlaceholder from 'react-placeholder';
import CustomTooltip from '../../../components/customTooltip/CustomTooltip';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
SwiperCore.use([Navigation]);
import 'swiper/swiper-bundle.min.css';
import PiechartCard from '../../../components/piechartCard/piechartCard';
import columnLineData from '../../../utils/charts/columnLineData';
import { DualChartsSection } from '../style';
import columnChartData from '../../../utils/charts/columnChartData';

dayjs.locale('de');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');
const CallCenter = () => {
	const [yearFilter, setYearFilter] = useState(2023);
	const [totalYears, setTotalYears] = useState([]);
	const [servicesFilters, setServicesFilters] = useState({});
	const [startDate, setStartDate] = useState(new Date(`${new Date().getFullYear() - 1}-01-02`));
	const [endDate, setEndDate] = useState(new Date());
	// const fakeCallsByNumbers = [
	// 	{
	// 		number: '0800 000 0000',
	// 		quantity: 2500,
	// 	},
	// 	{
	// 		number: '0800 000 0000',
	// 		quantity: 3500,
	// 	},
	// 	{
	// 		number: '0800 000 0000',
	// 		quantity: 4500,
	// 	},
	// 	{
	// 		number: '0800 000 0000',
	// 		quantity: 5500,
	// 	},
	// 	{
	// 		number: '0800 000 0000',
	// 		quantity: 6500,
	// 	},
	// ];
	// const debouncedChart = useDebounce(fakeCallsByNumbers, 500);

	const { data: callsQuantities, refetch: refetchCalls } = useQuery('callsQuantities', {
		queryFn: () => getServices(),
		onError: (err) => console.log(err),
	});

	const getServices = async () => {
		const response = await api.get(`/calls/indices`);
		setTotalYears(Object.keys(response?.data));
		const services = response?.data[yearFilter];

		return services;
	};
	const handleOrganizeChart = (data) => {
		if (!data) return;

		const list = data.slice();
		for (let month = 1; month <= 12; month++) {
			if (!list.some((item) => item && new Date(item.month).getUTCMonth() + 1 === month)) {
				list.push({
					quantity: 0,
					month: dayjs(`${yearFilter}-${String(month).padStart(2, '0')}-01`).format(
						'YYYY-MM-DDTHH:mm:ssZ'
					),
				});
			}
		}
		const arr = list.filter((item) => item).sort((a, b) => new Date(a.month) - new Date(b.month));
		return arr.map((month) => month.quantity);
	};

	const averageCallTimeMinutes = (callsQuantities?.averageCallTime ?? 0) / 60;

	useEffect(() => {
		refetchCalls();
	}, [yearFilter]);

	useEffect(() => {
		console.log('Voc');
	});
	const callsBySector = [
		{
			product: 'Ilha 1',
			quantity: 10,
		},
		{
			product: 'Ilha 2',
			quantity: 20,
		},
		{
			product: 'Ilha 3',
			quantity: 15,
		},
		{
			product: 'Ilha 4',
			quantity: 10,
		},
		{
			product: 'Ilha 5',
			quantity: 30,
		},
	];

	return (
		<main>
			<section data-aos='fade-up' data-aos-delay='500'>
				<Swiper
					className='p-0 m-0 mb-2 list-inline '
					slidesPerView={5}
					spaceBetween={32}
					navigation={{
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					}}
					breakpoints={{
						320: { slidesPerView: 1 },
						550: { slidesPerView: 2 },
						991: { slidesPerView: 3 },
						1400: { slidesPerView: 3 },
						1500: { slidesPerView: 4 },
						1920: { slidesPerView: 5 },
					}}
				>
					<SwiperSlide className='card-slide card'>
						<div className='card-body'>
							<div className='progress-widget'>
								<div className='bg-info text-white rounded p-3'>
									<svg
										width='22px'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z'
											stroke='currentColor'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</div>
								<div className='progress-detail' style={{ width: '100%' }}>
									<div className='d-flex justify-content-between'>
										<p className='mb-2'>Média de ligações/Dia</p>
										<div>
											<CustomTooltip
												text={
													<div style={{ textAlign: 'left' }}>
														<p>Média de ligações/Dia no decorrer do ano</p>
														<p>Dados provenientes do NextIP e VozCxpress</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 className='counter'>
										<ReactPlaceholder
											type='text'
											rows={1}
											ready={true}
											showLoadingAnimation={true}
											color='#E0E0E0'
										>
											{callsQuantities?.averageCallByDay ?? 0}
										</ReactPlaceholder>
									</h4>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide className='card-slide card'>
						<div className='card-body'>
							<div className='progress-widget'>
								<div className='bg-danger text-white rounded p-3'>
									<svg
										width='22px'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z'
											stroke='currentColor'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</div>
								<div className='progress-detail' style={{ width: '100%' }}>
									<div className='d-flex justify-content-between'>
										<p className='mb-2'>Ligações perdidas/Dia</p>
										<div>
											<CustomTooltip
												text={
													<div style={{ textAlign: 'left' }}>
														<p>Ligações perdidas/Dia no decorrer do ano</p>
														<p>Dados provenientes do NextIP e VozCxpress</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 className='counter'>
										<ReactPlaceholder
											type='text'
											rows={1}
											ready={true}
											showLoadingAnimation={true}
											color='#E0E0E0'
										>
											0
										</ReactPlaceholder>
									</h4>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide className='card-slide card'>
						<div className='card-body'>
							<div className='progress-widget'>
								<div className='bg-primary text-white rounded p-3'>
									<svg
										width='22'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M21.25 12.0005C21.25 17.1095 17.109 21.2505 12 21.2505C6.891 21.2505 2.75 17.1095 2.75 12.0005C2.75 6.89149 6.891 2.75049 12 2.75049C17.109 2.75049 21.25 6.89149 21.25 12.0005Z'
											stroke='currentColor'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M15.4316 14.9429L11.6616 12.6939V7.84692'
											stroke='currentColor'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</div>
								<div className='progress-detail' style={{ width: '100%' }}>
									<div className='d-flex justify-content-between'>
										<p className='mb-2'>Tempo médio</p>
										<div>
											<CustomTooltip
												text={
													<div style={{ textAlign: 'left' }}>
														<p>Tempo médio das ligações no decorrer do ano</p>
														<p>Dados provenientes do NextIP e VozCxpress</p>
														<p>Não há dados sobre esse indicador no sistema</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 className='counter'>
										<ReactPlaceholder
											type='text'
											rows={1}
											ready={!false}
											showLoadingAnimation={true}
											color='#E0E0E0'
										>
											{`${averageCallTimeMinutes.toFixed(2)} minutos`}
										</ReactPlaceholder>
									</h4>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide className='card-slide card'>
						<div className='card-body'>
							<div className='progress-widget'>
								<div className='bg-warning text-white rounded p-3'>
									<svg
										width='22'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M21.25 12.0005C21.25 17.1095 17.109 21.2505 12 21.2505C6.891 21.2505 2.75 17.1095 2.75 12.0005C2.75 6.89149 6.891 2.75049 12 2.75049C17.109 2.75049 21.25 6.89149 21.25 12.0005Z'
											stroke='currentColor'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M15.4316 14.9429L11.6616 12.6939V7.84692'
											stroke='currentColor'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</div>
								<div className='progress-detail' style={{ width: '100%' }}>
									<div className='d-flex justify-content-between'>
										<p className='mb-2'>Espera média</p>
										<div>
											<CustomTooltip
												text={
													<div style={{ textAlign: 'left' }}>
														<p>Tempo médio das ligações em espera no decorrer do ano</p>
														<p>Dados provenientes do NextIP e VozCxpress</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 className='counter'>
										<ReactPlaceholder
											type='text'
											rows={1}
											ready={!false}
											showLoadingAnimation={true}
											color='#E0E0E0'
										>
											{`0 minutos`}
										</ReactPlaceholder>
									</h4>
								</div>
							</div>
						</div>
					</SwiperSlide>
				</Swiper>
			</section>

			<section data-aos='fade-up' data-aos-delay='700'>
				<Card>
					<Card.Header className='d-flex justify-content-between'>
						<div className='d-flex'>
							<h5 className='mr-1'>Atendimentos por ano</h5>
							<div>
								<CustomTooltip
									text={
										<div style={{ textAlign: 'left' }}>
											<p>Indices de atendimento em cada mês do ano</p>
											<p>Dados provenientes do NextIP e VozCxpress</p>
											<p>Não há dados no sistema para computar o indicador de nota média</p>
										</div>
									}
								/>
							</div>
						</div>
						<Dropdown>
							<b>Período:</b>
							<Dropdown.Toggle
								as={Button}
								variant='text-gray'
								type='button'
								id='dropdownMenuButtonSM'
							>
								{yearFilter}
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{totalYears.map((item) => (
									<Dropdown.Item onClick={() => setYearFilter(item)}>{item}</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					</Card.Header>
					<Card.Body>
						<Chart
							options={
								columnLineData(
									[
										{
											name: 'Ligações',
											type: 'line',
											data: handleOrganizeChart(callsQuantities?.callByMonth),
										},
										{
											name: 'Não respondida',
											type: 'line',
											data: handleOrganizeChart(callsQuantities?.notAnsweredByMonth),
										},
										{
											name: 'Nota média',
											type: 'column',
											data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
										},
										{
											name: 'Tempo médio',
											type: 'column',
											data: handleOrganizeChart(callsQuantities?.averageCallByDayPerMonth),
										},
									],
									[
										'Jan',
										'Fev',
										'Mar',
										'Abr',
										'Mai',
										'Jun',
										'Jul',
										'Ago',
										'Set',
										'Out',
										'Nov',
										'Dez',
									]
								).options
							}
							series={
								columnLineData(
									[
										{
											name: 'Ligações',
											type: 'line',
											data: handleOrganizeChart(callsQuantities?.callByMonth),
										},
										{
											name: 'Não respondida',
											type: 'line',
											data: handleOrganizeChart(callsQuantities?.notAnsweredByMonth),
										},
										{
											name: 'Nota média',
											type: 'column',
											data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
										},
										{
											name: 'Tempo médio',
											type: 'column',
											data: handleOrganizeChart(callsQuantities?.averageCallByDayPerMonth),
										},
									],
									[
										'Jan',
										'Fev',
										'Mar',
										'Abr',
										'Mai',
										'Jun',
										'Jul',
										'Ago',
										'Set',
										'Out',
										'Nov',
										'Dez',
									]
								).series
							}
							type='area'
							height='250'
						/>
					</Card.Body>
				</Card>
			</section>

			{/* Esperando aprovação */}
			{/* <section data-aos='fade-up' data-aos-delay='500'>
				<div className='d-flex gap-4'></div>
				<DualChartsSection>
					<Col>
						<DualChartsSection>
							<Col>
								<Card>
									<PiechartCard
										title='Por setor (ilha)'
										tooltip='Ligações por setor.'
										data={callsBySector}
										labelAttribute='product'
										quantityAttribute='quantity'
										legendPosition='bottom'
									/>
								</Card>
							</Col>

							<Col>
								<Card>
									<PiechartCard
										title='Por ramal'
										tooltip='Ligações por ramal.'
										data={callsBySector}
										labelAttribute='product'
										quantityAttribute='quantity'
										legendPosition='bottom'
									/>
								</Card>
							</Col>
						</DualChartsSection>
					</Col>

					<Col>
						<Card>
							<Card.Header>
								<h5 className='mb-4'>Ligações por número</h5>
							</Card.Header>
							<Card.Body>
								<LazyChart
									debouncedChart={debouncedChart}
									data={{
										callsByNumbers: fakeCallsByNumbers,
									}}
									loadings={false}
								/>
							</Card.Body>
						</Card>
					</Col>
				</DualChartsSection>
			</section> */}
		</main>
	);
};

const LazyChart = ({ debouncedChart, data, loadings }) =>
	useMemo(
		() =>
			!loadings ? (
				<Chart
					options={columnChartData(data.callsByNumbers, 'number', true).options}
					series={columnChartData(data.callsByNumbers, 'number', true).series}
					type='bar'
					height='250'
				/>
			) : (
				<Spinner />
			),
		[debouncedChart, data]
	);

export default CallCenter;
