import React, { useEffect, useState } from 'react';
import { Form } from 'react-router-dom';
import InputMask from '@mona-health/react-input-mask';

/**
 * Renders a input masked for CPF or CNPJ.
 *
 * @param _
 * @param {Object} children - Element child.
 * @param {string} value - The input value.
 * @param {Function} onChange - The function to handle onChange event // react-hook-form.
 * @param {boolean} disabled - A flag to disable the mask input // react-hook-form.
 * @return {JSX.Element} InputMask component with masked value.
 */
export const CpfMask = ({ children, value, onChange, disabled }) => {
	const [mask, setMask] = useState('999.999.999-999');
	useEffect(() => {
		if ((value || '').toString().replace(/\D/g, '').length > 11) {
			setMask('99.999.999/9999-99');
			return;
		}
		setMask('999.999.999-99999');
	}, [value]);

	return (
		<InputMask
			mask={mask}
			onChange={onChange}
			disabled={disabled}
			maskPlaceholder={null}
			value={value}
		>
			{children}
		</InputMask>
	);
};
