import React, { useEffect, useState } from 'react';
//hooks
import { usePagination } from '@table-library/react-table-library/pagination';
import { useTheme } from '@table-library/react-table-library/theme';
//styles
import { TableWrapper } from './style';
//components
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
	Cell,
} from '@table-library/react-table-library/table';
import { Card } from 'react-bootstrap';
import Searchbox from '../../../components/searchbox/searchbox';
import { Pagination } from '../../../components/table/pagination';

const CallsDetails = () => {
	const [dataTable, setDataTable] = useState();
	const limitTable = 5;

	const calls = {
		nodes: [
			{
				id: 1,
				name: 'Carlos Siqueira',
				number: '11 99999-9999',
				averageDuration: '01:00',
				quantity: 15,
			},
			{
				name: 'Carlos Siqueira',
				number: '82 99999-9999',
				averageDuration: '01:00',
				quantity: 15,
			},
			{
				name: 'Carlos Siqueira',
				number: '12 99999-9999',
				averageDuration: '01:00',
				quantity: 15,
			},
			{
				name: 'Carlos Siqueira',
				number: '12 99999-9999',
				averageDuration: '01:00',
				quantity: 15,
			},
			{
				name: 'Carlos Siqueira',
				number: '12 99999-9999',
				averageDuration: '01:00',
				quantity: 15,
			},
			{
				name: 'Carlos Siqueira',
				number: '12 99999-9999',
				averageDuration: '01:00',
				quantity: 15,
			},
			{
				name: 'Carlos Siqueira',
				number: '12 99999-9999',
				averageDuration: '01:00',
				quantity: 15,
			},
			{
				name: 'Carlos Siqueira',
				number: '12 99999-9999',
				averageDuration: '01:00',
				quantity: 15,
			},
			{
				name: 'Carlos Siqueira',
				number: '12 99999-9999',
				averageDuration: '01:00',
				quantity: 15,
			},
			{
				name: 'Carlos Siqueira',
				number: '12 99999-9999',
				averageDuration: '01:00',
				quantity: 15,
			},
			{
				name: 'Carlos Siqueira',
				number: '12 99999-9999',
				averageDuration: '01:00',
				quantity: 15,
			},
			{
				name: 'Carlos Siqueira',
				number: '12 99999-9999',
				averageDuration: '01:00',
				quantity: 15,
			},
			{
				name: 'Carlos Siqueira',
				number: '12 99999-9999',
				averageDuration: '01:00',
				quantity: 15,
			},
			{
				name: 'Carlos Siqueira',
				number: '12 99999-9999',
				averageDuration: '01:00',
				quantity: 15,
			},
			{
				name: 'Carlos Siqueira',
				number: '12 99999-9999',
				averageDuration: '01:00',
				quantity: 15,
			},
		],
	};

	useEffect(() => {
		setDataTable(calls);
	});
	const pagination = usePagination(calls, {
		state: {
			page: 0,
			size: limitTable,
		},
	});
	const theme = useTheme({
		BaseCell: `
			&:nth-of-type(1) {
				left: 0px;
			}`,
	});
	return (
		<Card>
			<Card.Header className='d-flex justify-content-between'>
				<div className='d-flex gap-4 align-items-center'>
					<h5 className='card-title'>Ligações</h5>
					<Searchbox list={calls.nodes} setResult={setDataTable} atributeToSearch={'number'} />
				</div>
			</Card.Header>
			<Card.Body>
				{Array.isArray(dataTable?.nodes) && (
					<TableWrapper>
						<Table
							id='table-calls-details'
							responsive
							data={dataTable}
							pagination={pagination}
							theme={theme}
							className='table'
							role='grid'
							data-toggle='data-table'
						>
							{(tableList) => (
								<>
									<Header>
										<HeaderRow>
											<HeaderCell sortKey='name'>Nome</HeaderCell>
											<HeaderCell sortKey='number'>Número</HeaderCell>
											<HeaderCell sortKey='averageDuration'>Duração média</HeaderCell>
											<HeaderCell sortKey='quantity'>Quantidade de ligações</HeaderCell>
										</HeaderRow>
									</Header>
									<Body>
										{tableList.map((call, index) => (
											<>
												<Row className='pointer' item={call}>
													<Cell>{call.name}</Cell>
													<Cell>{call.number}</Cell>
													<Cell>{call.averageDuration}</Cell>
													<Cell>{call.quantity}</Cell>
												</Row>
											</>
										))}
									</Body>
								</>
							)}
						</Table>
						<Pagination data={calls} pagination={pagination} />
					</TableWrapper>
				)}
			</Card.Body>
		</Card>
	);
};

export default CallsDetails;
