export const columnNegativeData = (data, format) => {
	const months = [
		'Jan',
		'Fev',
		'Mar',
		'Abr',
		'Maio',
		'Jun',
		'Jul',
		'Ago',
		'Set',
		'Out',
		'Nov',
		'Dez',
	];

	const moneyFormat = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	});

	const columnNegative = {
		series: [
			{
				name: 'Valor gasto',
				data: data,
			},
		],
		options: {
			chart: {
				type: 'bar',
				height: 350,
			},
			plotOptions: {
				bar: {
					borderRadius: 4,
					horizontal: true,
					colors: {
						ranges: [
							{
								from: -Infinity,
								to: -1,
								color: '#FC5A5A',
							},
							{
								from: 1,
								to: Infinity,
								color: '#7EB02C',
							},
						],
					},
					columnWidth: '80%',
				},
			},
			dataLabels: {
				enabled: true,
				style: {
					fontWeight: 'bold',
					colors: ['#00000080'],
				},
				noData: {
					text: 'Nenhum dado encontrado!',
					align: 'center',
					verticalAlign: 'middle',
					offsetX: 0,
					offsetY: 0,
					style: {
						color: undefined,
						fontSize: '16px',
						fontFamily: undefined,
					},
				},
				formatter: function (val) {
					return moneyFormat.format(val);
				},
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return format === 'money' ? moneyFormat.format(val) : val;
					},
				},
			},
			yaxis: {
				categories: months.slice(0, data.length),
			},
			xaxis: {
				categories: months.slice(0, data.length),
				labels: {
					show: false,
					rotate: -90,
				},
			},
		},
	};

	return columnNegative;
};
