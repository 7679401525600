import React, { memo, useState, useEffect } from 'react';
import { useSessionStorage } from '@uidotdev/usehooks';
//icons
import { FaBoxes } from 'react-icons/fa';
//components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
SwiperCore.use([Navigation]);
import { Card, Col, Row } from 'react-bootstrap';
import ServicesByLastYears from '../../../components/servicesLast3Years/servicesLast3Years';
import CardWithGrowth from '../../../components/cardWithGrowth/CardWithGrowth';
import ServicesByYear from '../../../components/servicesByYear/servicesByYear';
import ChartFilters from '../../../components/chartFilters/chartFilters';
import { EmployeesByTypes } from '../../../components/employeesByTypes/EmployeesByTypes';
import { DualChartsSection } from '../style';
import { ServicesByEmployeesTypes } from '../../../components/servicesByEmployeesTypes/servicesByEmployeesTypes';

const ServicesOverview = memo((props) => {
	const [isLoading, setLoading] = useSessionStorage('isLoading', false);
	const [servicesQuantities, setServicesQuantities] = useState([]);
	const [queryMethods, setQueryMethods] = useState();
	const [servicesFilters, setServicesFilters] = useState({});
	const [servicesChannelFilter, setServicesChannelFilter] = useState('Todos');
	const [servicesProductFilter, setServicesProductFilter] = useState('Todos');
	const [startDateDisplay, setStartDateDisplay] = useState();
	const [endDateDisplay, setEndDateDisplay] = useState();

	//filtros das análise de atendimentos
	useEffect(() => {
		queryMethods?.refetch();
	}, [servicesChannelFilter, servicesProductFilter]);

	useEffect(() => {
		console.log(servicesFilters);
	}, [servicesFilters]);
	return (
		<>
			<section data-aos='fade-up' data-aos-delay='500'>
				<ServicesByLastYears />
			</section>

			<section data-aos='fade-up' data-aos-delay='600'>
				<Card>
					<Card.Header className='d-flex'>
						<h5>Análise de atendimentos</h5>
					</Card.Header>
					<Card.Body>
						<ChartFilters
							period
							product
							channel
							channelFilter={servicesChannelFilter}
							productFilter={servicesProductFilter}
							setProduct={setServicesProductFilter}
							filtersController={servicesFilters}
							setChannel={setServicesChannelFilter}
							setFiltersController={setServicesFilters}
							data={'servicesQuantities'}
							setData={setServicesQuantities}
							setStartDateDisplay={setStartDateDisplay}
							setEndDateDisplay={setEndDateDisplay}
							setQueryMethods={setQueryMethods}
							query={'/services/quantities'}
						/>
					</Card.Body>
				</Card>

				<Swiper
					className='p-0 m-0 mb-2 list-inline '
					slidesPerView={5}
					spaceBetween={32}
					navigation={{
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					}}
					breakpoints={{
						320: { slidesPerView: 1 },
						550: { slidesPerView: 2 },
						991: { slidesPerView: 3 },
						1400: { slidesPerView: 3 },
						1500: { slidesPerView: 4 },
						1920: { slidesPerView: 5 },
					}}
				>
					<SwiperSlide>
						<CardWithGrowth
							title='Atendimentos Totais'
							tooltip={
								<div style={{ textAlign: 'left' }}>
									<p>Total de atendimentos</p>
								</div>
							}
							colorIcon={'info'}
							icon={
								<svg width='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z'
										stroke='currentColor'
										strokeWidth='1.5'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							}
						>
							<CardWithGrowth.Body isLoading={queryMethods?.isFetching}>
								{servicesQuantities?.totalServicesQuantity}
							</CardWithGrowth.Body>
						</CardWithGrowth>
					</SwiperSlide>
					<SwiperSlide>
						<CardWithGrowth
							title='Finalizados Totais'
							tooltip={
								<div style={{ textAlign: 'left' }}>
									<p>O total de atendimentos finalizados</p>
								</div>
							}
							isLoading={isLoading}
							colorIcon={'primary'}
							icon={
								<svg width='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z'
										stroke='currentColor'
										strokeWidth='1.5'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							}
						>
							<CardWithGrowth.Body isLoading={queryMethods?.isFetching}>
								{servicesQuantities?.finishedServicesQuantity}
							</CardWithGrowth.Body>
						</CardWithGrowth>
					</SwiperSlide>
					<SwiperSlide>
						<CardWithGrowth
							title='Estoque Totais'
							tooltip={
								<div style={{ textAlign: 'left' }}>
									<p>Calculado com: total de atendimentos - atendimentos finalizados.</p>
									<p>*Filtros aplicados afetam o resultado final.</p>
								</div>
							}
							isLoading={isLoading}
							colorIcon={'danger'}
							icon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='20px'
									viewBox='0 0 24 24'
									fill='none'
								>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M21.419 15.732C21.419 19.31 19.31 21.419 15.732 21.419H7.95C4.363 21.419 2.25 19.31 2.25 15.732V7.932C2.25 4.359 3.564 2.25 7.143 2.25H9.143C9.861 2.251 10.537 2.588 10.967 3.163L11.88 4.377C12.312 4.951 12.988 5.289 13.706 5.29H16.536C20.123 5.29 21.447 7.116 21.447 10.767L21.419 15.732Z'
										stroke='currentColor'
										stroke-width='1.5'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
									<path
										d='M7.48145 14.4629H16.2164'
										stroke='currentColor'
										stroke-width='1.5'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							}
						>
							<CardWithGrowth.Body isLoading={queryMethods?.isFetching}>
								{servicesQuantities?.totalServicesStock}
							</CardWithGrowth.Body>
						</CardWithGrowth>
					</SwiperSlide>
					<SwiperSlide>
						<CardWithGrowth
							title='Total acumulado'
							tooltip={
								<div style={{ textAlign: 'left' }}>
									<p>É o estoque acumulado, ou seja, segue o cálculo:</p>
									<p>total de atendimentos - atendimentos finalizados</p>
									<p>porém filtros de período não afetam o resultado final.</p>
								</div>
							}
							isLoading={isLoading}
							colorIcon={'secondary'}
							icon={<FaBoxes />}
						>
							<CardWithGrowth.Body isLoading={queryMethods?.isFetching}>
								{servicesQuantities?.totalAccumulated}
							</CardWithGrowth.Body>
						</CardWithGrowth>
					</SwiperSlide>
				</Swiper>

				<DualChartsSection>
					<Col>
						<ServicesByEmployeesTypes
							startDateDisplay={startDateDisplay}
							endDateDisplay={endDateDisplay}
							filters={servicesFilters}
							channel={servicesChannelFilter}
							product={servicesProductFilter}
						/>
					</Col>
					<Col>
						<ServicesByYear />
					</Col>
				</DualChartsSection>
			</section>
		</>
	);
});

export default ServicesOverview;
