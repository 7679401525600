import styled from 'styled-components';

export const GoBack = styled.div`
	width: max-content;
	padding: 0.5rem;

	border-radius: 0.5rem;
	
	font-size: 1.25rem;

	${({ step }) => step !== 1 && 'cursor: pointer;'}
	
	transition: all 0.2s ease-in-out;
	:hover{
		background-color: var(--bs-primary-tint-90) !important;
		color: var(--bs-primary-shade-60) !important;
	}
`;
