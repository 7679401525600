import React, { useState, useEffect, lazy, memo, useMemo } from 'react';
import { api } from '../../api/api';

//hooks
import { useDebounce } from '@uidotdev/usehooks';
//charts
import Chart from 'react-apexcharts';
// AOS animation
import AOS from 'aos';
import 'aos';
import '../../../node_modules/aos/dist/aos.css';
//components
import { Card, Modal, Spinner } from 'react-bootstrap';
import CustomTooltip from '../customTooltip/CustomTooltip';
import ReactPlaceholder from 'react-placeholder';
import columnChartData from '../../utils/charts/columnChartData';
import ChartFilters from '../chartFilters/chartFilters';
const StockByYear = lazy(() => import('../stockByYear/stockByYear'));

const ServicesByYear = memo(({ client }) => {
	const [clientId, setClientId] = useState(0);
	const [servicesYearsQuantities, setServicesYearsQuantities] = useState();
	const debouncedChart = useDebounce(servicesYearsQuantities, 500);
	const [queryMethods, setQueryMethods] = useState();
	//Charts filters
	const [servicesChannelFilter, setServicesChannelFilter] = useState('Todos');
	const [servicesProductFilter, setServicesProductFilter] = useState('Todos');
	const [servicesFilters, setServicesFilters] = useState({});
	//functional
	const [showStockByYears, setShowStock] = useState();
	const debouncedShowStock = useDebounce(showStockByYears, 500);
	const [showYears, setShowYears] = useState({ year: '', show: false });

	//UseEffects==========
	useEffect(() => {
		AOS.init({
			startEvent: 'DOMContentLoaded',
			disable: function () {
				var maxWidth = 996;
				return window.innerWidth < maxWidth;
			},
			throttleDelay: 10,
			once: true,
			duration: 700,
			offset: 10,
		});
	});
	useEffect(() => {
		setClientId(client?.id);
	}, [client]);
	useEffect(() => {
		setShowStock(showYears?.show);
	}, [showYears]);

	return (
		<>
			<Card style={{ width: '100%', minWidth: '300px' }} data-aos='fade-up' data-aos-delay='800'>
				<Card.Header className='d-flex flex-wrap justify-content-between'>
					<div className='d-flex'>
						<h5>Atendimentos por ano</h5>
						<CustomTooltip
							text={
								<div style={{ textAlign: 'left' }}>
									<p>Clique em uma coluna para expandir</p>
								</div>
							}
						/>
					</div>

					<ChartFilters
						product
						channel
						channelFilter={servicesChannelFilter}
						productFilter={servicesProductFilter}
						setProduct={setServicesProductFilter}
						setChannel={setServicesChannelFilter}
						filtersController={servicesFilters}
						setFiltersController={setServicesFilters}
						data={'servicesYearsQuantities'}
						setData={setServicesYearsQuantities}
						setQueryMethods={setQueryMethods}
						query={'/services/quantities/year'}
					/>
				</Card.Header>

				<Card.Body className='overflow-hidden'>
					<div>
						<div style={{ position: 'relative' }}>
							<div
								className='card-body'
								style={{
									position: 'relative',
									filter: queryMethods?.isRefetching ? 'blur(5px)' : 'none',
								}}
							>
								<LazyChart
									debouncedChart={debouncedChart}
									data={{
										servicesYearsQuantities: servicesYearsQuantities?.servicesQuantityByYear
											.map((service) => {
												return {
													date: new Date(service.date).getFullYear(),
													quantity: Number(service.quantity),
												};
											})
											.reverse(),
										setShowYears,
									}}
									loadings={queryMethods?.isLoading}
								/>
							</div>
							{queryMethods?.isRefetching ? (
								<Spinner
									variant='primary'
									style={{ position: 'absolute', left: '50%', top: '50%' }}
								/>
							) : null}
						</div>
					</div>
				</Card.Body>
			</Card>

			<Modal
				size='lg'
				show={showYears.show}
				className='back-blur'
				onHide={() => setShowYears({ year: '', show: false })}
			>
				<Modal.Header closeButton>
					<Modal.Title className='d-flex align-items-center'>Acompanhamento do ano</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{debouncedShowStock ? (
						<StockByYear initialYear={showYears?.selected} />
					) : (
						<div className='d-flex w-100 h-100 justify-content-center mt-4 mb-4'>
							<Spinner />
						</div>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
});

const LazyChart = ({ debouncedChart, data, loadings }) =>
	useMemo(
		() => (
			<ReactPlaceholder
				type='rect'
				style={{ height: '300px' }}
				ready={!loadings}
				showLoadingAnimation={true}
				color='#E0E0E0'
			>
				{data.servicesYearsQuantities ? (
					<Chart
						options={
							columnChartData(data.servicesYearsQuantities, 'date', false, data.setShowYears)
								.options
						}
						series={
							columnChartData(data.servicesYearsQuantities, 'date', false, data.setShowYears).series
						}
						type='bar'
						height='350'
					/>
				) : null}
			</ReactPlaceholder>
		),
		[debouncedChart, data]
	);

export default ServicesByYear;
