import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const DocApi = () => {
	return <ReactMarkdown>{markdown}</ReactMarkdown>;
};

export default DocApi;

const markdown = `# Documentação da API do DataHub

Bem-vindo à documentação da API do DataHub, uma plataforma desenvolvida pela [SAS - Soluções em Gestão de Projetos](https://sasgp.com.br/).

## URLs da API

- Ambiente de Desenvolvimento: [https://dev.api.datahub.sasgp.com.br](https://dev.api.datahub.sasgp.com.br/)
- Ambiente de Produção: [https://api.datahub.sasgp.com.br/](https://api.datahub.sasgp.com.br/)

## Rotas da API

### Rota Base

- **Rota:** ${'`/indices/`'}
- **Descrição:** Raiz da API que fornece acesso a todos os índices gerados.

### Visões
As rotas da API são baseadas nas visões que temos no frontend, ou seja, para cada página há uma rota na API que retorna todos os dados necessários para alimentar a página.

#### Página Home

- **Rota:** ${'`/indices/home`'}
- **Descrição:** Fornece acesso aos indicadores relacionados à página inicial.

#### Página RH (Recursos Humanos)

- **Rota:** ${'`/indices/hr`'}
- **Descrição:** Fornece acesso aos indicadores relacionados à página de Recursos Humanos.

#### Página Desenvolvedor

- **Rota:** ${'`/indices/dev`'}
- **Descrição:** Fornece acesso aos indicadores relacionados à página de Desenvolvedor.

### Exemplo de Uso

Para obter os indicadores da Página Home, você pode fazer uma solicitação GET para:

${`http
GET https://api.datahub.sasgp.com.br/indices/home`}

### Respostas da API

A API do DataHub retorna dados no formato JSON. Certifique-se de lidar adequadamente com os códigos de status e as estruturas de resposta.

#### Códigos de Status Comuns

### Respostas da API

A API do DataHub retorna dados no formato JSON. Certifique-se de lidar adequadamente com os códigos de status e as estruturas de resposta.

#### Códigos de Status Comuns

- <span style="color:green">**200 OK:**</span> A solicitação foi bem-sucedida.
- <span style="color:orange">**401 Unauthorized:**</span> Falha na autenticação.
- <span style="color:red">**404 Not Found:**</span> Recurso não encontrado.
- <span style="color:red">**500 Internal Server Error:**</span> Erro interno do servidor.
`;
