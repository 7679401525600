import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/animations/loading.json';

import { Container } from 'react-bootstrap';

const LoadingPage = () => {
	const options = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<div className='d-flex justify-content-center align-items-center'>
			<div className='d-flex' style={{ height: '380px', width: '900px' }}>
				<Container
					className='d-flex justify-content-center align-items-center'
					style={{ gap: '1rem', textAlign: 'left' }}
				>
					<Lottie options={options} height={200} width={200} className='mb-4' alt='' />
				</Container>
			</div>
		</div>
	);
};

export { LoadingPage };
