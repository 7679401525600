import styled from 'styled-components';

export const TableWrapper = styled.div`
table{
	--data-table-library_grid-template-columns:
			max-content
			max-content
			max-content
			max-content
			max-content
			max-content
			max-content
			max-content
			max-content !important;
}
	td {
		max-width: 600px;
		text-align: center;
		white-space: wrap;
	}
`;

export const ModalWrapper = styled.div`
	.modal-dialog{
		max-width: max-content !important;
	}
`;
