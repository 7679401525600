import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';

//components
import { BsBookHalf } from 'react-icons/bs';
import { roles } from '../../../../utils/types/rolesTypes';

const HeaderStyle4 = () => {
	//const userRole = jwtDecode(JSON.parse(localStorage.getItem('@datahub-token'))?.token).permission;

	return (
		<>
			<Navbar
				bg='dark'
				expand='lg'
				variant='dark'
				className='fixed-top iq-navbar'
				aria-label='Main navigation'
			>
				<Container fluid>
					<Link className='navbar-brand d-flex align-items-center'>
						<BsBookHalf />
						<h4 className='logo-title text-light ms-3 mb-0'>
							{'<'}DOCUMENTAÇÃO DATAHUB {'/>'}
						</h4>
					</Link>
					<Navbar.Toggle
						className='p-0 border-0'
						aria-controls='navbarSideCollapse'
						aria-label='Toggle navigation'
					>
						<span className='navbar-toggler-icon'>
							<span className='navbar-toggler-bar bar1 mt-2'></span>
							<span className='navbar-toggler-bar bar2'></span>
							<span className='navbar-toggler-bar bar3'></span>
						</span>
					</Navbar.Toggle>
					<Navbar.Collapse className='navbar-collapse offcanvas-collapse open'>
						<Nav as='ul' className=' navbar-nav me-auto mb-2 mb-lg-0 align-items-center'>
							<Nav.Item as='li'>
								<Nav.Link active aria-current='page' href='#'>
									Front-end
								</Nav.Link>
							</Nav.Item>
							<Nav.Item as='li'>
								<Nav.Link href='#'>Back-end</Nav.Link>
							</Nav.Item>
						</Nav>
						<form className='d-flex'>
							<input
								className='form-control me-2'
								type='search'
								placeholder='Search'
								aria-label='Search'
							/>
							<button className='btn btn-primary' type='submit'>
								Search
							</button>
						</form>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
};

export default HeaderStyle4;
