import styled from 'styled-components';

export const DualChartsSection = styled.section`
	display: flex;
	flex-wrap: nowrap;

	gap: 1rem;
	
	@media (max-width: 992px) {
		flex-wrap: wrap;
	}
`;
