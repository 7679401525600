const useCache = () => {
	const clearStorages = () => {
		const token = localStorage.getItem('@datahub-token');
		localStorage.clear();
		sessionStorage.clear();

		if (token) localStorage.setItem('@datahub-token', token);
	};

	return { clearStorages };
};

export default useCache;
