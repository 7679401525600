import { themes } from './themes';

const moneyFormat = new Intl.NumberFormat('pt-BR', {
	style: 'currency',
	currency: 'BRL',
});

const columnLineData = (series, categories, maxLength, theme, ticks, format) => {
	const columnLineData = {
		options: {
			chart: {
				toolbar: {
					show: true,
					offsetX: 0,
					offsetY: 0,
					tools: {
						selection: true,
						zoom: true,
						zoomin: true,
						zoomout: true,
						download: false,
						pan: false,
						reset: true | '<img src="/static/icons/reset.png" width="20">',
					},

					autoSelected: 'zoom',
				},
			},
			stroke: {
				width: [4, 4, 3, 3],
				fill: {
					type: 'solid', // Use 'solid' para cores sólidas nas colunas
				},
			},
			colors: themes[theme] || themes.secondary,
			fill: {
				opacity: [1],
				gradient: {
					inverseColors: false,
					shade: 'light',
					type: 'vertical',
					opacityFrom: 1,
					opacityTo: 1,
					stops: [100, 100, 100, 100],
				},
			},
			dataLabels: {
				enabled: false,
				enabledOnSeries: [1],
			},
			tooltip: {
				y: {
					formatter: function (val) {
						console.log(format);
						return format === 'money' ? moneyFormat.format(val) : val;
					},
				},
			},
			noData: {
				text: 'Nenhum dado encontrado!',
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: undefined,
					fontSize: '16px',
					fontFamily: undefined,
				},
			},
			xaxis: {
				tickAmount: ticks || 'dataPoints',
				max: maxLength,
			},

			labels: categoriesLabels(categories),
			// xaxis: {
			// 	type: 'datetime',
			// },
		},
		series: series,
	};

	return columnLineData;
};

const categoriesLabels = (categories) => {
	let cat = categories;
	let max = 3;

	if (categories.length < 12 && categories.length > 6) {
		const diasAbreviados = categories.map((data) => {
			const dataObj = new Date(data);
			const diasDaSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
			const nomeDiaAbreviado = diasDaSemana[dataObj.getDay() === 6 ? 0 : dataObj.getDay() + 1];

			max = 7;
			return nomeDiaAbreviado;
		});
		cat = diasAbreviados;
	}
	if (categories.length > 12) {
		const newDates = categories.map((date) => {
			const [ano, mes, dia] = date.split('-');
			// return `${dia}/${mes}`;
			max = 30;
			return `${dia}/${mes}`;
		});
		cat = newDates;
	}
	//Caso seja o filtro com os anos
	if (Number.isInteger(cat[0])) {
		cat.sort((a, b) => a - b);
		max = 3;
	}

	return cat;
};

export default columnLineData;
