import React, { forwardRef, useEffect, useState } from 'react';
import { Form } from 'react-router-dom';
import InputMask from '@mona-health/react-input-mask';

/**
 * Renders a input masked for CPF or CNPJ.
 *
 * @param _
 * @param {Object} children - Element child.
 * @param {string} value - The input value.
 * @param {Function} onChange - The function to handle onChange event // react-hook-form.
 * @param {boolean} disabled - A flag to disable the mask input // react-hook-form.
 * @return {JSX.Element} InputMask component with masked value.
 */
export const DateMask = forwardRef(
	({ children, value, onClick, onChange, disabled, setDisplayValue }, ref) => {
		const [mask, setMask] = useState('99/99/9999');

		useEffect(() => {
			if (!setDisplayValue) return;
			setDisplayValue(value);
		}, [value]);

		return (
			<InputMask
				mask={mask}
				onChange={onChange}
				disabled={disabled}
				maskPlaceholder={null}
				value={value}
				onClick={onClick}
				ref={ref}
			>
				{children}
			</InputMask>
		);
	}
);
