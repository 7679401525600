import React, { useState } from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import jwtDecode from 'jwt-decode';
import { roles } from '../../../utils/types/rolesTypes';

// img
import error404 from '../../../assets/images/error/404.png';
import notFound from '../../../assets/images/error/undraw_blank_404.svg';
import useAuth from '../../../hooks/useAuth';

const Error404 = () => {
	const { isLogged } = useAuth();

	const userRole = jwtDecode(JSON.parse(localStorage.getItem('@datahub-token'))?.token).permission;

	return (
		<>
			<div className='d-flex justify-content-center align-items-center'>
				<div className='d-flex' style={{ height: '380px', width: '900px' }}>
					<Container
						className='d-flex justify-content-center align-items-center'
						style={{ gap: '1rem', textAlign: 'left' }}
					>
						<Image src={notFound} style={{ maxWidth: '150px' }} className='img-fluid mb-4' alt='' />
						<p>
							<h2 className='mb-0 mt-4 mb-4'>
								<b>Oops! Página Ainda em desenvolvimento.</b>
							</h2>
							<h6 className='mb-0 mt-4 mb-4 text-muted'>
								Pode ser que a página que esta procurando ainda esteja em desenvolvimento, mas será
								disponibilizada em breve!
							</h6>
							<Link
								className='btn bg-white text-primary d-inline-flex align-items-center'
								to={isLogged ? roles[userRole].index : '/'}
							>
								Voltar para o início
							</Link>
						</p>
					</Container>
				</div>
			</div>
		</>
	);
};

export default Error404;
