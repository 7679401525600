export const productTypes = {
	38: 'B.I COPLAN',
	37: 'Marketing',
	36: 'Genexus',
	33: 'Infraestrura',
	32: 'IPM',
	31: 'Business Inteligence',
	28: 'SigEduca',
	27: 'Cliente +',
	23: 'Protocolo',
	22: 'Transparência',
	19: 'Atendimento',
	15: 'Ouvidoriа',
	14: 'Planejamento',
	13: 'APLIC',
	11: 'RH',
	8: 'Educação',
	5: 'Central',
	3: 'Administrativo',
	2: 'Contábil',
	1: 'Tributário',
};
