import React, { useState, useEffect } from 'react';
//hooks
import useAuth from '../../../hooks/useAuth';
//styles
import error from '../../../assets/images/error/undraw_cancel_errorGeneric.svg';
import { ErrorDetails } from './style';
import { MdOutlineFileCopy } from 'react-icons/md';
//components
import { Button, Image } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { ArrowExpansion } from '../../../components/table/arrowExpansion';
import useCache from '../../../hooks/useClearCache';

const ErrorBoundaryPage = ({ genericError, resetErrorBoundary }) => {
	const { logout } = useAuth();
	const { clearStorages } = useCache();
	const [showError, setShowError] = useState(false);

	const handleLogout = () => {
		clearStorages();
		logout();
	};
	const refresh = () => {
		clearStorages();
		window.location.reload();
	};

	const copyError = async () => {
		try {
			await navigator.clipboard.writeText(genericError?.message);
			toast.success('Texto copiado');
		} catch (err) {
			console.log('Não foi possível copiar');
		}
	};
	useEffect(() => {
		console.log(genericError.stack);
	}, [showError]);
	return (
		<div
			className='d-flex flex-column align-items-center'
			style={{ height: '100vh', backgroundColor: 'white' }}
		>
			<Image src={error} style={{ maxWidth: '200px' }} className='img-fluid mb-4' />
			<h3>
				<b>Algo deu errado! Entre novamente em sua conta</b>
			</h3>
			<span className='mt-2'>Vamos tentar corrigir automaticamente...</span>
			<Button className='mt-4' onClick={refresh}>
				Apenas atualize a página
			</Button>

			<Button className='mt-4' variant='warning' onClick={() => setShowError(!showError)}>
				Mostrar detalhes do erro <ArrowExpansion active={showError} />
			</Button>
			<Button className='mt-4' variant='secondary' onClick={handleLogout}>
				Sair da conta
			</Button>
			{showError && (
				<ErrorDetails className='col-md-6'>
					{genericError?.stack}
					<MdOutlineFileCopy
						size={22}
						onClick={copyError}
						style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}
					/>
				</ErrorDetails>
			)}
			<Toaster position='bottom-right' reverseOrder={false} />
		</div>
	);
};

export default ErrorBoundaryPage;
