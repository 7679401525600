import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<footer className='footer'>
			<div className='footer-body'>
				<ul className='left-panel list-inline mb-0 p-0'>
					<li className='list-inline-item'>
						<Link to='/dashboard/extra/privacy-policy'>Política de Privacidade</Link>
					</li>
					<li className='list-inline-item'>
						<Link to='/dashboard/extra/terms-of-service'>Termos de Uso</Link>
					</li>
				</ul>
				<div className='right-panel'>
					©<script>document.write(new Date().getFullYear())</script> Desenvolvido com excelência por
					{'  '}
					<span className='text-gray'>
						<svg
							width='15'
							height='18'
							viewBox='0 0 15 18'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<g clipPath='url(#clip0_201_20)'>
								<path
									d='M2.00099 3.15552C1.91157 3.15543 1.82407 3.18148 1.74925 3.23046L0.21187 4.23937C0.147152 4.28122 0.0938874 4.33854 0.0568944 4.40615C0.0199014 4.47376 0.000347024 4.54953 0 4.6266L0 6.38113C3.98143e-05 6.46301 0.0219209 6.54339 0.0633865 6.61399C0.104852 6.68459 0.164401 6.74285 0.235891 6.78277L3.57104 8.64107C3.62027 8.66828 3.67574 8.68219 3.73198 8.68144C3.78823 8.68069 3.8433 8.6653 3.89179 8.63679C3.94028 8.60828 3.9805 8.56763 4.0085 8.51885C4.0365 8.47006 4.05131 8.41483 4.05147 8.35858V4.63668C4.05144 4.56428 4.03337 4.49303 3.9989 4.42937C3.96442 4.3657 3.91463 4.31163 3.85401 4.27204L2.2513 3.2295C2.17665 3.18143 2.08978 3.15575 2.00099 3.15552Z'
									fill='#5893FA'
								/>
								<path
									d='M7.46542 2.46005e-08C7.55484 -2.922e-05 7.64232 0.0260162 7.71717 0.0749472L9.25695 1.08385C9.32086 1.1262 9.37328 1.18374 9.4095 1.25132C9.44572 1.3189 9.46461 1.3944 9.46449 1.47108V3.22561C9.46445 3.30749 9.44257 3.38787 9.40111 3.45847C9.35964 3.52907 9.30009 3.58734 9.2286 3.62725L5.89489 5.48556C5.84568 5.51291 5.79018 5.52694 5.73388 5.52626C5.67759 5.52558 5.62244 5.51021 5.57391 5.48167C5.52537 5.45314 5.48513 5.41242 5.45717 5.36355C5.4292 5.31469 5.41448 5.25936 5.41446 5.20306V1.48117C5.41419 1.40871 5.43212 1.33734 5.46663 1.27363C5.50113 1.20991 5.55109 1.15589 5.61192 1.11652L7.2156 0.0739863C7.29005 0.0258759 7.37677 0.00019312 7.46542 2.46005e-08Z'
									fill='#9584F7'
								/>
								<path
									d='M14.9414 4.2725C14.9847 4.35055 15.0043 4.43956 14.9976 4.52857L14.8641 6.3643C14.8585 6.44099 14.8339 6.51508 14.7925 6.57991C14.7512 6.64474 14.6944 6.69827 14.6272 6.73567L13.0932 7.58796C13.0216 7.6276 12.9407 7.64744 12.8588 7.64541C12.777 7.64338 12.6972 7.61957 12.6276 7.57642L9.3833 5.56342C9.33533 5.53374 9.29597 5.49203 9.26911 5.44243C9.24225 5.39283 9.22884 5.33707 9.2302 5.28068C9.23156 5.22429 9.24764 5.16924 9.27686 5.12099C9.30608 5.07274 9.34741 5.03297 9.39675 5.00564L12.6502 3.19778C12.7136 3.16279 12.7847 3.14412 12.857 3.14345C12.9294 3.14278 13.0008 3.16013 13.0648 3.19394L14.755 4.08946C14.8335 4.13126 14.8982 4.19482 14.9414 4.2725Z'
									fill='#B94DDD'
								/>
								<path
									d='M14.9414 12.9568C14.8977 13.0348 14.8323 13.0984 14.7531 13.1398L13.1225 13.994C13.0544 14.0298 12.9786 14.048 12.9017 14.047C12.8248 14.046 12.7495 14.0258 12.6824 13.9883L11.1498 13.1345C11.0781 13.0947 11.0184 13.0364 10.9769 12.9657C10.9353 12.895 10.9134 12.8144 10.9135 12.7324V8.91444C10.9136 8.8582 10.9284 8.80296 10.9564 8.75417C10.9844 8.70539 11.0246 8.66474 11.0731 8.63623C11.1216 8.60772 11.1767 8.59233 11.2329 8.59158C11.2892 8.59083 11.3447 8.60474 11.3939 8.63195L14.645 10.4437C14.7084 10.4787 14.7621 10.5291 14.8009 10.5903C14.8398 10.6515 14.8627 10.7215 14.8674 10.7939L14.9971 12.7022C15.0033 12.7907 14.9836 12.8792 14.9404 12.9568'
									fill='#FF7F4D'
								/>
								<path
									d='M7.42506 17.2917C7.3357 17.2927 7.248 17.2675 7.17284 17.2191L5.62345 16.2261C5.55884 16.1847 5.50552 16.1279 5.46829 16.0608C5.43106 15.9937 5.41108 15.9185 5.41014 15.8417L5.39284 14.0872C5.39207 14.0053 5.41317 13.9247 5.45396 13.8537C5.49475 13.7826 5.55376 13.7238 5.62489 13.6832L8.93986 11.7917C8.98888 11.7637 9.04436 11.7491 9.1008 11.7491C9.15724 11.7492 9.21268 11.7641 9.26162 11.7922C9.31056 11.8203 9.35131 11.8607 9.37981 11.9094C9.40832 11.9581 9.4236 12.0134 9.42413 12.0699L9.46065 15.7918C9.46148 15.8642 9.4441 15.9357 9.4101 15.9997C9.3761 16.0636 9.32658 16.118 9.26607 16.1579L7.67297 17.2148C7.59878 17.2638 7.51203 17.2903 7.42314 17.2912'
									fill='#F5C642'
								/>
								<path
									d='M0.10233 12.9678C0.0558821 12.8913 0.0325247 12.8031 0.0350694 12.7136L0.0907994 10.8741C0.0931571 10.7972 0.11459 10.7222 0.153168 10.6557C0.191746 10.5892 0.246259 10.5333 0.311797 10.4931L1.80834 9.57691C1.87807 9.53397 1.95804 9.51051 2.03993 9.50899C2.12181 9.50747 2.2026 9.52794 2.27388 9.56826L5.60086 11.4419C5.65001 11.4696 5.69107 11.5097 5.71997 11.5582C5.74887 11.6066 5.76461 11.6618 5.76563 11.7182C5.76665 11.7746 5.75291 11.8303 5.72578 11.8798C5.69865 11.9292 5.65906 11.9707 5.61095 12.0002L2.43722 13.9435C2.37553 13.9816 2.30528 14.0035 2.23292 14.0072C2.16055 14.011 2.0884 13.9965 2.02309 13.9652L0.296424 13.1422C0.216964 13.1037 0.150255 13.0432 0.104251 12.9678'
									fill='#54E8B2'
								/>
								<path
									d='M4.77356 9.76908V9.22427H5.42887V9.76716C5.43136 9.8174 5.45307 9.86477 5.48951 9.89945C5.52595 9.93413 5.57433 9.95347 5.62464 9.95347C5.67495 9.95347 5.72333 9.93413 5.75977 9.89945C5.79621 9.86477 5.81793 9.8174 5.82042 9.76716V9.34438C5.82042 8.91199 4.78173 8.84809 4.78173 8.00782V7.57159C4.78173 7.09644 5.11803 6.7832 5.622 6.7832C6.12597 6.7832 6.46756 7.09644 6.46756 7.57159V8.05682H5.81321V7.5663C5.81446 7.5415 5.81039 7.51672 5.80129 7.49362C5.79218 7.47052 5.77825 7.44963 5.76042 7.43234C5.74259 7.41506 5.72128 7.40178 5.6979 7.3934C5.67453 7.38502 5.64963 7.38173 5.62488 7.38374C5.60041 7.38179 5.57581 7.38517 5.55277 7.39365C5.52973 7.40213 5.50881 7.41552 5.49145 7.43287C5.47409 7.45023 5.46071 7.47115 5.45223 7.49419C5.44374 7.51723 5.44036 7.54183 5.44232 7.5663V7.93143C5.44232 8.37006 6.48101 8.42724 6.48101 9.26799V9.76908C6.48101 10.2437 6.13654 10.5623 5.62488 10.5623C5.11322 10.5623 4.77452 10.2437 4.77452 9.76908'
									fill='black'
								/>
								<path
									d='M8.56799 9.76908V9.22427H9.22426V9.76716C9.22675 9.8174 9.24846 9.86477 9.28491 9.89945C9.32135 9.93413 9.36973 9.95347 9.42004 9.95347C9.47034 9.95347 9.51873 9.93413 9.55517 9.89945C9.59161 9.86477 9.61332 9.8174 9.61581 9.76716V9.34438C9.61581 8.91199 8.57712 8.84809 8.57712 8.00782V7.57159C8.57712 7.09644 8.91102 6.7832 9.41739 6.7832C9.92377 6.7832 10.263 7.09644 10.263 7.57159V8.05682H9.60861V7.5663C9.60992 7.54153 9.60592 7.51676 9.59687 7.49365C9.58783 7.47055 9.57394 7.44965 9.55615 7.43236C9.53835 7.41507 9.51706 7.40178 9.49371 7.3934C9.47036 7.38501 9.44549 7.38172 9.42076 7.38374C9.39629 7.38179 9.37168 7.38517 9.34864 7.39365C9.32561 7.40213 9.30469 7.41552 9.28733 7.43287C9.26997 7.45023 9.25658 7.47115 9.2481 7.49419C9.23962 7.51723 9.23624 7.54183 9.23819 7.5663V7.93143C9.23819 8.37006 10.2769 8.42724 10.2769 9.26799V9.76908C10.2769 10.2437 9.93194 10.5623 9.42076 10.5623C8.90958 10.5623 8.56992 10.2437 8.56992 9.76908'
									fill='black'
								/>
								<path
									d='M8.0304 6.84619H7.01766L6.57422 10.4994H7.23673L7.31024 9.61779L7.35684 9.07491L7.46157 7.80129V7.49862H7.54997V7.80129L7.65471 9.07491L7.70131 9.61779L7.77433 10.4994H8.46856L8.0304 6.84619Z'
									fill='black'
								/>
							</g>
							<defs>
								<clipPath id='clip0_201_20'>
									<rect width='15' height='17.2917' fill='white' />
								</clipPath>
							</defs>
						</svg>
					</span>{' '}
					<Link to='https://sasgp.com.br/' target='_blank'>
						SAS - Soluções em Gestão de Projetos
					</Link>
					.
				</div>
			</div>
		</footer>
	);
};

export default Footer;
