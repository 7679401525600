import React, { useEffect, memo, Fragment, useState, useMemo } from 'react';
import { api } from '../../api/api';
//hooks
import { useQuery } from 'react-query';
import useStars from '../../hooks/useStars';
import { useSessionStorage } from '@uidotdev/usehooks';
import { useSelector } from 'react-redux';
// AOS
import AOS from 'aos';
import 'aos';
import '../../../node_modules/aos/dist/aos.css';
//styles
import 'react-datepicker/dist/react-datepicker.css';
import ChartFilters from '../../components/chartFilters/chartFilters';
import 'swiper/swiper-bundle.min.css';
// import 'swiper/components/navigation/navigation.scss';
//icons
import { FaBoxes } from 'react-icons/fa';
// Import selectors & action from setting store
import * as SettingSelector from '../../store/setting/selectors';
//components
import { Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CountUp from 'react-countup';
import CustomTooltip from '../../components/customTooltip/CustomTooltip';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import CardWithGrowth from '../../components/cardWithGrowth/CardWithGrowth';
import PiechartCard from '../../components/piechartCard/piechartCard';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('pt-BR', ptBR);
import StockByYear from '../../components/stockByYear/stockByYear';
import GrowthBadge from '../../components/growthBadge/growthBadge';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { Skeleton } from '../../components/table/skeleton';
SwiperCore.use([Navigation]);

const Index = memo((props) => {
	useSelector(SettingSelector.theme_color);
	const { setStars } = useStars();
	const [isLoading, setLoading] = useSessionStorage('isLoading', false);
	const [dataDashboard, setDataDashboard] = useSessionStorage('dashboardData', null);
	//Charts filters
	const [servicesQuantities, setServicesQuantities] = useState();
	const [queryMethods, setQueryMethods] = useState();
	//others
	const [servicesFilters, setServicesFilters] = useState({});
	const [servicesChannelFilter, setServicesChannelFilter] = useState('Todos');
	const [servicesProductFilter, setServicesProductFilter] = useState('Todos');
	const [startDate, setStartDate] = useState(new Date(`${new Date().getFullYear() - 1}-01-02`));
	const [endDate, setEndDate] = useState(new Date());

	//Pega os dados e armazena no sessionstorage
	const { data: getDataDash } = useQuery(
		['dataDash'],
		async () => {
			setLoading(true);
			const response = await api('/indices/home');

			setDataDashboard(response?.data);

			return response?.data;
		},
		{
			onError: (err) => console.log(err),
			onSettled: () => setLoading(false),
		}
	);

	const { data: channelServicesQuantities, refetch: refetchChannelServices } = useQuery(
		['channelServicesQuantities'],
		{
			queryFn: async () => {
				//convertendo objeto em string params para requisição
				var filters = '';
				const channelFilter = { product: servicesFilters.product };
				for (let index = 0; index < Object.keys(channelFilter).length; index++) {
					const obj = Object.entries(channelFilter)[index];
					filters = filters.concat(obj.toString().replace(',', '=') + '&');
				}
				const initialDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
					.toString()
					.padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
				const finalDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
					.toString()
					.padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;

				const str = `initialDate=${initialDate}&finalDate=${finalDate}`;
				//requisição
				const response = await api.get(`/services/quantities/channel?${filters}${str}`);
				const services = response?.data?.servicesQuantityByChannel
					.map((service) => {
						return { channel: service.channel, quantity: Number(service.quantity) };
					})
					.filter((service) => service.quantity > 0); // Mesmo sendo 0, o objeto referente a ligação é retornado
				return services;
			},
			onError: (err) => console.log(err),
		}
	);

	const { data: productServicesQuantities, refetch: refetchProductServices } = useQuery(
		['productServicesQuantities'],
		{
			queryFn: async () => {
				setLoading(true);
				//convertendo objeto em string params para requisição
				var filters = '';
				const productFilter = { channel: servicesFilters.channel };
				for (let index = 0; index < Object.keys(productFilter).length; index++) {
					const obj = Object.entries(productFilter)[index];
					filters = filters.concat(obj.toString().replace(',', '=') + '&');
				}
				const initialDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
					.toString()
					.padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
				const finalDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
					.toString()
					.padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;

				const str = `initialDate=${initialDate}&finalDate=${finalDate}`;
				//requisição
				const response = await api.get(`/services/quantities/product?${filters}${str}`);

				setLoading(false);
				return response?.data?.servicesQuantityByProduct.map((service) => ({
					product: service.product || 'Sem Categoria',
					quantity: Number(service.quantity),
				}));
			},
			onError: (err) => console.log(err),
		}
	);

	useEffect(() => {
		refetchProductServices();
		refetchChannelServices();
	}, [servicesChannelFilter, servicesProductFilter, startDate, endDate]);

	//anim
	useEffect(() => {
		AOS.init({
			startEvent: 'DOMContentLoaded',
			disable: function () {
				var maxWidth = 996;
				return window.innerWidth < maxWidth;
			},
			throttleDelay: 10,
			once: true,
			duration: 700,
			offset: 10,
		});
	});

	return (
		<Fragment>
			<Col md='12' lg='12'>
				<Row className='row-cols-1'>
					<div className='overflow-hidden d-slider1 ' data-aos='fade-up' data-aos-delay='700'>
						<Card>
							<Card.Header>
								<h5>Análise Mensal dos Atendimentos</h5>
								<span className='text-muted'>(30 dias)</span>
							</Card.Header>
							<Card.Body>
								<Swiper
									className='p-0 m-0 mb-2 list-inline '
									slidesPerView={5}
									spaceBetween={32}
									navigation={{
										nextEl: '.swiper-button-next',
										prevEl: '.swiper-button-prev',
									}}
									breakpoints={{
										320: { slidesPerView: 1 },
										550: { slidesPerView: 2 },
										991: { slidesPerView: 3 },
										1400: { slidesPerView: 3 },
										1500: { slidesPerView: 4 },
										1920: { slidesPerView: 5 },
									}}
								>
									<SwiperSlide className=''>
										<div className=''>
											<div className='progress-widget'>
												<div className='bg-warning text-white rounded p-3'>
													<svg
														width='22'
														viewBox='0 0 24 24'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															fillRule='evenodd'
															clipRule='evenodd'
															d='M21.25 12.0005C21.25 17.1095 17.109 21.2505 12 21.2505C6.891 21.2505 2.75 17.1095 2.75 12.0005C2.75 6.89149 6.891 2.75049 12 2.75049C17.109 2.75049 21.25 6.89149 21.25 12.0005Z'
															stroke='currentColor'
															strokeWidth='1.5'
															strokeLinecap='round'
															strokeLinejoin='round'
														/>
														<path
															d='M15.4316 14.9429L11.6616 12.6939V7.84692'
															stroke='currentColor'
															strokeWidth='1.5'
															strokeLinecap='round'
															strokeLinejoin='round'
														/>
													</svg>
												</div>
												<div className='progress-detail' style={{ width: '100%' }}>
													<div className='d-flex justify-content-between'>
														<p className='mb-2'>Duração Média</p>
														<div>
															<CustomTooltip
																text={
																	<div style={{ textAlign: 'left' }}>
																		<p>
																			A duração média dos atendimentos é calculado com: Tempo total
																			/ qtd atendimento
																		</p>
																		<p>
																			* Considerando apenas atendimentos com duração abaixo de 210
																			minutos
																		</p>
																		<p>* Não incluso atendimentos por ligação</p>
																		<p>* Considera todo o período</p>
																	</div>
																}
															/>
														</div>
													</div>
													<h4
														className='counter d-flex align-items-center'
														style={{ gap: '0.5rem' }}
													>
														<ReactPlaceholder
															type='text'
															rows={1}
															ready={!isLoading}
															showLoadingAnimation={true}
															color='#E0E0E0'
														>
															{dataDashboard?.averageServiceTime ?? 0} minutos
															<GrowthBadge growth={dataDashboard?.averageServiceTimeGrowthRate} />
														</ReactPlaceholder>
													</h4>
												</div>
											</div>
										</div>
									</SwiperSlide>

									<SwiperSlide className=''>
										<div className=''>
											<div className='progress-widget'>
												<div className=' bg-soft-info rounded p-3'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='22px'
														fill='none'
														viewBox='0 0 24 24'
														stroke='currentColor'
													>
														<path
															strokeLinecap='round'
															strokeLinejoin='round'
															strokeWidth='2'
															d='M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
														></path>
													</svg>
												</div>
												<div className='progress-detail' style={{ width: '100%' }}>
													<div className='d-flex justify-content-between'>
														<p className='mb-1'>Nota Média</p>
														<div>
															<CustomTooltip
																text={
																	<div style={{ textAlign: 'left' }}>
																		<p>
																			A nota média dos atendimentos é calculado com: Nota total /
																			qtd atendimento
																		</p>
																		<p>* Não incluso atendimentos com nota 0 ou sem nota</p>
																		<p>
																			* Apenas {dataDashboard?.servicesRatedPercent}% dos
																			atendimentos avaliados
																		</p>
																		<p>* Considera todo o período</p>
																	</div>
																}
															/>
														</div>
													</div>

													<OverlayTrigger
														className='info'
														placement='top'
														overlay={<Tooltip>{dataDashboard?.last30DaysAverageRates}/5</Tooltip>}
													>
														<div className=' d-flex align-items-center' style={{ gap: '0.5rem' }}>
															<div>
																{setStars(dataDashboard?.last30DaysAverageRates).map(
																	(star) => star
																)}
															</div>
															<GrowthBadge growth={dataDashboard?.averageRateGrowthRate} />
														</div>
													</OverlayTrigger>
												</div>
											</div>
										</div>
									</SwiperSlide>

									<SwiperSlide className=''>
										<div className=''>
											<div className='progress-widget'>
												<div className='bg-info text-white rounded p-3'>
													<svg
														width='22px'
														viewBox='0 0 24 24'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															fillRule='evenodd'
															clipRule='evenodd'
															d='M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z'
															stroke='currentColor'
															strokeWidth='1.5'
															strokeLinecap='round'
															strokeLinejoin='round'
														/>
													</svg>
												</div>
												<div className='progress-detail' style={{ width: '100%' }}>
													<div className='d-flex justify-content-between'>
														<p className='mb-2'>Atendimentos mensais</p>
														<div>
															<CustomTooltip
																text={
																	<div style={{ textAlign: 'left' }}>
																		<p>Quantidade de atendimentos dos últimos 30 dias</p>
																		<p>* Considera todo o período</p>
																	</div>
																}
															/>
														</div>
													</div>

													<h4
														className='counter d-flex align-items-center'
														style={{ gap: '0.5rem' }}
													>
														<ReactPlaceholder
															type='text'
															rows={1}
															ready={!isLoading}
															showLoadingAnimation={true}
															color='#E0E0E0'
														>
															<CountUp
																start={0}
																end={dataDashboard?.last30DaysServicesQuantity}
																duration={1}
															/>
															<GrowthBadge growth={dataDashboard?.servicesQuantityGrowthRate} />
														</ReactPlaceholder>
													</h4>
												</div>
											</div>
										</div>
									</SwiperSlide>

									<SwiperSlide className=''>
										<div className=''>
											<div className='progress-widget'>
												<div className='bg-success text-white rounded p-3'>
													<svg
														width='22'
														viewBox='0 0 24 24'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															fillRule='evenodd'
															clipRule='evenodd'
															d='M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z'
															stroke='currentColor'
															strokeWidth='1.5'
															strokeLinecap='round'
															strokeLinejoin='round'
														/>
													</svg>
												</div>
												<div className='progress-detail' style={{ width: '100%' }}>
													<div className='d-flex justify-content-between'>
														<p className='mb-2'>Finalizados mensais</p>
														<div>
															<CustomTooltip
																text={
																	<div style={{ textAlign: 'left' }}>
																		<p>
																			Quantidade de atendimentos finalizados nos últimos 30 dias.
																		</p>
																		<p>* Considera todo o período</p>
																	</div>
																}
															/>
														</div>
													</div>
													<h4
														className='counter d-flex align-items-center'
														style={{ gap: '0.5rem' }}
													>
														<ReactPlaceholder
															type='text'
															rows={1}
															ready={!isLoading}
															showLoadingAnimation={true}
															color='#E0E0E0'
														>
															<CountUp
																start={0}
																end={dataDashboard?.last30DaysFinishedServicesQuantity}
																duration={1}
															/>
															<GrowthBadge growth={dataDashboard?.finishedServicesGrowthRate} />
														</ReactPlaceholder>
													</h4>
												</div>
											</div>
										</div>
									</SwiperSlide>

									<SwiperSlide className=''>
										<div className='progress-widget'>
											<div className='bg-danger text-white rounded p-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='20px'
													viewBox='0 0 24 24'
													fill='none'
												>
													<path
														fill-rule='evenodd'
														clip-rule='evenodd'
														d='M21.419 15.732C21.419 19.31 19.31 21.419 15.732 21.419H7.95C4.363 21.419 2.25 19.31 2.25 15.732V7.932C2.25 4.359 3.564 2.25 7.143 2.25H9.143C9.861 2.251 10.537 2.588 10.967 3.163L11.88 4.377C12.312 4.951 12.988 5.289 13.706 5.29H16.536C20.123 5.29 21.447 7.116 21.447 10.767L21.419 15.732Z'
														stroke='currentColor'
														stroke-width='1.5'
														stroke-linecap='round'
														stroke-linejoin='round'
													/>
													<path
														d='M7.48145 14.4629H16.2164'
														stroke='currentColor'
														stroke-width='1.5'
														stroke-linecap='round'
														stroke-linejoin='round'
													/>
												</svg>
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Estoque Mensal</p>
													<div>
														<CustomTooltip
															text={
																<div style={{ textAlign: 'left' }}>
																	<p>
																		O estoque mensal é calculado com: (total do mês - finalizados no
																		mês)
																	</p>
																	<p>* Considera todo o período</p>
																</div>
															}
														/>
													</div>
												</div>

												<h4 className='counter d-flex align-items-center' style={{ gap: '0.5rem' }}>
													<ReactPlaceholder
														type='text'
														rows={1}
														ready={dataDashboard}
														showLoadingAnimation={true}
														color='#E0E0E0'
													>
														<CountUp start={0} end={dataDashboard?.last30DaysStock} duration={1} />
														<GrowthBadge growth={dataDashboard?.stockGrowthRate} />
													</ReactPlaceholder>
												</h4>
											</div>
										</div>
									</SwiperSlide>

									<SwiperSlide className=''>
										<div className='progress-widget'>
											<div className='bg-secondary text-white rounded p-3'>
												<FaBoxes size={20} />
											</div>
											<div className='progress-detail' style={{ width: '100%' }}>
												<div className='d-flex justify-content-between'>
													<p className='mb-2'>Total acumulado</p>
													<div>
														<CustomTooltip
															text={
																<div style={{ textAlign: 'left' }}>
																	<p>Atendimentos em estoque de todo o período</p>
																</div>
															}
														/>
													</div>
												</div>

												<h4 className='counter d-flex align-items-center' style={{ gap: '0.5rem' }}>
													<ReactPlaceholder
														type='text'
														rows={1}
														ready={dataDashboard}
														showLoadingAnimation={true}
														color='#E0E0E0'
													>
														<CountUp
															start={0}
															end={dataDashboard?.totalAccumulatedServices}
															duration={1}
														/>
														{/* <GrowthBadge growth={dataDashboard?.stockGrowthRate} /> */}
													</ReactPlaceholder>
												</h4>
											</div>
										</div>
									</SwiperSlide>
									<div className='swiper-button swiper-button-next'></div>
									<div className='swiper-button swiper-button-prev'></div>
								</Swiper>
							</Card.Body>
						</Card>
					</div>
				</Row>
			</Col>

			<section data-aos='fade-up' data-aos-delay='800'>
				<StockByYear initialYear='2024' />
			</section>

			<section data-aos='fade-up' data-aos-delay='500'>
				<Card>
					<Card.Body>
						<h5>Análise de Atendimentos</h5>
						<ChartFilters
							product={true}
							channel={true}
							period={true}
							channelFilter={servicesChannelFilter}
							productFilter={servicesProductFilter}
							setProduct={setServicesProductFilter}
							filtersController={servicesFilters}
							setChannel={setServicesChannelFilter}
							setFiltersController={setServicesFilters}
							// dates={totalYears}
							data={'servicesQuantities'}
							setData={setServicesQuantities}
							setStartDateDisplay={setStartDate}
							setEndDateDisplay={setEndDate}
							setQueryMethods={setQueryMethods}
							query={'/services/quantities'}
						/>
					</Card.Body>
				</Card>

				<Swiper
					className='p-0 m-0 mb-2 list-inline '
					slidesPerView={5}
					spaceBetween={32}
					breakpoints={{
						320: { slidesPerView: 1 },
						550: { slidesPerView: 2 },
						991: { slidesPerView: 3 },
						1400: { slidesPerView: 3 },
						1500: { slidesPerView: 4 },
						1920: { slidesPerView: 5 },
					}}
				>
					<SwiperSlide>
						<CardWithGrowth
							title='Atendimentos Totais'
							tooltip={
								<div style={{ textAlign: 'left' }}>
									<p>Total de atendimentos</p>
								</div>
							}
							colorIcon={'info'}
							icon={
								<svg width='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z'
										stroke='currentColor'
										strokeWidth='1.5'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							}
						>
							<CardWithGrowth.Body isLoading={queryMethods?.isFetching}>
								{servicesQuantities?.totalServicesQuantity}
							</CardWithGrowth.Body>
						</CardWithGrowth>
					</SwiperSlide>
					<SwiperSlide>
						<CardWithGrowth
							title='Finalizados Totais'
							tooltip={
								<div style={{ textAlign: 'left' }}>
									<p>O total de atendimentos finalizados</p>
								</div>
							}
							isLoading={isLoading}
							colorIcon={'primary'}
							icon={
								<svg width='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z'
										stroke='currentColor'
										strokeWidth='1.5'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							}
						>
							<CardWithGrowth.Body isLoading={queryMethods?.isFetching}>
								{servicesQuantities?.finishedServicesQuantity}
							</CardWithGrowth.Body>
						</CardWithGrowth>
					</SwiperSlide>
					<SwiperSlide>
						<CardWithGrowth
							title='Estoque Totais'
							tooltip={
								<div style={{ textAlign: 'left' }}>
									<p>Calculado com:</p>
									<p>total de atendimentos - atendimentos finalizados.</p>
									<p>*Filtros aplicados afetam o resultado final.</p>
								</div>
							}
							isLoading={isLoading}
							colorIcon={'danger'}
							icon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='20px'
									viewBox='0 0 24 24'
									fill='none'
								>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M21.419 15.732C21.419 19.31 19.31 21.419 15.732 21.419H7.95C4.363 21.419 2.25 19.31 2.25 15.732V7.932C2.25 4.359 3.564 2.25 7.143 2.25H9.143C9.861 2.251 10.537 2.588 10.967 3.163L11.88 4.377C12.312 4.951 12.988 5.289 13.706 5.29H16.536C20.123 5.29 21.447 7.116 21.447 10.767L21.419 15.732Z'
										stroke='currentColor'
										stroke-width='1.5'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
									<path
										d='M7.48145 14.4629H16.2164'
										stroke='currentColor'
										stroke-width='1.5'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							}
						>
							<CardWithGrowth.Body isLoading={queryMethods?.isFetching}>
								{servicesQuantities?.totalServicesStock}
							</CardWithGrowth.Body>
						</CardWithGrowth>
					</SwiperSlide>
					<SwiperSlide>
						<CardWithGrowth
							title='Total acumulado'
							tooltip={
								<div style={{ textAlign: 'left' }}>
									<p>É o estoque acumulado, ou seja, segue o cálculo:</p>
									<p>total de atendimentos - atendimentos finalizados</p>
									<p>porém filtros de período não afetam o resultado final.</p>
								</div>
							}
							isLoading={isLoading}
							colorIcon={'secondary'}
							icon={<FaBoxes />}
						>
							<CardWithGrowth.Body isLoading={queryMethods?.isFetching}>
								{servicesQuantities?.totalAccumulated}
							</CardWithGrowth.Body>
						</CardWithGrowth>
					</SwiperSlide>
				</Swiper>

				<Row className='mb-5 flex-nowrap overflow-hidden'>
					<div className='overflow-hidden d-slider1'>
						<Swiper
							style={{ zIndex: '0' }}
							className='p-0 m-0 mb-2 list-inline'
							slidesPerView={2}
							spaceBetween={32}
							navigation={true}
							breakpoints={{
								320: { slidesPerView: 1 },
								550: { slidesPerView: 2 },
								991: { slidesPerView: 2 },
								1400: { slidesPerView: 2 },
								1500: { slidesPerView: 2 },
								1920: { slidesPerView: 3 },
								2040: { slidesPerView: 3 },
								2440: { slidesPerView: 3 },
							}}
						>
							<SwiperSlide
								className='card card-slider'
								style={{ height: '350px', maxWidth: '900px' }}
							>
								<PiechartCard
									title='Produtos'
									tooltip='Atendimentos por produto.'
									data={productServicesQuantities}
									labelAttribute='product'
									quantityAttribute='quantity'
								/>
							</SwiperSlide>

							<SwiperSlide
								className='card card-slider'
								style={{ height: '350px', maxWidth: '900px' }}
							>
								<PiechartCard
									title='Por canal'
									tooltip='Atendimentos por canal. Filtros de canal não atuam'
									data={channelServicesQuantities}
									labelAttribute='channel'
									quantityAttribute='quantity'
								/>
							</SwiperSlide>
						</Swiper>
					</div>
				</Row>
			</section>
		</Fragment>
	);
});

export default Index;
