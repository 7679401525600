import React, { useEffect, useState } from 'react';
import { Image, Modal, Spinner } from 'react-bootstrap';
import {
	BotImage,
	ClientImage,
	ClientMessage,
	MessageContainer,
	Messages,
	MyMessage,
} from './style';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { apiAurora } from '../../api/api';
//hooks
import { useDebounce } from '@uidotdev/usehooks';
//style
import errorHistory from '../../assets/images/error/undraw_conversation.svg';
import useFormattedValues from '../../hooks/useFormattedValues';
import botImage from '../../assets/images/error/coplan 1.svg';

const internalCodes = {
	1001: 'Falha ao buscar historico! Tente novamente mais tarde.',
	1002: 'Usuário não logado! Entre novamente na conta.',
	1003: 'Falha ao buscar historico! Tente novamente mais tarde.',
	1004: 'Falha ao buscar historico! Tente novamente mais tarde.',
	1005: 'Chat não encontrado! Tente novamente mais tarde.',
	1006: 'Histórico não encontrado! Tente novamente mais tarde.',
};
export const MessageHistory = ({ item, show, setShow }) => {
	const { setValue } = useFormattedValues();

	const {
		data: history,
		isLoading,
		isFetching,
	} = useQuery(['historyChat', item?.id], () => getHistory(item?.id), {
		initialData: () => [],
		enabled: !!show,
	});
	const debouncedFetching = useDebounce(isFetching, 200);

	const getHistory = async (historyId) => {
		try {
			const response = await apiAurora.get(`/chats/${historyId}/history`);
			return response?.data;
		} catch (err) {
			toast.error(
				internalCodes[err?.response?.data?.internalCode] ||
					'Não foi possível criar a conta. Tente novamente mais tarde.'
			);
		}
		return [];
	};

	const setDate = (date) => {
		return (
			`${new Date(date).getHours().toString().padStart(2, '0')}:${new Date(date)
				.getMinutes()
				.toString()
				.padStart(2, '0')} ${new Date(date).getUTCDate().toString().padStart(2, '0')}/${(
				new Date(date).getUTCMonth() + 1
			)
				.toString()
				.padStart(2, '0')}/${new Date(date).getUTCFullYear()}` || 'Sem data'
		);
	};

	return (
		<>
			<Modal show={show} size='lg' className='back-blur' onHide={() => setShow(false)}>
				<>
					<Modal.Header closeButton>
						<h4>
							{setValue(item?.protocol)} - {setValue(item?.userName)}
						</h4>
					</Modal.Header>
					<Modal.Body className='h-80vh overflow-auto'>
						<Messages>
							{history?.length > 0 &&
								history.map((message) =>
									message?.sender === 'user' ? (
										<MessageContainer user='client'>
											<ClientImage>
												<h4 className='text-white'>{item?.userName?.slice(0, 2) || ''}</h4>
											</ClientImage>
											<ClientMessage>
												{message?.message}
												{console.log(message?.timestamp)}
												<span>{setDate(message?.timestamp)}</span>
											</ClientMessage>
										</MessageContainer>
									) : (
										<MessageContainer user='my'>
											<MyMessage>
												{message?.message
													.replace('\\n', '\n')
													.split('\\n')
													.map((item) => (
														<p>{item}</p>
													))}
												<span>{setDate(message?.timestamp)}</span>
											</MyMessage>
											<BotImage src={botImage} />
										</MessageContainer>
									)
								)}
							<div className='d-flex flex-column justify-content-center align-items-center'>
								{!debouncedFetching && history?.length === 0 && (
									<>
										<Image
											src={errorHistory}
											style={{ maxWidth: '250px' }}
											className='img-fluid'
											alt='não foi possível encontrar chat'
										/>
										<h4 className='text-center mt-4'>Não há mensagens desse chat</h4>
										<p className='text-center'>Talvez o usuário não tenha chegado nessa parte :/</p>
									</>
								)}
								{debouncedFetching && (
									<Spinner variant='primary' style={{ position: 'absolute', top: '40%' }} />
								)}
							</div>
							<div className='position-absolute'></div>
						</Messages>
					</Modal.Body>
				</>
			</Modal>
		</>
	);
};
