import { themes } from './themes';

export const stackedbar100 = (series, format) => {
	const moneyFormat = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	});

	const stackedbar = {
		options: {
			chart: {
				toolbar: {
					show: false,
				},
				type: 'bar',
				height: 150,
				stacked: true,
				stackType: '100%',
			},
			colors: themes[themes] || themes.primary,
			plotOptions: {
				bar: {
					horizontal: true,
				},
			},
			grid: {
				show: false,
			},
			yaxis: {
				labels: { show: true },
			},
			dataLabels: {
				enabled: true,
				style: {
					fontWeight: 'bold',
					colors: ['#00000080'],
				},
				formatter: (val, { seriesIndex, dataPointIndex }) => {
					return format === 'money'
						? moneyFormat.format(series[seriesIndex ?? 0].data[dataPointIndex ?? 0])
						: series[seriesIndex ?? 0].data[dataPointIndex ?? 0];
				},
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return format === 'money' ? moneyFormat.format(val) : val;
					},
				},
			},
			noData: {
				text: 'Nenhum dado encontrado!',
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: undefined,
					fontSize: '16px',
					fontFamily: undefined,
				},
			},
			xaxis: {
				categories: [
					'Janeiro/24',
					'Fevereiro/24',
					'Março/24',
					'Abril/24',
					'Maio/24',
					'Junho/24',
					'Julho/24',
					'Agosto/24',
					'Setembro/24',
					'Outubro/24',
					'Novembro/24',
					'Dezembro/24',
				],
				labels: {
					show: true,
				},
				tickAmount: 2,
			},
		},
		series: series,
	};

	return stackedbar;
};
